import { EOfferType, MpUser, TradeOffer } from '@/domain';
import { OfferChangeModeratorDialog } from '@features/general/offer/components/changeModeratorDialog';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { TradeOfferStateKeyDialogs } from '../store/slice';
import { useTradeOfferActions } from '../useActions';

const nameDialog: TradeOfferStateKeyDialogs = 'changeModerator';

const TradeOfferActionsDialogChangeModeratorAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onChangeModerator = useCallback(
    (offers: TradeOffer[], user: MpUser) => {
      handlers.onChangeModerator(offers[0].id, user.id, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onChangeModerator, notification]
  );

  return (
    data && (
      <OfferChangeModeratorDialog<TradeOffer>
        offers={[data]}
        offerType={EOfferType.Trade}
        isExecuting={isProcessing}
        onExecute={onChangeModerator}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogChangeModeratorAdapter;
