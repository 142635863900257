import { AnyOffer, EOfferType, EUserRole, EUserStatus, MpUser } from '@/domain';
import { CorpOfferModeratorPreview } from '@features/corpOffer/types';
import { getCorpOfferModeratorPreview } from '@features/corpOffer/utils';
import { Grid } from '@mui/material';
import { MPAutocompleteSingleSelect } from '@ui-kit/autocomplete/single';
import { MPButton } from '@ui-kit/button';
import { useState } from 'react';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import UserMpSuggestionContainer from '../../../../user/userMp/search/container';
import { OfferChangeModeratorMultipleDescription } from './multipleDescription';
import { OfferChangeModeratorSingleDescription } from './singleDescription';

export interface OfferChangeModeratorDialogProps<T extends AnyOffer> {
  readonly offerType: EOfferType;
  readonly offers: Nullable<T[]>;
  readonly isExecuting: boolean;
  readonly onExecute: (offers: T[], user: MpUser) => void;
  readonly onClose: () => void;
}

const OfferChangeModeratorDialog = <T extends AnyOffer>(props: OfferChangeModeratorDialogProps<T>) => {
  const { offers, isExecuting, onExecute, onClose, offerType } = props;

  const [selectedModerator, setSelectedModerator] = useState<Nullable<MpUser>>(null);
  const [searchedModerator, setSearchedModerator] = useState<Nullable<MpUser>>(null);
  const [searchValue, setSearchValue] = useState<Nullable<string>>(null);
  const [error, setError] = useState<Nullable<string>>(null);

  const title =
    offers?.length === 1 && offers[0].approvingAdmin ? 'Переназначение модератора' : 'Назначение модератора';
  const label =
    offerType === EOfferType.Corp ? 'Администратор корпоративных предложений' : 'Администратор торговых предложений';

  if (!offers?.length) {
    return null;
  }

  const onModeratorChange = () => {
    if (selectedModerator) {
      onExecute(offers, selectedModerator);
    } else {
      setError('Обязательно для заполнения');
    }
  };

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onModeratorChange}
            >
              Назначить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              fullWidth={false}
              disabled={isExecuting}
              onClick={onClose}
            >
              Отмена
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          {offers.length === 1 && (
            <OfferChangeModeratorSingleDescription
              offerType={offerType}
              offer={offers[0]}
            />
          )}
          {offers.length > 1 && (
            <OfferChangeModeratorMultipleDescription
              offerType={offerType}
              offers={offers}
            />
          )}
        </Grid>

        <Grid item>
          <UserMpSuggestionContainer
            roles={[EUserRole.AdminMp, EUserRole.AdminMpCorp]}
            statuses={[EUserStatus.Enabled]}
          >
            {({ onSearch, users, isFetching }) => (
              <MPAutocompleteSingleSelect<CorpOfferModeratorPreview>
                label={label}
                isLoading={isFetching}
                error={!!error}
                helperText={error}
                value={
                  searchedModerator && {
                    ...searchedModerator,
                    id: '',
                    name: searchValue ?? '',
                  }
                }
                options={
                  users?.map(user => ({
                    ...user,
                    name: getCorpOfferModeratorPreview(user, [EUserRole.AdminMp, EUserRole.AdminMpCorp]),
                  })) ?? []
                }
                onSearchValue={value => {
                  onSearch(value);
                  setSearchValue(value);
                  setSelectedModerator(null);
                }}
                onChangeValue={value => {
                  setSelectedModerator(value);
                  setSearchedModerator(value);
                  setError(null);
                }}
              />
            )}
          </UserMpSuggestionContainer>
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};
export { OfferChangeModeratorDialog };
