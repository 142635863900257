import styled from '@emotion/styled';
import { viewConfig } from '@theme/viewConfig';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  flex-direction: row;
`;

export const ContentWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};
  padding-top: ${p => p.theme.spacing(2)};

  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;
