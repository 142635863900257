import { EMPStepperTransitionMethod } from '@components/common/stepper';
import StepperContainer from '../../../general/stepper/container';
import { ClientOrgCreateStepType } from '../../types';
import useClientOrgCreateStepper from '../hooks/useStepper';
import { ClientOrgCreateStepperIconAdapter } from './stepperIcon';
import { ClientOrgCreateStepperLabelAdapter } from './stepperLabel';

export const ClientOrgCreateStepperAdapter = () => {
  const { currentStep, steps, openStep } = useClientOrgCreateStepper();

  return (
    <StepperContainer<ClientOrgCreateStepType>
      step={currentStep}
      steps={steps}
      flow={false}
      forwardTransition={EMPStepperTransitionMethod.Free}
      decorators={{
        icon: ClientOrgCreateStepperIconAdapter,
        labelOptional: ClientOrgCreateStepperLabelAdapter,
      }}
      onClick={openStep}
    />
  );
};
