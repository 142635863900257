import Api from '@/data/api';
import ErrorHandler from '@/data/network/errorHandler';
import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { ApprovalOfferRegistry, ApprovalOfferRegistryRequest, CorpOffer, Nullable, SportOption, UUID } from '@/domain';
import offerServices from '@features/general/offer/services';
import { EOfferActionType } from '@features/general/offer/types';
import {
  personalPromotionCreateGenerate,
  personalPromotionCreateUpload,
} from '@features/personalPromotion/create/store/slice';
import { CaseReducer, createAsyncThunk, createSlice, Draft, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

const handleActionProcess = (
  state: Draft<CorpOffersActionsState>,
  id: UUID,
  actionType: EOfferActionType,
  fetchable: Fetchable,
  error: Nullable<ServerErrorResponse | undefined> = null
) => {
  if (id) {
    let process = state.actions.find(change => change.id === id);

    if (process) {
      process.id = id;
      process.type = actionType;
      process.error = error ?? null;
      process.isFetching = fetchable.isFetching;
      process.isFetched = fetchable.isFetched;
      process.isFailed = fetchable.isFailed;
    } else {
      process = {
        ...fetchable,
        id,
        type: actionType,
        error: null,
      };

      state.actions.push(process);
    }
  } else {
    state.create = fetchable;
  }
};

export const corpOfferActionsSave = createAsyncThunk<CorpOffer, CorpOffer, AppThunkAPIConfig<ServerErrorResponse>>(
  'corpOffer/actions/save',
  async (offer, { rejectWithValue }) => {
    try {
      return await offerServices.corp.save(offer);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferActionsArchive = createAsyncThunk<
  boolean,
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/actions/archive', async ({ ids }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.archiveCollection({ ids });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsRetrieve = createAsyncThunk<
  CorpOffer,
  { id: UUID; endDate: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/actions/retrieve', async (props, { rejectWithValue }) => {
  try {
    return await offerServices.corp.retrieve(props);
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsPause = createAsyncThunk<
  boolean,
  { ids: UUID[]; reason?: SportOption; comment?: string },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/actions/pause', async ({ ids, reason, comment }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.pauseCollection({ ids, reason, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsApprove = createAsyncThunk<
  boolean,
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/actions/approve', async ({ ids }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.approveCollection({ ids });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsReturnToVerification = createAsyncThunk<
  CorpOffer,
  { id: UUID; userId: UUID },
  AppThunkAPIConfig
>('corpOffer/actions/returnToVerification', async ({ id, userId }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.inWork({ id, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsChangeModerator = createAsyncThunk<
  boolean,
  {
    ids: UUID[];
    userId: UUID;
  },
  AppThunkAPIConfig
>('corpOffer/actions/changeModerator', async ({ ids, userId }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.inWorkCollection({ ids, userId });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsChangeApprovalRegistry = createAsyncThunk<
  ApprovalOfferRegistry,
  {
    ids: UUID[];
    approvalRegistry: ApprovalOfferRegistryRequest;
  },
  AppThunkAPIConfig
>('corpOffer/actions/changeApprovalRegistry', async ({ ids, approvalRegistry }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.changeApprovalRegistryCollection({ ids, approvalRegistry });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsResume = createAsyncThunk<
  boolean,
  { ids: UUID[] },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/actions/resume', async ({ ids }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.resumeCollection({ ids });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsDelete = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'corpOffer/actions/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await offerServices.corp.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferActionsDuplicate = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/actions/duplicate',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.duplicate({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferActionsNotUsedCodesDelete = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/actions/notUsedDelete',
  async ({ id }, { rejectWithValue }) => {
    try {
      await Api.personalPromotion.notUsedDelete({ offerId: id });
      return await offerServices.corp.one({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferActionsUnPublish = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/actions/unPublish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.unPublish({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferActionsPublish = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/actions/publish',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.publish({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOfferActionsReject = createAsyncThunk<
  boolean,
  { ids: UUID[]; reason: SportOption; comment?: string },
  AppThunkAPIConfig
>('corpOffer/actions/reject', async ({ ids, reason, comment }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.rejectCollection({ ids, reason, comment });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOfferActionsInWork = createAsyncThunk<CorpOffer, { id: UUID; userId: UUID }, AppThunkAPIConfig>(
  'corpOffer/actions/inWork',
  async ({ id, userId }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.inWork({ id, userId });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export type CorpOfferActionType = Fetchable & {
  id: UUID;
  type: EOfferActionType;
  error: Nullable<ServerErrorResponse>;
};

export type CorpOffersActionsSingleDialogData = {
  data: CorpOffer;
  notification?: boolean;
};

export type CorpOffersActionsMultipleDialogData = {
  data: CorpOffer[];
  notification?: boolean;
};

type CollectionActionTypes =
  | 'approveCollection'
  | 'changeApprovalRegistryCollection'
  | 'changeModeratorCollection'
  | 'archiveCollection'
  | 'rejectCollection'
  | 'pauseCollection'
  | 'resumeCollection';

export type CorpOffersActionsChangeDialogState =
  | {
      name: CollectionActionTypes;
      data: Nullable<CorpOffersActionsMultipleDialogData>;
    }
  | {
      name: Exclude<keyof CorpOffersActionsState['dialogs'], CollectionActionTypes>;
      data: Nullable<CorpOffersActionsSingleDialogData>;
    };

export type CorpOffersActionsState = {
  readonly actions: CorpOfferActionType[];
  readonly create: Fetchable;
  readonly dialogs: {
    readonly publish: Nullable<CorpOffersActionsSingleDialogData>;
    readonly pause: Nullable<CorpOffersActionsSingleDialogData>;
    readonly reject: Nullable<CorpOffersActionsSingleDialogData>;
    readonly archive: Nullable<CorpOffersActionsSingleDialogData>;
    readonly delete: Nullable<CorpOffersActionsSingleDialogData>;
    readonly resume: Nullable<CorpOffersActionsSingleDialogData>;
    readonly approve: Nullable<CorpOffersActionsSingleDialogData>;
    readonly duplicate: Nullable<CorpOffersActionsSingleDialogData>;
    readonly retrieve: Nullable<CorpOffersActionsSingleDialogData>;
    readonly createCodes: Nullable<CorpOffersActionsSingleDialogData>;
    readonly deleteCodes: Nullable<CorpOffersActionsSingleDialogData>;
    readonly unPublishAndEdit: Nullable<CorpOffersActionsSingleDialogData>;
    readonly unPublish: Nullable<CorpOffersActionsSingleDialogData>;
    readonly changeModerator: Nullable<CorpOffersActionsSingleDialogData>;
    readonly changeApprovalRegistry: Nullable<CorpOffersActionsSingleDialogData>;
    readonly returnToVerification: Nullable<CorpOffersActionsSingleDialogData>;
    readonly changeSortIndex: Nullable<CorpOffersActionsSingleDialogData>;
    readonly inWork: Nullable<CorpOffersActionsSingleDialogData>;

    readonly changeApprovalRegistryCollection: Nullable<CorpOffersActionsMultipleDialogData>;
    readonly approveCollection: Nullable<CorpOffersActionsMultipleDialogData>;
    readonly changeModeratorCollection: Nullable<CorpOffersActionsMultipleDialogData>;
    readonly archiveCollection: Nullable<CorpOffersActionsMultipleDialogData>;
    readonly rejectCollection: Nullable<CorpOffersActionsMultipleDialogData>;
    readonly pauseCollection: Nullable<CorpOffersActionsMultipleDialogData>;
    readonly resumeCollection: Nullable<CorpOffersActionsMultipleDialogData>;
  };
};

export type CorpOfferStateKeyDialogs = keyof CorpOffersActionsState['dialogs'];

interface Reducers extends SliceCaseReducers<CorpOffersActionsState> {
  corpOfferActionsChangeDialogState: Reducer<CorpOffersActionsChangeDialogState>;
  corpOfferActionsOptimize: Reducer;
}

type Reducer<T = undefined> = CaseReducer<CorpOffersActionsState, PayloadAction<T>>;

export const initialState: CorpOffersActionsState = {
  actions: [],
  create: fetchableDefault,
  dialogs: {
    publish: null,
    pause: null,
    reject: null,
    archive: null,
    delete: null,
    resume: null,
    duplicate: null,
    changeSortIndex: null,
    approve: null,
    retrieve: null,
    changeModerator: null,
    changeApprovalRegistry: null,
    returnToVerification: null,
    createCodes: null,
    deleteCodes: null,
    unPublish: null,
    unPublishAndEdit: null,
    inWork: null,

    approveCollection: null,
    changeModeratorCollection: null,
    changeApprovalRegistryCollection: null,
    archiveCollection: null,
    rejectCollection: null,
    pauseCollection: null,
    resumeCollection: null,
  },
};

const slice = createSlice<CorpOffersActionsState, Reducers>({
  name: 'corpOffer/actions',
  initialState,
  reducers: {
    corpOfferActionsChangeDialogState: (state, { payload }) => {
      const { name, data } = payload;
      switch (name) {
        case 'approveCollection':
          state.dialogs.approveCollection = data;
          break;
        case 'changeApprovalRegistryCollection':
          state.dialogs.changeApprovalRegistryCollection = data;
          break;
        case 'changeModeratorCollection':
          state.dialogs.changeModeratorCollection = data;
          break;
        case 'archiveCollection':
          state.dialogs.archiveCollection = data;
          break;
        case 'rejectCollection':
          state.dialogs.rejectCollection = data;
          break;
        case 'pauseCollection':
          state.dialogs.pauseCollection = data;
          break;
        case 'resumeCollection':
          state.dialogs.resumeCollection = data;
          break;
        default:
          state.dialogs[name] = data;
      }
    },
    corpOfferActionsOptimize: state => {
      // Оставляем только выполняющиеся действия
      state.actions = state.actions.filter(action => action.isFetching);
    },
  },
  extraReducers: builder => {
    builder
      /** Save */
      .addCase(corpOfferActionsSave.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Edit, fetchableFetching);
      })
      .addCase(corpOfferActionsSave.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Edit, fetchableFetched);
      })
      .addCase(corpOfferActionsSave.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Edit, fetchableFailed, payload);
      })
      /** Archive */
      .addCase(corpOfferActionsArchive.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Archive, fetchableFetching));
      })
      .addCase(corpOfferActionsArchive.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Archive, fetchableFetched));
      })
      .addCase(corpOfferActionsArchive.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Archive, fetchableFailed, payload));
      })
      /** Retrieve */
      .addCase(corpOfferActionsRetrieve.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Retrieve, fetchableFetching);
      })
      .addCase(corpOfferActionsRetrieve.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Retrieve, fetchableFetched);
      })
      .addCase(corpOfferActionsRetrieve.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Retrieve, fetchableFailed, payload);
      })
      /** Pause */
      .addCase(corpOfferActionsPause.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Pause, fetchableFetching));
      })
      .addCase(corpOfferActionsPause.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Pause, fetchableFetched));
      })
      .addCase(corpOfferActionsPause.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Pause, fetchableFailed, payload));
      })
      /** Approve */
      .addCase(corpOfferActionsApprove.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Approve, fetchableFetching));
      })
      .addCase(corpOfferActionsApprove.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Approve, fetchableFetched));
      })
      .addCase(corpOfferActionsApprove.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Approve, fetchableFailed, payload));
      })
      /** Return To verification */
      .addCase(corpOfferActionsReturnToVerification.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ReturnToVerification, fetchableFetching);
      })
      .addCase(corpOfferActionsReturnToVerification.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ReturnToVerification, fetchableFetched);
      })
      .addCase(corpOfferActionsReturnToVerification.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.ReturnToVerification, fetchableFailed, payload);
      })
      /** ChangeModerator */
      .addCase(corpOfferActionsChangeModerator.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.ChangeModerator, fetchableFetching));
      })
      .addCase(corpOfferActionsChangeModerator.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.ChangeModerator, fetchableFetched));
      })
      .addCase(corpOfferActionsChangeModerator.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.ChangeModerator, fetchableFailed, payload));
      })
      /** ChangeApprovalRegistry */
      .addCase(corpOfferActionsChangeApprovalRegistry.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.ChangeApprovalRegistry, fetchableFetching));
      })
      .addCase(corpOfferActionsChangeApprovalRegistry.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.ChangeApprovalRegistry, fetchableFetched));
      })
      .addCase(corpOfferActionsChangeApprovalRegistry.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id =>
          handleActionProcess(state, id, EOfferActionType.ChangeApprovalRegistry, fetchableFailed, payload)
        );
      })
      /** Resume */
      .addCase(corpOfferActionsResume.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Resume, fetchableFetching));
      })
      .addCase(corpOfferActionsResume.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Resume, fetchableFetched));
      })
      .addCase(corpOfferActionsResume.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Resume, fetchableFailed, payload));
      })
      /** Delete */
      .addCase(corpOfferActionsDelete.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Delete, fetchableFetching);
      })
      .addCase(corpOfferActionsDelete.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Delete, fetchableFetched);
      })
      .addCase(corpOfferActionsDelete.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Delete, fetchableFailed, payload);
      })
      /** Duplicate */
      .addCase(corpOfferActionsDuplicate.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Duplicate, fetchableFetching);
      })
      .addCase(corpOfferActionsDuplicate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Duplicate, fetchableFetched);
      })
      .addCase(corpOfferActionsDuplicate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Duplicate, fetchableFailed, payload);
      })
      /** DeleteCodes */
      .addCase(corpOfferActionsNotUsedCodesDelete.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.DeleteCodes, fetchableFetching);
      })
      .addCase(corpOfferActionsNotUsedCodesDelete.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.DeleteCodes, fetchableFetched);
      })
      .addCase(corpOfferActionsNotUsedCodesDelete.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.DeleteCodes, fetchableFailed, payload);
      })
      /** UnPublish */
      .addCase(corpOfferActionsUnPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.UnPublish, fetchableFetching);
      })
      .addCase(corpOfferActionsUnPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.UnPublish, fetchableFetched);
      })
      .addCase(corpOfferActionsUnPublish.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.UnPublish, fetchableFailed, payload);
      })
      /** InWork */
      .addCase(corpOfferActionsInWork.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.InWork, fetchableFetching);
      })
      .addCase(corpOfferActionsInWork.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.InWork, fetchableFetched);
      })
      .addCase(corpOfferActionsInWork.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.InWork, fetchableFailed, payload);
      })
      /** Reject */
      .addCase(corpOfferActionsReject.pending, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Reject, fetchableFetching));
      })
      .addCase(corpOfferActionsReject.fulfilled, (state, { meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Reject, fetchableFetched));
      })
      .addCase(corpOfferActionsReject.rejected, (state, { payload, meta }) => {
        const { ids } = meta.arg;
        ids.forEach(id => handleActionProcess(state, id, EOfferActionType.Reject, fetchableFailed, payload));
      })
      /** Publish */
      .addCase(corpOfferActionsPublish.pending, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Publish, fetchableFetching);
      })
      .addCase(corpOfferActionsPublish.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Publish, fetchableFetched);
      })
      .addCase(corpOfferActionsPublish.rejected, (state, { meta }) => {
        const { id } = meta.arg;
        handleActionProcess(state, id, EOfferActionType.Publish, fetchableFailed);
      })
      /** CreateCodes */
      .addCase(personalPromotionCreateGenerate.pending, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetching);
      })
      .addCase(personalPromotionCreateGenerate.fulfilled, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetched);
      })
      .addCase(personalPromotionCreateGenerate.rejected, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFailed);
      })
      /** UploadCodes */
      .addCase(personalPromotionCreateUpload.pending, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetching);
      })
      .addCase(personalPromotionCreateUpload.fulfilled, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFetched);
      })
      .addCase(personalPromotionCreateUpload.rejected, (state, { meta }) => {
        const { offerId } = meta.arg;
        handleActionProcess(state, offerId, EOfferActionType.CreateCodes, fetchableFailed);
      });
  },
});

export const { corpOfferActionsChangeDialogState, corpOfferActionsOptimize } = slice.actions;

export default slice.reducer;
