import { viewConfig } from '@theme/viewConfig';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import TopPushDialog from '../../../components/common/dialogs/topPush';
import OfferHistoryTableContainer from '../../offerHistory/table/container';
import { EVariantHistory } from '../../offerHistory/table/utils/common';
import { useContextHandlers } from './hooks/useContextHandlers';
import { bookingOfferDetailsBookingOfferSelector, bookingOfferDetailsDialogsSelector } from './store/selectors';

export const BookingOfferDetailsDialogsAdapter = () => {
  const handlers = useContextHandlers();

  const dialogs = useSelector(bookingOfferDetailsDialogsSelector);
  const offer = useSelector(bookingOfferDetailsBookingOfferSelector);

  const handleHideHistory = useCallback(() => {
    handlers.onChangeDialogState('history', null);
  }, [handlers.onChangeDialogState]);

  if (!offer) {
    return null;
  }

  return (
    <>
      <TopPushDialog
        fullScreen
        open={!!dialogs.history}
        title='История'
        leftOffset={viewConfig.stepper.width}
        onClose={handleHideHistory}
      >
        <OfferHistoryTableContainer
          offerId={offer.id}
          typeHistory={EVariantHistory.BookingOffer}
        />
      </TopPushDialog>
    </>
  );
};
