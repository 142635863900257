import { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import ActionsComponent from '../../../components/common/actions';
import TablePagination from '../../../components/common/table/pagination';
import { PanelAction } from '../../../types';
import { CorpOfferLifeCycle } from '../../general/offer/lifecycle/types';
import {
  EOfferActionType,
  EOfferTableTab,
  OfferActions,
  OfferActionTableCollectionType,
  OfferActionTableType,
} from '../../general/offer/types';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import { FooterWrapper } from './controls';
import useCorpOfferCollectionActions from './hooks/useCollectionActions';
import { useContextHandlers } from './hooks/useContextHandlers';
import {
  corpOffersPageCountSelector,
  corpOffersPageNumberSelector,
  corpOffersSearchSelector,
  corpOffersSelectedSelector,
  corpOffersTotalCountSelector,
} from './store/selectors';
import { CorpOfferCollectionActionPayload } from './utils';

interface CorpOfferTableFooterContainerProps {
  readonly tab: EOfferTableTab;
  readonly allowedActions: OfferActions<OfferActionTableType>;
  readonly lifecycle: CorpOfferLifeCycle;
}

const CorpOfferTableFooterContainer = forwardRef((props: CorpOfferTableFooterContainerProps, ref: any) => {
  const { tab, lifecycle, allowedActions } = props;

  const { userSpecific } = useCurrentUser();

  const handlers = useContextHandlers();

  const pageNumber = useSelector(corpOffersPageNumberSelector);
  const search = useSelector(corpOffersSearchSelector);
  const totalCount = useSelector(corpOffersTotalCountSelector);
  const pageCount = useSelector(corpOffersPageCountSelector);

  const selectedCorpOffers = useSelector(corpOffersSelectedSelector);

  const onPanelAction = (action: PanelAction<OfferActionTableCollectionType, CorpOfferCollectionActionPayload>) => {
    const { type, payload } = action;
    const corpOffers = payload?.corpOffers ?? [];
    const corpOffersToAction = corpOffers.filter(offer => lifecycle.build({ obj: offer, allowedActions }).can(type));

    switch (type) {
      case EOfferActionType.Pause:
        handlers.onCorpOffersPause(corpOffersToAction);
        break;
      case EOfferActionType.Reject:
        handlers.onCorpOffersReject(corpOffersToAction);
        break;
      case EOfferActionType.Archive:
        handlers.onCorpOffersArchive(corpOffersToAction);
        break;
      case EOfferActionType.ChangeApprovalRegistry:
        handlers.onCorpOffersChangeApprovalRegistry(corpOffersToAction);
        break;
      case EOfferActionType.ChangeModerator:
        handlers.onCorpOffersChangeModerator(corpOffersToAction);
        break;
      case EOfferActionType.Approve:
        handlers.onCorpOffersApprove(corpOffersToAction);
        break;
      case EOfferActionType.Resume:
        handlers.onCorpOffersResume(corpOffersToAction);
        break;
    }
  };

  const collectionActions = useCorpOfferCollectionActions({
    tab,
    userSpecific,
    corpOffers: selectedCorpOffers,
    lifecycle,
    allowedActions,
  });

  const actionsPanel = collectionActions.length > 0 && (
    <ActionsComponent<OfferActionTableCollectionType>
      actions={collectionActions.map(action => ({ ...action, payload: { corpOffers: selectedCorpOffers } }))}
      onAction={onPanelAction}
      navAdapter={null}
    />
  );

  return (
    <div ref={ref}>
      <TablePagination
        page={pageNumber}
        pageSize={search.pageSize}
        pageCount={pageCount}
        objectsCount={totalCount}
        objectsLabel='корпоративных предложений'
        onChangePageSize={handlers.onCorpOffersChangePageSize}
        onChangePage={handlers.onCorpOffersChangePage}
      />
      {actionsPanel && <FooterWrapper>{actionsPanel}</FooterWrapper>}
    </div>
  );
});

export default CorpOfferTableFooterContainer;
