import { RootState } from '@/data/store/store';
import { ClientOrgCreate } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';

export const clientOrgCreateClientOrgSelector = (store: RootState) => store.clientOrg.create.byId.clientOrg;

export const clientOrgCreateClientOrgIdSelector = (store: RootState) => store.clientOrg.create.byId.clientOrg.id;

export const clientOrgCreateClientOrgStatusSelector = (store: RootState) =>
  store.clientOrg.create.byId.clientOrg.status;

export const clientOrgCreateClientOrgIsPresentSelector = (store: RootState) => !!store.clientOrg.create.byId.clientOrg;

export const clientOrgCreateClientOrgFirLifeCycleSelector = createSelector(
  (store: RootState) => store.clientOrg.create.byId.clientOrg.id,
  (store: RootState) => store.clientOrg.create.byId.clientOrg.status,
  (store: RootState) => store.clientOrg.create.byId.clientOrg.readOnly,
  (id, status, readOnly) => ({
    id,
    status,
    readOnly,
  })
);

export const clientOrgCreateClientOrgFieldByNameSelector =
  <Field extends keyof ClientOrgCreate>(field: Field) =>
  (store: RootState) =>
    store.clientOrg.create.byId.clientOrg?.[field];

export const clientOrgCreateModifiedSelector = (store: RootState) => store.clientOrg.create.modified;

export const clientOrgCreateValidationStepperSelector = (store: RootState) => store.clientOrg.create.validationStepper;

export const clientOrgCreateIsPreloadingSelector = (store: RootState) => store.clientOrg.create.byId.isFetching;

export const clientOrgCreateGuidSelector = (store: RootState) => store.clientOrg.create.guid;

export const clientOrgCreateUiStepsStateSelector = (store: RootState) => store.clientOrg.create.ui?.steps ?? null;

export const clientOrgCreateIsFieldVisibleSelector = (field: keyof ClientOrgCreate) => (store: RootState) => true;

export const clientOrgCreateIsFieldDisabledSelector = (field: keyof ClientOrgCreate) => (store: RootState) =>
  !!store.clientOrg.create.ui?.fields?.disabled?.find(f => f === field);

export const clientOrgCreateValidationResultsSelector = (store: RootState) => store.clientOrg.create.validation;

export const clientOrgCreateValidationResultsByFieldSelector = (field: keyof ClientOrgCreate) => (store: RootState) =>
  store.clientOrg.create.validation[field] ?? null;

export const clientOrgCreateDialogCloseSelector = (store: RootState) => store.clientOrg.create.dialogs?.close;

export const clientOrgCreateDialogDiscardSelector = (store: RootState) => store.clientOrg.create.dialogs?.discard;
