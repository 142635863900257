import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { useCorpOfferActions } from '../useActions';
import { CorpOfferActionsConfirmDialog } from '@features/corpOffer/actions/components';
import { CorpOfferStateKeyDialogs } from '../store/slice';

const nameDialog: CorpOfferStateKeyDialogs = 'deleteCodes';

const CorpOfferActionsDialogDeleteCodesAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onDelete = useCallback(() => {
    action?.data &&
      handlers.onDeleteCodes(action.data.id, action.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onDeleteCodes, action]);

  return (
    action?.data && (
      <CorpOfferActionsConfirmDialog
        title={`Удалить все неприсвоенные коды?`}
        text={'В случае удаления всех неприсвоенных кодов, корпоративное предложение будет приостановлено'}
        actionText='Удалить'
        isFetching={isProcessing}
        onAction={onDelete}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogDeleteCodesAdapter;
