import { ButtonLink } from '@components/common/buttons/link';
import { StepperButtonWrapper } from '@features/bookingOffer/details/adapters/controls';
import { useSelector } from 'react-redux';
import AppSvgIcon from '../../../../components/common/images/icon';
import { TimerIcon } from '../../../../icons';
import { HistoryLink } from '../controls';
import { useContextHandlers } from '../hooks/useContextHandlers';
import { useContextParams } from '../hooks/useContextParams';
import { bookingOfferDetailsBookingOfferSelector } from '../store/selectors';

const BookingOfferDetailsButtonsAdapter = () => {
  const {
    access: { viewHistory },
  } = useContextParams();
  const bookingOffer = useSelector(bookingOfferDetailsBookingOfferSelector);

  const handlers = useContextHandlers();

  const onShowHistory = () => {
    handlers.onChangeDialogState('history', bookingOffer);
  };

  return viewHistory ? (
    <StepperButtonWrapper>
      <ButtonLink
        variant='text'
        fullWidth={false}
        onClick={onShowHistory}
      >
        <AppSvgIcon
          fontSize='small'
          icon={TimerIcon}
        />
        <HistoryLink>История</HistoryLink>
      </ButtonLink>
    </StepperButtonWrapper>
  ) : null;
};

export default BookingOfferDetailsButtonsAdapter;
