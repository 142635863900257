import { ClientOrgCreateDialogCloseAdapter } from './dialogClose';
import { ClientOrgCreateDialogDiscardAdapter } from './dialogDiscard';

export const ClientOrgCreateDialogsAdapter = () => {
  return (
    <>
      <ClientOrgCreateDialogCloseAdapter />
      <ClientOrgCreateDialogDiscardAdapter />
    </>
  );
};
