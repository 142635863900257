import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { SportOption } from '../../../../../../domain/model';
import { Nullable, UUID } from '../../../../../../domain/model/types';
import ConfirmDialog from '../../../../../components/common/dialogs/confirm';
import ContentLoader from '../../../../../components/common/loader';
import { MPButton } from '../../../../../theme/ui-kit/button';
import { MPFormTextArea } from '../../../../../theme/ui-kit/input';

interface OfferReasonsDialogProps<T> {
  readonly title: string;
  readonly actionText: string;
  readonly text?: ReactNode;
  readonly isFetching: boolean;
  readonly reasons: SportOption[];
  readonly onAction: (reason: SportOption, comment?: string) => void;
  readonly onClose: () => void;
}

const OfferReasonsDialog = <T extends object>(props: OfferReasonsDialogProps<T>) => {
  const { title, actionText, text, reasons, isFetching, onAction, onClose } = props;

  const [reasonMsg, setReasonMsg] = useState<string>('');
  const [reasonId, setReasonId] = useState<Nullable<UUID>>(null);

  const onChangeReasonId = (reasonParam: UUID) => {
    setReasonId(reasonParam);
  };

  const onChangeReasonMsg = (reasonParam: string) => {
    setReasonMsg(reasonParam);
  };

  const reasonAction = () => {
    const reason = reasons.find(item => item.id === reasonId);
    if (reason) {
      onAction(reason, reasonMsg);
    }
  };

  return (
    <ConfirmDialog
      open
      onClose={onClose}
      title={title}
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={!reasonId || isFetching}
              onClick={reasonAction}
            >
              {actionText}
              {isFetching && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isFetching}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        <Grid item>
          <Typography
            gutterBottom={true}
            component='div'
          >
            {text || 'Укажите причину'}
          </Typography>
          <RadioGroup
            value={reasonId || ''}
            onChange={event => onChangeReasonId(event.target.value)}
          >
            {reasons.map(item => (
              <FormControlLabel
                key={item.id}
                id={item.id}
                value={item.id}
                control={<Radio color={'primary'} />}
                label={item.name}
              />
            ))}
          </RadioGroup>
        </Grid>
        <Grid item>
          <MPFormTextArea
            rows={3}
            label='Комментарий'
            value={reasonMsg}
            margin='none'
            disabled={isFetching}
            onChange={event => onChangeReasonMsg(event.target.value)}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default OfferReasonsDialog;
