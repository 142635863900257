import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getIsCorpOfferAnyActionByTypeExecutingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';
import { CorpOfferActionsConfirmDialog } from '../components';
import { pluralize } from 'presentation/utils/pluralize';
import { EOfferActionType } from '@features/general/offer/types';

const nameDialog: CorpOfferStateKeyDialogs = 'archiveCollection';

const CorpOfferActionsDialogArchiveCollectionAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getIsCorpOfferAnyActionByTypeExecutingSelector(EOfferActionType.Archive));

  const text =
    action?.data?.length &&
    `Будет отправлено в архив ${pluralize(action.data.length, [
      'корпоративное предложение',
      'корпоративных предложения',
      'корпоративных предложений',
    ])}: ${action.data.length}`;

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onArchive = useCallback(() => {
    action?.data &&
      handlers.onArchive(action.data, action?.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onArchive, action]);

  return (
    action?.data && (
      <CorpOfferActionsConfirmDialog
        title='Архивирование корпоративных предложений'
        actionText='Отправить в архив'
        text={text}
        onAction={onArchive}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogArchiveCollectionAdapter;
