import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { useCorpOfferActions } from '../useActions';
import { CorpOfferActionsConfirmDialog } from '@features/corpOffer/actions/components';
import { Grid, Typography } from '@mui/material';
import { CorpOfferStateKeyDialogs } from '../store/slice';

const nameDialog: CorpOfferStateKeyDialogs = 'delete';

const CorpOfferActionsDialogDeleteAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onDelete = useCallback(() => {
    action?.data &&
      handlers.onDelete(action.data.id, action?.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onDelete, action]);

  return (
    action?.data && (
      <CorpOfferActionsConfirmDialog
        title={`Удалить безвозвратно корпоративное предложение?`}
        actionText='Удалить'
        isFetching={isProcessing}
        onAction={onDelete}
        onClose={onCloseDialog}
      >
        <Grid item>
          <Typography>Партнер: {action.data.partner?.name}</Typography>
        </Grid>
        <Grid item>
          <Typography>Предложение: {action.data.name}</Typography>
        </Grid>
      </CorpOfferActionsConfirmDialog>
    )
  );
};

export default CorpOfferActionsDialogDeleteAdapter;
