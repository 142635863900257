import { CorpOfferActionsConfirmDialog } from '@features/corpOffer/actions/components';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'approve';

const CorpOfferActionsDialogApproveAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onApprove = useCallback(() => {
    action?.data &&
      handlers.onApprove([action.data], action.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onApprove, action]);

  return (
    action?.data && (
      <CorpOfferActionsConfirmDialog
        title='Опубликовать предложение?'
        actionText='Опубликовать'
        onAction={onApprove}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogApproveAdapter;
