import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { useCorpOfferActions } from '../useActions';
import { CorpOfferActionsConfirmDialog } from '@features/corpOffer/actions/components';
import { CorpOfferStateKeyDialogs } from '../store/slice';

const nameDialog: CorpOfferStateKeyDialogs = 'unPublish';

const CorpOfferActionsDialogUnPublishAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onPause = useCallback(() => {
    action?.data &&
      handlers.onUnPublish(action.data.id, action.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onUnPublish, action]);

  return (
    action?.data && (
      <CorpOfferActionsConfirmDialog
        title='Отозвать корпоративное предложение?'
        text={`Корпоративное предложение ${action.data.id} '${action.data.name}' будет отозвано и сохранено в черновиках.`}
        actionText='Отозвать'
        isFetching={isProcessing}
        onAction={onPause}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogUnPublishAdapter;
