import { ButtonLink } from '@components/common/buttons/link';
import { useMemo } from 'react';

type SupportInfoMpFeedbackAdapterProps = {
  readonly onShowFeedback?: () => void;
};
const SupportInfoMpFeedbackAdapter = ({ onShowFeedback }: SupportInfoMpFeedbackAdapterProps) => {
  return useMemo(() => {
    return onShowFeedback ? (
      <ButtonLink
        variant='text'
        fullWidth={false}
        onClick={onShowFeedback}
      >
        Форма обратной связи
      </ButtonLink>
    ) : null;
  }, [onShowFeedback]);
};

export default SupportInfoMpFeedbackAdapter;
