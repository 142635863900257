import styled from '@emotion/styled';
import { Typography, TypographyProps } from '@mui/material';
import theme from '../../../theme';
import { publicAppPalette } from '../../palette';
import { ETagColors } from './utils';

interface WrapperProps extends Omit<TypographyProps, 'variant' | 'color'> {
  readonly color?: ETagColors;
  readonly variant?: 'outlined' | 'filled';
  readonly dense?: boolean;
  readonly bold?: boolean;
}

export const Wrapper = styled(({ color, variant, dense, bold, ...props }: WrapperProps) => <Typography {...props} />)`
  overflow: hidden;
  border-width: 1px;
  border-style: solid;
  border-radius: ${p => p.theme.spacing(0.5)};

  padding: ${p => (p.dense ? p.theme.spacing(0.25) : p.theme.spacing(0.5))}
    ${p => (p.dense ? p.theme.spacing() : p.theme.spacing(1.5))};

  border-color: ${p => getColorFromTheme(p.color as any)};
  color: ${p => getColorTextWithFilled(p.color as any, p.variant as any)};
  background-color: ${p => ((p.variant as any) === 'filled' ? getColorFromTheme(p.color as any) : 'transparent')};

  // Отключаем этими настройками наследование шрифтов
  font-weight: ${p => (p.bold ? p.theme.typography.fontWeightSemiBold : p.theme.typography.fontWeightRegular)};
  font-size: ${p => p.theme.typography.body2.fontSize};
  line-height: ${p => p.theme.typography.body2.lineHeight};
  white-space: nowrap;
`;

function getColorFromTheme(color: ETagColors): string {
  switch (color) {
    case ETagColors.Default:
      return theme.palette.text.primary;
    case ETagColors.Primary: {
      return theme.palette.primary.main;
    }
    case ETagColors.Success: {
      return theme.palette.success.main;
    }
    case ETagColors.Secondary: {
      return theme.palette.text.secondary;
    }
    case ETagColors.Warning: {
      return theme.palette.error.main;
    }
    case ETagColors.Error: {
      return publicAppPalette.primary;
    }
    case ETagColors.Disabled: {
      return theme.palette.disabled.text;
    }
    default:
      return theme.palette.text.primary;
  }
}

function getColorTextWithFilled(color: ETagColors, variant: 'filled' | 'outlined'): string {
  switch (color) {
    case ETagColors.Default:
    case ETagColors.Primary:
    case ETagColors.Success:
    case ETagColors.Warning:
    case ETagColors.Error:
    case ETagColors.Disabled: {
      if (variant === 'filled') {
        return theme.palette.text.onPrimary;
      } else {
        return getColorFromTheme(color);
      }
    }
    case ETagColors.Secondary: {
      if (variant === 'filled') {
        return theme.palette.text.primary;
      } else {
        return getColorFromTheme(color);
      }
    }
    default:
      return getColorFromTheme(color);
  }
}
