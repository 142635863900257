import { FCC } from 'react';
import { Wrapper } from './controls';

type SidePanelContentWrapperProps = {
  readonly width?: string;
};

export const SidePanelContentWrapper: FCC<SidePanelContentWrapperProps> = ({ width = 'min-content', children }) => {
  return <Wrapper width={width}>{children}</Wrapper>;
};
