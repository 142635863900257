import { ApprovalOfferRegistryRequest } from '@/domain';
import { OnChangeObjectAttribute } from 'presentation/types';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CorpOfferActionsApprovalRegistryDialog from '../components/approvalRegistryDialog';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'changeApprovalRegistry';

const CorpOfferActionsDialogChangeApprovalRegistryAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const [approvalRegistry, setApprovalRegistry] = useState<ApprovalOfferRegistryRequest>({ number: null, date: null });

  const onChangeApprovalRegistryAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest> = (name, value) => {
    setApprovalRegistry((prevState: ApprovalOfferRegistryRequest) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    setApprovalRegistry(
      action?.data?.approvalRegistry
        ? { number: action.data.approvalRegistry.number, date: action.data.approvalRegistry.date }
        : { number: null, date: null }
    );
  }, [action?.data]);

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onApprove = useCallback(() => {
    action?.data &&
      handlers.onChangeApprovalRegistry([action.data], approvalRegistry, action?.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onApprove, approvalRegistry, action]);

  return (
    action?.data && (
      <CorpOfferActionsApprovalRegistryDialog
        open={!!action.data}
        approvalRegistry={approvalRegistry}
        isValidation
        isExecuting={isProcessing}
        onChangeAttribute={onChangeApprovalRegistryAttribute}
        onChange={onApprove}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogChangeApprovalRegistryAdapter;
