import { useCallback, useState } from 'react';
import Api from '../../../data/api';
import useCurrentUser from '../user/hooks/useCurrentUser';
import { FeedbackData } from './index';

type UseFeedback = {
  feedbackVisible: boolean;
  onShowFeedback(): void;
  onHideFeedback(): void;
  onSubmitFeedback(data: FeedbackData): Promise<boolean>;
};

export function useFeedback(): UseFeedback {
  const [feedbackVisible, setFeedbackVisible] = useState<boolean>(false);
  const { userSpecific } = useCurrentUser();

  const onShowFeedback = useCallback(() => {
    setFeedbackVisible(true);
  }, []);

  const onHideFeedback = useCallback(() => {
    setFeedbackVisible(false);
  }, []);

  const onSubmitFeedback = useCallback(async (data: FeedbackData): Promise<boolean> => {
    try {
      const response = await Api.common.feedback({
        message: data.formText,
        email: userSpecific.email,
        name: 'Витрина. Форма обратной связи',
        attachment: data.formFile?.path
          ? {
              path: data.formFile.path,
            }
          : null,
      });
      return response.status === 200;
    } catch (e: any) {
      throw new Error(e.response.data.message);
    }
  }, []);

  return {
    feedbackVisible,
    onShowFeedback,
    onHideFeedback,
    onSubmitFeedback,
  };
}
