import styled from '@emotion/styled';
import { Typography, TypographyProps } from '@mui/material';
import { EPartnerStatus } from '../../../../domain/model/enums';
import { viewConfig } from '../../../theme/viewConfig';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div``;

export const FooterWrapper = styled.div`
  border-top: 1px solid ${p => p.theme.palette.secondary.main};

  padding: ${p => p.theme.spacing(2)} ${p => p.theme.spacing(4)};
`;

export const ColumnWrapper = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;

  padding-top: ${p => p.theme.spacing(2.25)};
`;

export const Content = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-top: ${p => p.theme.spacing()};
  margin-bottom: ${p => p.theme.spacing(3)};
`;

export const LoaderWrapper = styled.div`
  z-index: 3;
  position: fixed;
  top: 0;
  left: ${viewConfig.stepper.width};
  right: 0;
  bottom: 0;
`;

export const ContentContainer = styled.div`
  width: 100%;
  height: 100%;

  padding-left: ${viewConfig.stepper.width};
`;

export const TitleWrapper = styled.div`
  width: ${viewConfig.sidePanel.contentWidth};

  padding-bottom: ${p => p.theme.spacing(1.5)};

  border-bottom: 1px solid ${p => p.theme.palette.disabled.background};
`;

interface StatusLabelProps extends TypographyProps {
  readonly status: EPartnerStatus;
}

export const StatusLabel = styled(({ status, ...others }: StatusLabelProps & { children?: any }) => (
  <Typography {...others} />
))`
  display: inline-block;
  padding: ${p => p.theme.spacing(0.25, 1)};
  color: ${p => p.theme.palette.background.default};
  background: ${p =>
    p.status === EPartnerStatus.OnVerification
      ? p.theme.palette.error.main
      : p.status === EPartnerStatus.Enabled
      ? p.theme.palette.success.main
      : p.theme.palette.secondary.dark};
  border-radius: ${p => p.theme.shape.borderRadius}px;
`;
