import useAdCampaignDetailsStepper from '@features/adCampaign/details/hooks/useStepper';
import StepperContainer from '../../../general/stepper/container';
import { AdCampaignDetailsStepType } from '../../types';

const AdCampaignDetailsStepperAdapter = () => {
  const { currentStep, steps, openStep } = useAdCampaignDetailsStepper();

  return (
    <StepperContainer<AdCampaignDetailsStepType>
      step={currentStep}
      steps={steps}
      flow={false}
      onClick={openStep}
    />
  );
};

export default AdCampaignDetailsStepperAdapter;
