import { AnyOffer, EOfferType } from '@/domain';

export interface OfferChangeModeratorMultipleDescriptionProps<T extends AnyOffer> {
  readonly offerType: EOfferType;
  readonly offers: T[];
}

export const OfferChangeModeratorMultipleDescription = <T extends AnyOffer>(
  props: OfferChangeModeratorMultipleDescriptionProps<T>
) => {
  const { offers, offerType } = props;

  const label = offerType === EOfferType.Corp ? 'корпоративных предложений' : 'торговых предложений';

  if (!offers.length) {
    return null;
  }

  return `Выберите администратора на которого будет назначена проверка ${label}: ${offers.length}`;
};
