import { Fade, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppDispatch } from '../../../../../data/store/store';
import { EPartnerStatus } from '../../../../../domain/model/enums';
import { UUID } from '../../../../../domain/model/types';
import { addSearchParamToLocation } from '../../../../../routing/globalRouting';
import MasterActionsComponent from '../../../../components/common/actions/master';
import AppBreadcrumbs from '../../../../components/common/breadcrumbs';
import DefaultHeader from '../../../../components/common/header';
import ContentLoader from '../../../../components/common/loader';
import Splitter from '../../../../components/common/splitter';
import { DefaultContentWrapper } from '../../../../components/common/wrappers/content';
import { DefaultFooterWrapper } from '../../../../components/common/wrappers/footer';
import { PanelAction, PanelActions } from '../../../../types';
import useBreadcrumbs from '../../../general/breadcrumbs/useBreadcrumbs';
import { nsiDataSelector } from '../../../general/nsi/store/selectors';
import { EPartnerActionType, EPartnerStep, PartnerActionDetailsType } from '../../../general/partner/types';
import { getPartnerDeskEditRoute } from '../../../partnerDesk/entry';
import { EPartnerUrlParam, getPartnerEditRoute } from '../../entry';
import { ContainerWrapper, HeaderWrapper, LoaderWrapper } from '../controls';
import PartnerDetailsTabs from '../tabs';
import {
  EPartnerDetailsLegalTab,
  EPartnerDetailsTab,
  getPartnerDetailsActions,
  getPartnerDetailsLegalTabs,
  PartnerDetailsLegalTab,
} from '../utils';
import PartnerDetailsAdminComponent from './component';
import {
  partnerDetailsAdminByIdSelector,
  partnerDetailsAdminChangeStatusSelector,
  partnerDetailsAdminNeedRefreshWatcherSelector,
} from './store/selectors';
import {
  partnerDetailsAdminByIdFetch,
  partnerDetailsAdminChangePartnerStatus,
  partnerDetailsAdminStateReset,
} from './store/slice';

interface PartnerDetailsAdminContainerProps {
  readonly id: UUID;
  readonly tab?: EPartnerDetailsTab;
  readonly legalTab?: EPartnerDetailsLegalTab;
  readonly canEdit?: boolean;
  readonly canEditDesk?: boolean;
  readonly canActivate?: boolean;
  readonly canDeactivate?: boolean;
}

const PartnerDetailsAdminContainer = (props: PartnerDetailsAdminContainerProps) => {
  const {
    id,
    tab = EPartnerDetailsTab.Legal,
    legalTab = EPartnerDetailsLegalTab.Common,
    canEdit,
    canEditDesk,
    canActivate,
    canDeactivate,
  } = props;

  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();

  const { partner, desk, notifications, isFetching } = useSelector(partnerDetailsAdminByIdSelector);
  const needRefreshWatcher = useSelector(partnerDetailsAdminNeedRefreshWatcherSelector);
  const { isFetching: changeStatusIsFetching } = useSelector(partnerDetailsAdminChangeStatusSelector);
  const { taxationSystemTypes, partnerTypes, partnerOwnerShipTypes } = useSelector(nsiDataSelector);

  const breadcrumbs = useBreadcrumbs();

  const onChangeTab = (newTab: EPartnerDetailsTab) => {
    history.replace(addSearchParamToLocation({ location, param: EPartnerUrlParam.Tab, value: newTab }));
  };

  const onEditLegal = () => {
    const step = legalTab === EPartnerDetailsLegalTab.Permissions ? EPartnerStep.Permissions : EPartnerStep.General;
    history.push(getPartnerEditRoute({ id, step }));
  };

  const onEditDesk = () => {
    history.push(getPartnerDeskEditRoute({ partnerId: id }));
  };

  const onChangeStatus = (status: EPartnerStatus) => {
    dispatch(partnerDetailsAdminChangePartnerStatus({ id, status }));
  };

  const onChangeLegalTab = (newLegalTab: PartnerDetailsLegalTab) => {
    history.replace(addSearchParamToLocation({ location, param: EPartnerUrlParam.LegalTab, value: newLegalTab.id }));
  };

  const onPanelAction = (action: PanelAction<PartnerActionDetailsType>) => {
    const { type } = action;
    switch (type) {
      case EPartnerActionType.Edit:
        onEditLegal();
        break;
      case EPartnerActionType.EditDesk:
        onEditDesk();
        break;
      case EPartnerActionType.Activate:
        onChangeStatus(EPartnerStatus.Enabled);
        break;
      case EPartnerActionType.Deactivate:
        onChangeStatus(EPartnerStatus.Disabled);
        break;
      case EPartnerActionType.ChangeSortIndex:
        break;
    }
  };

  // начальная загрузка
  useEffect(() => {
    const promise = dispatch(partnerDetailsAdminByIdFetch({ id }));
    return () => {
      dispatch(partnerDetailsAdminStateReset());
      promise?.abort();
    };
  }, [dispatch]);

  // необходимость обновить данные
  useEffect(() => {
    if (needRefreshWatcher > 0) {
      const promise = dispatch(partnerDetailsAdminByIdFetch({ id }));
      return () => {
        promise?.abort();
      };
    }
  }, [dispatch, needRefreshWatcher]);

  const actions: PanelActions<PartnerActionDetailsType> = partner
    ? getPartnerDetailsActions({
        status: partner.status,
        tab,
        canEdit,
        canEditDesk: !!desk && canEditDesk,
        canActivate,
        canDeactivate,
      })
    : [];

  const actionsPanel = (
    <MasterActionsComponent<PartnerActionDetailsType>
      actions={actions}
      show={!!actions.length}
      onAction={onPanelAction}
      wrapper={DefaultFooterWrapper}
    />
  );

  return (
    <Fade in>
      <DefaultContentWrapper
        type='details'
        stickyHeader
        fullHeight
        footer={actionsPanel}
      >
        <ContainerWrapper>
          {partner && (
            <>
              <HeaderWrapper>
                <DefaultHeader headline={<>{breadcrumbs.length > 0 && <AppBreadcrumbs />}</>}>
                  <Typography variant='h2'>Компания</Typography>
                </DefaultHeader>
                <PartnerDetailsTabs
                  tab={tab}
                  onChangeTab={onChangeTab}
                />
              </HeaderWrapper>
              <Splitter
                variant='horizontal'
                size={4}
              />
              <PartnerDetailsAdminComponent
                tab={tab}
                legalTabs={getPartnerDetailsLegalTabs(partner.regInfo?.orgType ?? null)}
                legalTab={legalTab}
                partner={partner}
                partnerTypes={partnerTypes}
                desk={desk}
                notifications={notifications}
                taxSystems={taxationSystemTypes}
                ownerShipTypes={partnerOwnerShipTypes}
                onChangeLegalTab={onChangeLegalTab}
              />
            </>
          )}

          {(isFetching || changeStatusIsFetching) && (
            <LoaderWrapper>
              <ContentLoader
                size={75}
                alpha
              />
            </LoaderWrapper>
          )}
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default PartnerDetailsAdminContainer;
