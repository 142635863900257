import { isOfferTypeWithCodes } from '@features/general/offer/utils/common';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { CorpOfferActionsConfirmDialog } from '../components';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'resume';

const CorpOfferActionsDialogResumeAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const isCodes =
    action?.data?.promotionType &&
    isOfferTypeWithCodes(action.data.promotionType) &&
    action.data.offerCount === action.data.offerCount - action.data.notUsedOfferCount;

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onChangeDialogState]);

  const onOpenCreateCodes = useCallback(() => {
    onCloseDialog();
    action?.data && handlers.onTryCreateCodes({ data: action.data, notification: action.notification });
  }, [handlers.onResume, action]);

  const onResume = useCallback(() => {
    action?.data &&
      handlers.onResume([action.data], action.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onResume, action]);

  if (!action?.data) {
    return null;
  }

  return isCodes ? (
    <CorpOfferActionsConfirmDialog
      title='Публикация корпоративного предложения невозможно'
      text={'Опубликовать корпоративное предложение невозможно, так как закончились коды. Пополните базу кодов'}
      actionText='Пополнить базу'
      isFetching={isProcessing}
      onAction={onOpenCreateCodes}
      onClose={onCloseDialog}
    />
  ) : (
    <CorpOfferActionsConfirmDialog
      title={`Опубликовать корпоративное предложение?`}
      actionText='Опубликовать'
      isFetching={isProcessing}
      onAction={onResume}
      onClose={onCloseDialog}
    />
  );
};

export default CorpOfferActionsDialogResumeAdapter;
