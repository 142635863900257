import { ApprovalOfferRegistryRequest } from '@/domain';
import ConfirmDialog from '@components/common/dialogs/confirm';
import ContentLoader from '@components/common/loader';
import CorpOfferApprovalRegistryEdit from '@features/corpOffer/components/approvalRegistry/edit';
import { Grid, Typography } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import useValidation from 'presentation/hooks/validation/useValidation';
import { OnChangeObjectAttribute } from 'presentation/types';
import { ReactNode, useState } from 'react';

interface CorpOfferActionsApprovalRegistryDialogProps {
  readonly open: boolean;
  readonly approvalRegistry: Nullable<ApprovalOfferRegistryRequest>;
  readonly isValidation?: boolean;
  readonly isExecuting?: boolean;
  readonly description?: ReactNode;
  readonly onChangeAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest>;
  readonly onChange: () => void;
  readonly onClose: () => void;
}

const CorpOfferActionsApprovalRegistryDialog = (props: CorpOfferActionsApprovalRegistryDialogProps) => {
  const { open, approvalRegistry, isValidation, isExecuting, description, onChangeAttribute, onChange, onClose } =
    props;

  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);

  const { validationResult, validate } = useValidation<ApprovalOfferRegistryRequest>({
    object: approvalRegistry,
    validateOnChange,
    rules: isValidation
      ? {
          number: {
            required: true,
          },
          date: {
            required: true,
          },
        }
      : {},
  });

  const onInternalChange = () => {
    const isValidObject = validate();
    if (isValidObject) {
      onChange();
    } else {
      setValidateOnChange(true);
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title='Присвоить приказ?'
      dialogButtons={
        <Grid
          container
          spacing={3}
        >
          <Grid item>
            <MPButton
              fullWidth={false}
              disabled={isExecuting}
              onClick={onInternalChange}
            >
              Присвоить
              {isExecuting && <ContentLoader />}
            </MPButton>
          </Grid>
          <Grid item>
            <MPButton
              variant='outlined'
              disabled={isExecuting}
              fullWidth={false}
              onClick={onClose}
            >
              Отменить
            </MPButton>
          </Grid>
        </Grid>
      }
    >
      <Grid
        container
        spacing={2}
        wrap='nowrap'
        direction='column'
      >
        {description && (
          <Grid item>
            <Typography>{description}</Typography>
          </Grid>
        )}
        <Grid item>
          <CorpOfferApprovalRegistryEdit
            approvalRegistry={approvalRegistry}
            validation={validationResult}
            onChangeAttribute={onChangeAttribute}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default CorpOfferActionsApprovalRegistryDialog;
