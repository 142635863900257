import { CorpOffer } from '@/domain';
import { useCorpOfferActions } from '@features/corpOffer/actions/useActions';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useDataTableFilterAdapter, { DataFilterAdapter } from '../../../../hooks/useDataFilterAdapter';
import { EPanelActionPosition } from '../../../../types';
import { CorpOfferLifeCycle } from '../../../general/offer/lifecycle/types';
import {
  EOfferActionType,
  OfferActionProcess,
  OfferActions,
  OfferActionTableCommonType,
  OfferActionTableType,
} from '../../../general/offer/types';
import { getOfferTableActions } from '../../../general/offer/utils/actions';
import { ECorpOfferTableFilterItem } from '../../filterUtils';
import { ECorpOfferTableColumn } from '../../types';
import CorpOfferTable from '../component';
import { useContextConfig } from '../hooks/useContextConfig';
import { useContextHandlers } from '../hooks/useContextHandlers';
import {
  corpOffersActionsSelector,
  corpOffersCorpOffersSelector,
  corpOffersFilterSelector,
  corpOffersGuidSelector,
  corpOffersSelectedSelector,
  corpOffersSortColumnSelector,
  corpOffersSortDirectionSelector,
} from '../store/selectors';
import { CorpOfferActionCall } from '../utils';

interface CorpOffersTableAdapterProps {
  readonly lifecycle: CorpOfferLifeCycle;
  readonly filterAdapter: DataFilterAdapter<ECorpOfferTableColumn, ECorpOfferTableFilterItem>;
  readonly tabActions: OfferActions<OfferActionTableType>;
}

const CorpOffersTableAdapter = (props: CorpOffersTableAdapterProps) => {
  const { lifecycle, filterAdapter, tabActions } = props;

  const handlers = useContextHandlers();
  const { multiSelectAllowed, onCorpOfferClick, onCorpOfferOpenEdit } = useContextConfig();

  const guid = useSelector(corpOffersGuidSelector);
  const corpOffers = useSelector(corpOffersCorpOffersSelector);
  const actionProcesses = useSelector(corpOffersActionsSelector);
  const selectedCorpOffers = useSelector(corpOffersSelectedSelector);
  const sortColumn = useSelector(corpOffersSortColumnSelector);
  const sortDirection = useSelector(corpOffersSortDirectionSelector);
  const filter = useSelector(corpOffersFilterSelector);

  const {
    utils: {
      selectors: { isOffersAnyChangedSelector },
    },
  } = useCorpOfferActions();

  const needRefresh = useSelector(isOffersAnyChangedSelector);

  const onActionClick = (call: CorpOfferActionCall) => {
    const { action, corpOffer } = call;
    switch (action) {
      case EOfferActionType.Pause:
        handlers.onCorpOfferPause(corpOffer);
        break;
      case EOfferActionType.Reject:
        handlers.onCorpOfferReject(corpOffer);
        break;
      case EOfferActionType.Archive:
        handlers.onCorpOfferArchive(corpOffer);
        break;
      case EOfferActionType.Retrieve:
        handlers.onCorpOfferRetrieve(corpOffer);
        break;
      case EOfferActionType.Delete:
        handlers.onCorpOfferDelete(corpOffer);
        break;
      case EOfferActionType.Resume:
        handlers.onCorpOfferResume(corpOffer);
        break;
      case EOfferActionType.Approve:
        handlers.onCorpOfferApprove(corpOffer);
        break;
      case EOfferActionType.ChangeApprovalRegistry:
        handlers.onCorpOfferChangeApprovalRegistry(corpOffer);
        break;
      case EOfferActionType.ChangeModerator:
        handlers.onCorpOfferChangeModerator(corpOffer);
        break;
      case EOfferActionType.Duplicate:
        handlers.onCorpOfferDuplicate(corpOffer).then(duplicate => {
          if (duplicate) {
            onCorpOfferOpenEdit(duplicate);
          }
        });
        break;
      case EOfferActionType.ReturnToVerification:
        break;
    }
  };

  const getCorpOfferCommonActions = (
    corpOffer: CorpOffer,
    actions: OfferActions<OfferActionTableType>
  ): OfferActions<OfferActionTableCommonType> => {
    return getOfferTableActions(lifecycle, corpOffer, actions).filter(action =>
      action.position.includes(EPanelActionPosition.Default)
    ) as OfferActions<OfferActionTableCommonType>;
  };

  const getCorpOfferActionProcesses = (corpOffer: CorpOffer): OfferActionProcess[] => {
    return actionProcesses.filter(p => p.id === corpOffer.id) ?? [];
  };

  const { metadata, onChangeMetadata } = useDataTableFilterAdapter({
    guid,
    adapter: filterAdapter,
    sortColumn,
    filterValues: filter,
    onChangeFilter: handlers.onChangeFilter,
    onSortReset: handlers.onResetSort,
  });

  useEffect(() => {
    if (needRefresh > 0) {
      handlers.onRefresh();
    }
  }, [needRefresh, handlers.onRefresh]);

  return (
    <CorpOfferTable
      metadata={metadata}
      sort={{ column: sortColumn, direction: sortDirection }}
      corpOffers={corpOffers}
      selectedCorpOffers={selectedCorpOffers}
      getCommonActions={corpOffer => getCorpOfferCommonActions(corpOffer, tabActions)}
      onRequestSort={handlers.onChangeSort}
      onCorpOfferClick={onCorpOfferClick}
      onCorpOfferAction={onActionClick}
      getActionProcesses={getCorpOfferActionProcesses}
      onCorpOfferSelect={multiSelectAllowed ? handlers.onCorpOfferSelect : null}
      onChangeMetadata={onChangeMetadata}
      onAllCorpOffersSelect={handlers.onAllCorpOffersSelect}
    />
  );
};

export default CorpOffersTableAdapter;
