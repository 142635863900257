import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { useCorpOfferActions } from '../useActions';
import { CorpOffer } from '@/domain';
import OfferRetrieveDialog from '@features/general/offer/components/retrieveDialog';
import { CorpOfferStateKeyDialogs } from '../store/slice';

const nameDialog: CorpOfferStateKeyDialogs = 'retrieve';

const CorpOfferActionsDialogRetrieveAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onRetrieve = useCallback(
    (offer: CorpOffer, endDate: string) => {
      handlers.onRetrieve(offer, endDate, action?.notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onRetrieve, action]
  );

  return (
    action?.data && (
      <OfferRetrieveDialog<CorpOffer>
        offer={action.data}
        isExecuting={isProcessing}
        onExecute={onRetrieve}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogRetrieveAdapter;
