import { AnyOffer, EOfferType } from '@/domain';
import { Grid, Typography } from '@mui/material';

export interface OfferChangeModeratorSingleDescriptionProps<T extends AnyOffer> {
  readonly offerType: EOfferType;
  readonly offer: T;
}

export const OfferChangeModeratorSingleDescription = <T extends AnyOffer>(
  props: OfferChangeModeratorSingleDescriptionProps<T>
) => {
  const { offer, offerType } = props;

  if (!offer) {
    return null;
  }

  return (
    <>
      {offer.approvingAdmin && (
        <Grid item>
          <Typography>
            Это {offerType === EOfferType.Corp ? 'корпоративное' : 'торговое'} предложение уже в работе у{' '}
            <b>{offer.approvingAdmin.name}</b>. Выберите администратора на которого будет переназначена проверка{' '}
            {offerType === EOfferType.Corp ? 'КП' : 'ТП'} «{offer.name}».
          </Typography>
        </Grid>
      )}
      {!offer.approvingAdmin && (
        <Grid item>
          <Typography>
            Выберите администратора на которого будет назначена проверка {offerType === EOfferType.Corp ? 'КП' : 'ТП'} «
            {offer.name}».
          </Typography>
        </Grid>
      )}
    </>
  );
};
