import { CorpOffer } from '../../../../../domain/model/corpOffer';
import { AppUserSpecific } from '../../../../../domain/model/user';
import { CorpOfferLifeCycle } from '../../../general/offer/lifecycle/types';
import {
  EOfferActionType,
  EOfferTableTab,
  OfferActions,
  OfferActionTableCollectionType,
  OfferActionTableType,
} from '../../../general/offer/types';
import { filterOfferCollectionActions, renameOfferAction } from '../../../general/offer/utils/actions';

interface UseCorpOfferCollectionActionsProps {
  readonly tab: EOfferTableTab;
  readonly userSpecific: AppUserSpecific;
  readonly lifecycle: CorpOfferLifeCycle;
  readonly corpOffers: CorpOffer[];
  readonly allowedActions: OfferActions<OfferActionTableType>;
}

type ActionsType = OfferActions<OfferActionTableCollectionType>;

type UseCorpOfferCollectionActionsResult = ActionsType;

const intersectActions = (a: ActionsType, b: ActionsType) =>
  a
    // фильтруем для определения пересечений
    .filter(itemA => b.some(itemB => itemA.type === itemB.type))
    // ставим disabled если действие отключено в любом из массивов
    .map(itemA => ({
      ...itemA,
      disabled: itemA.disabled && b.find(itemB => itemA.type === itemB.type)?.disabled,
    }));

const useCorpOfferCollectionActions = (
  props: UseCorpOfferCollectionActionsProps
): UseCorpOfferCollectionActionsResult => {
  const { lifecycle, corpOffers, allowedActions } = props;

  let resultActions = corpOffers.reduce<ActionsType>((prev, offer) => {
    // получаем ограничения ЖЦ
    const restrictions = lifecycle.build({ obj: offer, allowedActions });
    const lifecycleActions = restrictions.nextActions as OfferActions<OfferActionTableType>;

    // фильтруем допустимые действия для массовых модификаций
    const collectionActions: ActionsType = filterOfferCollectionActions(lifecycleActions);

    // рассчитываем пересечения
    return intersectActions(prev.length ? prev : collectionActions, collectionActions);
  }, []);

  // меняем label у действий
  renameOfferAction(resultActions, EOfferActionType.Approve, 'Опубликовать все КП с приказом');
  renameOfferAction(resultActions, EOfferActionType.Resume, 'Опубликовать');
  renameOfferAction(resultActions, EOfferActionType.ChangeApprovalRegistry, 'Присвоить приказ');

  // пихаем все действия в одну группу
  resultActions = resultActions.map(action => ({
    ...action,
    groupLabel: 'Выбрать действие',
    primary: true,
  }));

  return resultActions;
};

export default useCorpOfferCollectionActions;
