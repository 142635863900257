import { Typography } from '@mui/material';

const SectionTitle = ({
  color = 'initial',
  gutterBottom = true,
  noWrap = false,
  children,
}: {
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
  gutterBottom?: boolean;
  noWrap?: boolean;
  children: any;
}) => {
  return (
    <Typography
      variant='subtitle2'
      color={color}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
    >
      {children}
    </Typography>
  );
};

export default SectionTitle;
