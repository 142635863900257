import { createCancelToken } from '@/data/api/index';
import {
  ApiCancellable,
  ApiQueryDsl,
  ApiRequestListDiscriminator,
  ApiRequestPageable,
  ApiResponseMaBeCounters,
} from '@/data/api/types';
import { appendQueryDslParams, getComServicesEndpoint } from '@/data/api/utils';
import { XLSXImportResult } from '@/domain';
import {
  ESocialPackageAdminAction,
  ESocialPackagesAdminAction,
  Nullable,
  RzdSocialPackage,
  RzdSocialPackageCreateRequest,
  SportOption,
} from '@/domain';
import { AxiosRequestConfig } from 'axios';
import axios, { AxiosResponse } from 'axios';

type AllCommandProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly discriminator?: D;
    readonly querydsl?: Nullable<ApiQueryDsl>;
    readonly restLimitsExist?: boolean;
  };

type AloneCommandProps = {
  readonly id: UUID;
};

export type RZDEmployeeCategoriesProps<D extends ApiRequestListDiscriminator> = ApiRequestPageable &
  ApiCancellable & {
    readonly discriminator?: D;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type ArchiveCommandProps = AloneCommandProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type ActivateCommandProps = AloneCommandProps;

type BackToAppointedCommandProps = AloneCommandProps;

type SetLimitCommandProps = AloneCommandProps & {
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type CollectionCommandProps = {
  readonly ids: UUID[];
};

type ArchiveCollectionCommandProps = CollectionCommandProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type ActivateCollectionCommandProps = CollectionCommandProps;

type BackToAppointedCollectionCommandProps = CollectionCommandProps;

type AllocateLimitCollectionCommandProps = CollectionCommandProps & {
  readonly limitToSelfRest: number;
  readonly limitToFamilyRest: number;
};

type CreateSocialPackageCommandProps = {
  readonly data: RzdSocialPackageCreateRequest;
};

type ImportLimitRulesProps = ApiCancellable & {
  readonly file: File;
  readonly config?: AxiosRequestConfig;
};

type SocialPackageApi = {
  readonly all: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllCommandProps<D>
  ) => Promise<AxiosResponse<ApiResponseMaBeCounters<D, RzdSocialPackage[]>>>;
  readonly archive: (props: ArchiveCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly activate: (props: ActivateCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly backToAppointed: (props: BackToAppointedCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;
  readonly allocateLimit: (props: SetLimitCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;

  readonly archiveCollection: (props: ArchiveCollectionCommandProps) => Promise<AxiosResponse<void>>;
  readonly activateCollection: (props: ActivateCollectionCommandProps) => Promise<AxiosResponse<void>>;
  readonly backToAppointedCollection: (props: BackToAppointedCollectionCommandProps) => Promise<AxiosResponse<void>>;
  readonly allocateLimitCollection: (props: AllocateLimitCollectionCommandProps) => Promise<AxiosResponse<void>>;

  readonly create: (props: CreateSocialPackageCommandProps) => Promise<AxiosResponse<RzdSocialPackage>>;

  readonly importLimitRules: (props: ImportLimitRulesProps) => Promise<AxiosResponse<XLSXImportResult>>;
};

const socialPackage: SocialPackageApi = {
  all: props => {
    const { discriminator, page, pageSize, restLimitsExist, querydsl, sort, signal } = props;
    const params = new URLSearchParams();

    if (sort) {
      if (typeof sort === 'string') {
        params.append('sort', sort);
      } else {
        sort.forEach(item => params.append('sort', item));
      }
    }

    params.append('page', (page - 1).toString(10));
    params.append('size', pageSize.toString(10));

    if (discriminator) {
      params.append('resultType', discriminator);
    }

    if (querydsl) {
      appendQueryDslParams(params, querydsl);
    }

    if (restLimitsExist !== null && restLimitsExist !== undefined) {
      params.append('restLimitsExist', restLimitsExist.toString());
    }

    return axios.get(`${getComServicesEndpoint()}/social-packages`, {
      params,
      cancelToken: signal && createCancelToken(axios, signal),
    });
  },
  archive: ({ id, reason, comment }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageArchiveCommand,
      reason,
      comment,
    });
  },
  activate: ({ id }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageActivationCommand,
    });
  },
  backToAppointed: ({ id }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageBackToAppointedCommand,
    });
  },
  allocateLimit: ({ id, limitToSelfRest, limitToFamilyRest }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/${id}`, {
      discriminator: ESocialPackageAdminAction.SocialPackageAllocateLimitCommand,
      limitToSelfRest,
      limitToFamilyRest,
    });
  },
  archiveCollection: ({ ids, reason, comment }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesArchiveCommand,
      reason,
      comment,
    });
  },
  activateCollection: ({ ids }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesActivationCommand,
    });
  },
  backToAppointedCollection: ({ ids }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesBackToAppointedCommand,
    });
  },
  allocateLimitCollection: ({ ids, limitToSelfRest, limitToFamilyRest }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages/actions`, {
      socialPackageIds: ids,
      discriminator: ESocialPackagesAdminAction.SocialPackagesAllocateLimitCommand,
      limitToSelfRest,
      limitToFamilyRest,
    });
  },
  create: ({ data }) => {
    return axios.post(`${getComServicesEndpoint()}/social-packages`, data);
  },
  importLimitRules: ({ file, config = {} }) => {
    const formData = new FormData();
    formData.append('file', file);
    const reqConfig: AxiosRequestConfig = {
      ...config,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return axios.post(`${getComServicesEndpoint()}/rzd-employee-categories/limit-rules/import`, formData, reqConfig);
  },
};

export default socialPackage;
