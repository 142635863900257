import SupportInfoLayout from '@features/support/layout';
import SupportInfoMpFeedbackAdapter from '@features/support/mp/adapters/feedback';
import SupportInfoMpFeedbackDialogAdapter from '@features/support/mp/adapters/feedbackDialog';
import { useCallback, useState } from 'react';
import SupportInfoMpContactsAdapter from './adapters/contacts';
import SupportInfoMpDocumentsAdapter from './adapters/documents';
import SupportInfoMpHeaderAdapter from './adapters/header';

const SupportInfoMpContainer = () => {
  const [feedback, setFeedback] = useState<boolean>(false);

  const onFeedbackDialogOpen = useCallback(() => setFeedback(true), []);
  const onFeedbackDialogClose = useCallback(() => setFeedback(false), []);

  return (
    <SupportInfoLayout
      header={<SupportInfoMpHeaderAdapter />}
      feedback={<SupportInfoMpFeedbackAdapter onShowFeedback={onFeedbackDialogOpen} />}
      contacts={<SupportInfoMpContactsAdapter />}
      documents={<SupportInfoMpDocumentsAdapter />}
    >
      <SupportInfoMpFeedbackDialogAdapter
        opened={feedback}
        onClose={onFeedbackDialogClose}
      />
    </SupportInfoLayout>
  );
};

export default SupportInfoMpContainer;
