import styled from '@emotion/styled';

export const FilledContainer = styled.div`
  flex: 1;
`;

export const FieldsGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${p => p.theme.spacing(2)};
`;

export const ContentWrapper = styled.div`
  width: 24rem;
`;
