import { MpCustomer } from '@/domain';
import { ECustomerTableTab } from '@features/customer/table/utils';
import { useCallback } from 'react';

export type UseCustomerTableConfig = {
  readonly defaultTab: ECustomerTableTab;
  readonly tabsForCounts: ECustomerTableTab[];
  readonly onCustomerClick: (customer: MpCustomer) => void;
};

export type UseCustomerTableConfigProps = {};

export function useCustomerTableConfig(props: UseCustomerTableConfigProps): UseCustomerTableConfig {
  const onCustomerClick = useCallback<UseCustomerTableConfig['onCustomerClick']>(
    customer => console.log('onCustomerClick', customer),
    []
  );

  const defaultTab = ECustomerTableTab.Active;

  const tabsForCounts = [ECustomerTableTab.Active, ECustomerTableTab.NotFilled, ECustomerTableTab.Deactivated];

  return {
    defaultTab,
    tabsForCounts,
    onCustomerClick,
  };
}
