import { EPartnerStatus, PartnerShort } from '@/domain';
import { UserAccessMatrix } from 'domain/model/accessMatrix';
import { EBannerPartition, EOrderPartition } from 'domain/model/enums';
import rootRouting from '../../../../routing';
import { commonMenu } from '../../../menu';
import { MenuPanelItem } from '../../../types';
import { getBannersTableRoute } from '../../banner/entry';
import { getConfigurationPrivilegeRoute, getConfigurationSportRoute } from '../../configuration/entry';
import { getOrdersTableRoute } from '../../order/entry';
import { getPartnerDetailsRoute } from '../../partner/entry';
import { getAdminMpTableRoute } from '../../user/adminMp/entry';

export const getPartnerMenu = (partner: PartnerShort, accessMatrix: UserAccessMatrix): MenuPanelItem[] => {
  const items: MenuPanelItem[] = [];
  const {
    productOffers,
    tradeOffers,
    corpOffers,
    bookingOffers,
    corpActivations,
    tradeActivations,
    orders,
    partners,
    partnerEmployees,
    partnerWindow,
    reports,
    banners,
    analyticsQueries,
    adCampaigns,
  } = accessMatrix;

  const partnerId = partner.id;
  const partnerStatus = partner.status;

  const offersSubMenu: MenuPanelItem[] = [];
  if (corpOffers?.view) {
    offersSubMenu.push(commonMenu.corpOffers(rootRouting.corpOffers));
  }
  if (tradeOffers?.view) {
    offersSubMenu.push(commonMenu.tradeOffers(rootRouting.tradeOffers));
  }
  if (productOffers?.view) {
    offersSubMenu.push(commonMenu.productOffers(rootRouting.products));
  }
  if (bookingOffers?.view) {
    offersSubMenu.push(commonMenu.bookingOffers(rootRouting.bookingOffers));
  }
  if (offersSubMenu.length > 0) {
    items.push({
      ...commonMenu.offers,
      subMenu: offersSubMenu,
    });
  }

  const activationsSubMenu: MenuPanelItem[] = [];
  if (corpActivations.view) {
    activationsSubMenu.push(commonMenu.corpActivations(rootRouting.corpActivations));
  }
  if (tradeActivations.view) {
    activationsSubMenu.push(commonMenu.tradeActivations(rootRouting.tradeActivations));
  }
  if (activationsSubMenu.length > 0) {
    items.push({
      ...commonMenu.activations,
      subMenu: activationsSubMenu,
    });
  }

  const ordersSubMenu: MenuPanelItem[] = [];
  if (orders?.[EOrderPartition.ProductOffers].view) {
    ordersSubMenu.push(
      commonMenu.productOfferOrders(
        getOrdersTableRoute({
          partition: EOrderPartition.ProductOffers,
        })
      )
    );
  }
  if (orders?.[EOrderPartition.BookingOffers].view) {
    ordersSubMenu.push(
      commonMenu.bookingOfferOrders(
        getOrdersTableRoute({
          partition: EOrderPartition.BookingOffers,
        })
      )
    );
  }
  if (ordersSubMenu.length > 0) {
    items.push({
      ...commonMenu.orders,
      subMenu: ordersSubMenu,
    });
  }

  const adsSubMenu: MenuPanelItem[] = [];
  if (partnerWindow?.view) {
    adsSubMenu.push(commonMenu.partnerWindow(rootRouting.partnerWindow));
  }
  if (banners?.[EBannerPartition.CorpOffers].view) {
    adsSubMenu.push(
      commonMenu.corpOfferBanners(
        getBannersTableRoute({
          partition: EBannerPartition.CorpOffers,
        })
      )
    );
  }
  if (banners?.[EBannerPartition.TradeOffers].view) {
    adsSubMenu.push(
      commonMenu.tradeOfferBanners(
        getBannersTableRoute({
          partition: EBannerPartition.TradeOffers,
        })
      )
    );
  }

  if (adCampaigns.view) {
    adsSubMenu.push(commonMenu.adCampaigns(rootRouting.ads));
  }
  if (adsSubMenu.length > 0) {
    items.push({
      ...commonMenu.ads,
      subMenu: adsSubMenu,
    });
  }

  const analyticsSubMenu: MenuPanelItem[] = [];
  if (reports?.view) {
    analyticsSubMenu.push(commonMenu.reports(rootRouting.reports));
  }
  if (analyticsQueries?.view) {
    analyticsSubMenu.push(commonMenu.queryAnalytics(rootRouting.analytics));
  }
  if (analyticsSubMenu.length > 0) {
    items.push({
      ...commonMenu.analytics,
      subMenu: analyticsSubMenu,
    });
  }

  const companySubMenu: MenuPanelItem[] = [];
  if (partners?.view) {
    companySubMenu.push(commonMenu.partnerCompany(getPartnerDetailsRoute({ id: partnerId })));
  }
  if (partnerEmployees?.view) {
    companySubMenu.push(commonMenu.partnerEmployees(rootRouting.employees));
  }
  if (companySubMenu.length > 0) {
    items.push({
      ...commonMenu.company(partnerStatus === EPartnerStatus.Rejected),
      subMenu: companySubMenu,
    });
  }

  return items;
};

export const getCommonMpMenu = (accessMatrix: UserAccessMatrix): MenuPanelItem[] => {
  const items: MenuPanelItem[] = [];
  const {
    productOffers,
    tradeOffers,
    corpOffers,
    bookingOffers,
    tradeActivations,
    corpActivations,
    orders,
    partners,
    customers,
    adminsMp,
    reports,
    banners,
    configuration,
    landingWindow,
    analyticsQueries,
    adCampaigns,
    clientOrgs,
    socialPackages,
    socialPackageRequests,
  } = accessMatrix;

  const offersSubMenu: MenuPanelItem[] = [];
  if (corpOffers?.view) {
    offersSubMenu.push(commonMenu.corpOffers(rootRouting.corpOffers));
  }
  if (tradeOffers?.view) {
    offersSubMenu.push(commonMenu.tradeOffers(rootRouting.tradeOffers));
  }
  if (productOffers?.view) {
    offersSubMenu.push(commonMenu.productOffers(rootRouting.products));
  }
  if (bookingOffers?.view) {
    offersSubMenu.push(commonMenu.bookingOffers(rootRouting.bookingOffers));
  }
  if (offersSubMenu.length > 0) {
    items.push({
      ...commonMenu.offers,
      subMenu: offersSubMenu,
    });
  }

  const activationsSubMenu: MenuPanelItem[] = [];
  if (corpActivations.view) {
    activationsSubMenu.push(commonMenu.corpActivations(rootRouting.corpActivations));
  }
  if (tradeActivations.view) {
    activationsSubMenu.push(commonMenu.tradeActivations(rootRouting.tradeActivations));
  }
  if (activationsSubMenu.length > 0) {
    items.push({
      ...commonMenu.activations,
      subMenu: activationsSubMenu,
    });
  }

  const ordersSubMenu: MenuPanelItem[] = [];
  if (orders?.[EOrderPartition.BookingOffers].view) {
    ordersSubMenu.push(
      commonMenu.bookingOfferOrders(
        getOrdersTableRoute({
          partition: EOrderPartition.BookingOffers,
        })
      )
    );
  }
  if (ordersSubMenu.length > 0) {
    items.push({
      ...commonMenu.orders,
      subMenu: ordersSubMenu,
    });
  }

  const adsSubMenu: MenuPanelItem[] = [];
  if (landingWindow?.view) {
    adsSubMenu.push(commonMenu.landingWindow(rootRouting.landingWindow));
  }
  if (banners?.[EBannerPartition.CorpOffers].view) {
    adsSubMenu.push(
      commonMenu.corpOfferBanners(
        getBannersTableRoute({
          partition: EBannerPartition.CorpOffers,
        })
      )
    );
  }
  if (banners?.[EBannerPartition.TradeOffers].view) {
    adsSubMenu.push(
      commonMenu.tradeOfferBanners(
        getBannersTableRoute({
          partition: EBannerPartition.TradeOffers,
        })
      )
    );
  }
  if (adCampaigns.view) {
    adsSubMenu.push(commonMenu.adCampaigns(rootRouting.ads));
  }
  if (adsSubMenu.length > 0) {
    items.push({
      ...commonMenu.ads,
      subMenu: adsSubMenu,
    });
  }

  if (clientOrgs?.view) {
    items.push(commonMenu.clientOrgs(rootRouting.clientOrgs));
  }

  const cspSubMenu: MenuPanelItem[] = [];
  if (socialPackageRequests?.view) {
    cspSubMenu.push(commonMenu.socialPackageRequests(rootRouting.socialPackageRequest));
  }
  if (socialPackages?.view) {
    cspSubMenu.push(commonMenu.socialPackages(rootRouting.socialPackage));
  }
  if (cspSubMenu.length > 0) {
    items.push({
      ...commonMenu.csp,
      subMenu: cspSubMenu,
    });
  }

  const analyticsSubMenu: MenuPanelItem[] = [];
  if (reports?.view) {
    analyticsSubMenu.push(commonMenu.reports(rootRouting.reports));
  }
  if (analyticsQueries?.view) {
    analyticsSubMenu.push(commonMenu.queryAnalytics(rootRouting.analytics));
  }
  if (analyticsSubMenu.length > 0) {
    items.push({
      ...commonMenu.analytics,
      subMenu: analyticsSubMenu,
    });
  }

  const usersSubMenu: MenuPanelItem[] = [];
  if (partners?.view) {
    usersSubMenu.push(commonMenu.partners(rootRouting.partners));
  }
  if (customers?.view?.list) {
    usersSubMenu.push(commonMenu.customers(rootRouting.customers));
  }
  if (adminsMp?.view) {
    usersSubMenu.push(commonMenu.mpAdmins(getAdminMpTableRoute({})));
  }
  if (usersSubMenu.length > 0) {
    items.push({
      ...commonMenu.users,
      subMenu: usersSubMenu,
    });
  }

  if (configuration?.view) {
    items.push(commonMenu.configuration(getConfigurationPrivilegeRoute({})));
  }

  return items;
};

export const getSportMenu = (accessMatrix: UserAccessMatrix): MenuPanelItem[] => {
  const items: MenuPanelItem[] = [];
  const { events, teams, playgrounds, complaints, chats, reports, configuration } = accessMatrix;

  if (events?.view) {
    items.push(commonMenu.events(rootRouting.events));
    items.push(commonMenu.sportsmen(rootRouting.sportsmen));
  }

  if (teams?.view) {
    items.push(commonMenu.teams(rootRouting.teams));
  }

  if (playgrounds?.view) {
    items.push(commonMenu.playgrounds(rootRouting.playgrounds));
  }

  if (chats?.view) {
    items.push(commonMenu.chats(rootRouting.chats));
  }

  if (complaints?.view) {
    items.push(commonMenu.complaints(rootRouting.complaints));
  }

  if (reports?.view) {
    items.push(commonMenu.sportReports(rootRouting.reports));
  }

  if (configuration?.view) {
    items.push(commonMenu.configuration(getConfigurationSportRoute({})));
  }

  return items;
};
