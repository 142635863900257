import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isCorpOffersAnyChangedSelector } from '../store/selectors';
import { corpOfferActionsOptimize } from '../store/slice';

const CorpOfferActionsOptimizerAdapter = () => {
  const dispatch = useDispatch();
  const needOptimize = useSelector(isCorpOffersAnyChangedSelector);

  useEffect(() => {
    if (needOptimize > 0) {
      dispatch(corpOfferActionsOptimize());
    }
  }, [needOptimize]);

  return null;
};

export default CorpOfferActionsOptimizerAdapter;
