import { PartnerEditAdminSidePanelsAdapter } from '@features/partner/edit/adapters/sidePanels';
import { SideColumn } from '@layouts/masterContentGrid/controls';
import { SportOptionTyped } from '../../../../domain/model';
import { Address } from '../../../../domain/model/address';
import {
  EPartnerOwnershipType,
  EPartnerPermission,
  EPartnerType,
  ETaxationSystemType,
} from '../../../../domain/model/enums';
import {
  PartnerCompanyDataDraft,
  PartnerDataDraft,
  PartnerIndividualDataDraft,
  PartnerShort,
} from '../../../../domain/model/partner';
import { Nullable } from '../../../../domain/model/types';
import { OnChangeObjectAttribute } from '../../../types';
import { ValidationResult } from '../../../utils/validation';
import { EPartnerStep } from '../../general/partner/types';
import {
  PartnerDataStepPermissions,
  PartnerDetailsCompany,
  PartnerDetailsIndividual,
  PartnerDocumentsCompany,
  PartnerDocumentsIndividual,
  PartnerGeneralCompany,
  PartnerGeneralIndividual,
} from '../../general/partner/validation';
import { ColumnWrapper, StepWrapper, Wrapper } from './controls';
import PartnerEditStepAddress from './steps/address';
import PartnerEditStepDetails from './steps/details';
import PartnerEditStepDocuments from './steps/documents';
import PartnerEditStepGeneral from './steps/general';
import PartnerEditStepPermissions from './steps/permissions/edit';

interface PartnerEditAdminProps {
  readonly step: EPartnerStep;
  readonly partner: PartnerShort;
  readonly permissions: Nullable<EPartnerPermission[]>;
  readonly partnerType: Nullable<EPartnerType>;
  readonly address: Address;
  readonly ownerShipTypes: SportOptionTyped<EPartnerOwnershipType>[];
  readonly taxationSystemTypes: SportOptionTyped<ETaxationSystemType>[];
  readonly partnerTypes: SportOptionTyped<EPartnerType>[];
  readonly isValidGeneral: boolean;
  readonly isValidDetails: boolean;
  readonly isValidAddress: boolean;
  readonly isValidDocuments: boolean;
  readonly isValidPermissions: boolean;
  readonly validationGeneralCompany: Nullable<ValidationResult<PartnerGeneralCompany>>;
  readonly validationGeneralIndividual: Nullable<ValidationResult<PartnerGeneralIndividual>>;
  readonly validationDetailsCompany: Nullable<ValidationResult<PartnerDetailsCompany>>;
  readonly validationDetailsIndividual: Nullable<ValidationResult<PartnerDetailsIndividual>>;
  readonly validationAddress: Nullable<ValidationResult<Address>>;
  readonly validationDocumentsCompany: Nullable<ValidationResult<PartnerDocumentsCompany>>;
  readonly validationDocumentsIndividual: Nullable<ValidationResult<PartnerDocumentsIndividual>>;
  readonly validationPermissions: Nullable<ValidationResult<PartnerDataStepPermissions>>;
  readonly onChangeAttribute: OnChangeObjectAttribute<PartnerDataDraft>;
  readonly onChangeAddress: (address: Address) => void;
  readonly onChangeCompanyAttribute: OnChangeObjectAttribute<PartnerCompanyDataDraft>;
  readonly onChangeIndividualCompanyAttribute: OnChangeObjectAttribute<PartnerIndividualDataDraft>;
  readonly onChangePermissions: (value: EPartnerPermission[]) => void;
  readonly onChangePartnerType: (value: EPartnerType) => void;
}

const PartnerEditAdmin = (props: PartnerEditAdminProps) => {
  const {
    step,
    partner,
    permissions,
    partnerType,
    address,
    taxationSystemTypes,
    partnerTypes,
    isValidGeneral,
    isValidDetails,
    isValidAddress,
    isValidDocuments,
    isValidPermissions,
    validationGeneralCompany,
    validationGeneralIndividual,
    validationDetailsCompany,
    validationDetailsIndividual,
    validationAddress,
    validationDocumentsCompany,
    validationDocumentsIndividual,
    validationPermissions,
    ownerShipTypes,
    onChangeAttribute,
    onChangeCompanyAttribute,
    onChangeIndividualCompanyAttribute,
    onChangeAddress,
    onChangePermissions,
    onChangePartnerType,
  } = props;

  return (
    <Wrapper>
      <StepWrapper>
        <ColumnWrapper>
          {step === EPartnerStep.General && (
            <PartnerEditStepGeneral
              partner={partner}
              ownerShipTypes={ownerShipTypes}
              validationCompany={validationGeneralCompany}
              validationIndividual={validationGeneralIndividual}
              isValid={isValidGeneral}
              onChangeAttribute={onChangeAttribute}
              onChangeCompanyAttribute={onChangeCompanyAttribute}
            />
          )}
          {step === EPartnerStep.Address && (
            <PartnerEditStepAddress
              address={address}
              orgType={partner.regInfo?.orgType ?? null}
              isValid={isValidAddress}
              validation={validationAddress}
              onChangeAddress={onChangeAddress}
            />
          )}
          {step === EPartnerStep.Details && (
            <PartnerEditStepDetails
              partner={partner}
              taxationSystemTypes={taxationSystemTypes}
              isValid={isValidDetails}
              validationCompany={validationDetailsCompany}
              validationIndividual={validationDetailsIndividual}
              onChangeAttribute={onChangeAttribute}
              onChangeCompanyAttribute={onChangeCompanyAttribute}
              onChangeIndividualAttribute={onChangeIndividualCompanyAttribute}
            />
          )}
          {step === EPartnerStep.Documents && (
            <PartnerEditStepDocuments
              partner={partner}
              isValid={isValidDocuments}
              validationCompany={validationDocumentsCompany}
              validationIndividual={validationDocumentsIndividual}
              onChangeAttribute={onChangeAttribute}
              onChangeCompanyAttribute={onChangeCompanyAttribute}
              onChangeIndividualAttribute={onChangeIndividualCompanyAttribute}
            />
          )}
          {step === EPartnerStep.Permissions && (
            <PartnerEditStepPermissions
              partner={partner}
              isValid={isValidPermissions}
              partnerTypes={partnerTypes}
              permissions={permissions}
              partnerType={partnerType}
              validation={validationPermissions}
              onChangePermissions={onChangePermissions}
              onChangePartnerType={onChangePartnerType}
              onChangeAgreements={agreements => onChangeAttribute('agreements', agreements)}
            />
          )}
        </ColumnWrapper>
        <SideColumn sideContentPosition={'fixed'}>
          <PartnerEditAdminSidePanelsAdapter />
        </SideColumn>
      </StepWrapper>
    </Wrapper>
  );
};

export default PartnerEditAdmin;
