import { RootState } from '@/data/store/store';
import { Nullable, UUID } from '@/domain';
import { createSelector } from '@reduxjs/toolkit';
import { EOfferActionType } from '@features/general/offer/types';
import { CorpOffersActionsState } from '@features/corpOffer/actions/store/slice';
import { offerCollectionActions } from '@features/general/offer/utils/actions';

export const corpOfferActionsDialogsSelector = (store: RootState) => store.corpOffer.actions.dialogs;

export const getCorpOfferActionsOfferIsProcessingSelector = (id: Nullable<UUID>) =>
  createSelector(
    (store: RootState) => store.corpOffer.actions.actions,
    (store: RootState) => store.corpOffer.actions.create.isFetching,
    (actions, isCreating) => {
      return id ? actions.some(action => action.id === id && action.isFetching) : isCreating;
    }
  );

export const isCorpOffersAnyChangedSelector = (store: RootState) =>
  store.corpOffer.actions.actions.filter(action => !action.isFetching).length;

export const corpOfferActionsGetActionSelector = <A extends keyof CorpOffersActionsState['dialogs']>(action: A) =>
  createSelector(
    () => action,
    corpOfferActionsDialogsSelector,
    (action, dialogs) => dialogs[action]
  );

export const getIsCorpOfferAnyActionByTypeExecutedSelector = (id: Nullable<UUID>, ...actions: EOfferActionType[]) =>
  createSelector(
    () => id,
    () => actions,
    (store: RootState) => store.corpOffer.actions.actions,
    (id, argActions, storeActions) => {
      return id
        ? storeActions.find(action => action.id === id && action.isFetched && argActions.some(a => a === action.type))
            ?.type ?? null
        : null;
    }
  );

export const getIsCorpOfferAnyActionByTypeExecutingSelector = (...actions: EOfferActionType[]) =>
  createSelector(
    () => actions,
    (store: RootState) => store.corpOffer.actions.actions,
    (argActions, storeActions) => {
      return storeActions.some(action => action.isFetching && argActions.some(a => a === action.type));
    }
  );

export const isCorpOfferAnyCollectionActionProcessingSelector = createSelector(
  (store: RootState) => store.corpOffer.actions.actions,
  storeActions => {
    return storeActions.some(action => action.isFetching && offerCollectionActions.some(a => a === action.type));
  }
);

export const corpOfferCollectionActionProcessingSelector = createSelector(
  (store: RootState) => store.corpOffer.actions.actions,
  storeActions => {
    return storeActions.filter(action => action.isFetching && offerCollectionActions.some(a => a === action.type));
  }
);
