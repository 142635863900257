import { TradeOffer } from '@/domain';
import { isOfferTypeWithCodes } from '@features/general/offer/utils/common';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { TradeOfferActionsConfirmDialog } from '../components';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { TradeOfferStateKeyDialogs } from '../store/slice';
import { useTradeOfferActions } from '../useActions';

const nameDialog: TradeOfferStateKeyDialogs = 'resume';

const TradeOfferActionsDialogResumeAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(data?.id ?? null));

  const isCodes =
    data?.promotionType &&
    isOfferTypeWithCodes(data.promotionType) &&
    data.offerCount === data.offerCount - data.notUsedOfferCount;

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onChangeDialogState]);

  const onOpenCreateCodes = useCallback(
    (offer: TradeOffer) => {
      onCloseDialog();
      handlers.onTryCreateCodes({ data: offer, notification });
    },
    [handlers.onResume, notification]
  );

  const onResume = useCallback(
    (offer: TradeOffer) => {
      handlers.onResume(offer, notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onResume, notification]
  );

  if (!data) {
    return null;
  }

  return isCodes ? (
    <TradeOfferActionsConfirmDialog
      offer={data}
      title='Публикация торгового предложения невозможно'
      text={'Опубликовать торговое предложение невозможно, так как закончились коды. Пополните базу кодов'}
      actionText='Пополнить базу'
      isFetching={isProcessing}
      onAction={onOpenCreateCodes}
      onClose={onCloseDialog}
    />
  ) : (
    <TradeOfferActionsConfirmDialog
      offer={data}
      title={`Опубликовать торговое предложение?`}
      actionText='Опубликовать'
      isFetching={isProcessing}
      onAction={onResume}
      onClose={onCloseDialog}
    />
  );
};

export default TradeOfferActionsDialogResumeAdapter;
