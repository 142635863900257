import { FCC, ReactNode } from 'react';
import { ContentColumnComponent } from './contentColumn';
import { ColumnContent, SideColumn, Wrapper } from './controls';
import { MasterContentGridColumnComponent } from './types';

interface MasterContentGridLayoutProps {
  readonly sideContent?: ReactNode;
  readonly sideContentPosition?: 'sticky' | 'fixed';
  readonly column?: MasterContentGridColumnComponent;
}

const MasterContentGridLayout: FCC<MasterContentGridLayoutProps> = props => {
  const { sideContent, sideContentPosition = 'fixed', column: Column = ContentColumnComponent, children } = props;

  return (
    <Wrapper>
      <Column>
        <ColumnContent>{children}</ColumnContent>
      </Column>
      {sideContent && <SideColumn sideContentPosition={sideContentPosition}>{sideContent}</SideColumn>}
    </Wrapper>
  );
};

export default MasterContentGridLayout;
