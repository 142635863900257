import { useSelector } from 'react-redux';
import MenuPanelDrawerLayout from '../../../layouts/menuPanel';
import { viewIsMainMenuVisible } from '../container/store/selectors';
import { MainMenuFooterAdapter } from './adapter/footer';
import { MainMenuHeaderAdapter } from './adapter/header';
import { MainMenuItemsAdapter } from './adapter/items';
import useMainMenu from './useMenu';

const MainMenuContainer = () => {
  const visible = useSelector(viewIsMainMenuVisible);

  const { items, menuType, guid, isSelectedItem, onItemClick, isOpenedSubMenu } = useMainMenu();

  return (
    <MenuPanelDrawerLayout
      visible={visible}
      guid={guid}
      header={<MainMenuHeaderAdapter menuType={menuType} />}
      footer={<MainMenuFooterAdapter onItemClick={onItemClick} />}
    >
      <MainMenuItemsAdapter
        items={items}
        onItemClick={onItemClick}
        isSelectedItem={isSelectedItem}
        isOpenedSubMenu={isOpenedSubMenu}
      />
    </MenuPanelDrawerLayout>
  );
};

export default MainMenuContainer;
