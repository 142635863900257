import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { AdCampaign } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import adCampaignServices from '../../services';
import { AdCampaignDetailsUiState } from '../types';

export const adCampaignDetailsByIdFetch = createAsyncThunk<AdCampaign, UUID, AppThunkAPIConfig>(
  'adCampaign/details/byId/fetch',
  async (id, { rejectWithValue, signal }) => {
    try {
      return await adCampaignServices.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export interface AdCampaignDetailsState {
  readonly byId: Fetchable & {
    readonly adCampaign: Nullable<AdCampaign>;
  };
  readonly needRefreshWatcher: number;
  readonly ui: Nullable<AdCampaignDetailsUiState>;
}

type Reducer<T = undefined> = CaseReducer<AdCampaignDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<AdCampaignDetailsState> {
  adCampaignDetailsStateReset: Reducer;
  adCampaignDetailsNeedRefreshWatcherIncrement: Reducer;
  adCampaignDetailsSetUiState: Reducer<{
    name: keyof AdCampaignDetailsUiState;
    value: any;
  }>;
}

const slice = createSlice<AdCampaignDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      adCampaign: null,
    },
    needRefreshWatcher: 0,
    ui: null,
  },
  reducers: {
    adCampaignDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        adCampaign: null,
      };

      state.ui = null;
    },
    adCampaignDetailsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    adCampaignDetailsSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
        };
      }
      state.ui[name] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(adCampaignDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.adCampaign = null;
      })
      .addCase(adCampaignDetailsByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.adCampaign = payload;
      })
      .addCase(adCampaignDetailsByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.adCampaign = null;
      });
  },
});

export const {
  adCampaignDetailsStateReset,
  adCampaignDetailsSetUiState,
  adCampaignDetailsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
