import { DefaultContentWrapper } from '@components/common/wrappers/content';
import { ContainerWrapper, ContentWrapper, Wrapper } from '@features/support/layout/controls';
import { Fade, Grid } from '@mui/material';
import { FCC, ReactNode } from 'react';

interface SupportInfoLayoutProps {
  readonly header: ReactNode;
  readonly contacts: ReactNode;
  readonly feedback?: ReactNode;
  readonly documents: ReactNode;
}

const SupportInfoLayout: FCC<SupportInfoLayoutProps> = props => {
  const { header, feedback, contacts, documents, children } = props;
  return (
    <Fade in>
      <DefaultContentWrapper
        type='details'
        stickyHeader
        fullHeight
        header={header}
      >
        <ContainerWrapper>
          <Wrapper>
            <ContentWrapper>
              <Grid
                container
                spacing={4}
                direction='column'
              >
                <Grid item>{contacts}</Grid>
                {feedback && <Grid item>{feedback}</Grid>}
                <Grid item>{documents}</Grid>
              </Grid>
              {children}
            </ContentWrapper>
          </Wrapper>
          {children}
        </ContainerWrapper>
      </DefaultContentWrapper>
    </Fade>
  );
};

export default SupportInfoLayout;
