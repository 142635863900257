import ClearIcon from '@mui/icons-material/Clear';
import {
  Grid,
  IconButton,
  LinearProgress,
  LinearProgressProps,
  SvgIconProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import { LinkProps } from '@mui/material/Link/Link';
import { SyntheticEvent } from 'react';
import { ContentTypeImageIcon } from '../../../../icons';
import { convertBytesToUnitText } from '../../../../utils/files';
import { Content, EndAdornmentWrapper, Wrapper } from './controls';
import { FileInfoStorageAdapter } from './storageAdapter';
import { FileInfoAdapterType } from './types';

export interface FileInfoProps {
  readonly id?: string;
  readonly adapter?: FileInfoAdapterType;
  readonly originalName?: string;
  readonly loadingProgress?: number;
  readonly onRemove?: () => void;
  readonly icon?: JSX.Element;
  readonly endIcon?: JSX.Element;
  readonly color?: 'primary' | 'secondary' | 'success' | 'warning';
  /** default 'true' */
  readonly fileSize?: boolean;
  readonly fileExtension?: boolean;
  readonly linkProps?: Omit<LinkProps, 'href'>;
  readonly size?: number;
  readonly disabled?: boolean;
}

const FileInfo = (props: FileInfoProps) => {
  const {
    id,
    originalName,
    adapter: Adapter = FileInfoStorageAdapter,
    loadingProgress,
    onRemove,
    icon = <ContentTypeImageIcon />,
    endIcon,
    color = 'secondary',
    fileSize = true,
    linkProps,
    size,
    fileExtension,
    disabled = false,
  } = props;

  const nameColor: TypographyProps['color'] = 'textPrimary';
  const progressColor: LinearProgressProps['color'] = color;
  const sizeColor: TypographyProps['color'] = 'textSecondary';
  const removeIconColor: SvgIconProps['color'] = color === 'secondary' ? 'inherit' : color;

  const onRemoveInner = (event: SyntheticEvent) => {
    event.preventDefault();
    onRemove?.();
  };

  return (
    <Adapter
      id={id}
      loadingProgress={loadingProgress}
      originalName={originalName}
      linkProps={linkProps}
      size={size}
      fileExtension={fileExtension}
    >
      {({ name, size, isLoading, error }) => (
        <Wrapper
          color={color}
          disabled={disabled}
        >
          {icon}
          {isLoading && (
            <Content
              container
              direction='column'
            >
              <Grid item>
                <Typography
                  variant='body1'
                  noWrap
                  color={disabled ? 'textSecondary' : nameColor}
                >
                  {name ?? 'загрузка данных файла...'}
                </Typography>
              </Grid>
              <Grid item>
                <LinearProgress
                  variant={!loadingProgress || loadingProgress === 100 ? 'indeterminate' : 'determinate'}
                  color={progressColor}
                  value={loadingProgress}
                />
              </Grid>
            </Content>
          )}
          {!isLoading && (
            <Content
              container
              direction='column'
            >
              <Grid item>
                {name && (
                  <Typography
                    variant='body1'
                    noWrap
                    color={disabled ? 'textSecondary' : nameColor}
                  >
                    {name}
                  </Typography>
                )}
                {error && (
                  <Typography
                    variant='body1'
                    noWrap
                    color={nameColor}
                  >
                    {error}
                  </Typography>
                )}
              </Grid>
              {size && fileSize && (
                <Grid item>
                  <Typography
                    variant='body2'
                    color={sizeColor}
                  >
                    {convertBytesToUnitText(size)}
                  </Typography>
                </Grid>
              )}
            </Content>
          )}
          {!error && (
            <EndAdornmentWrapper>
              {onRemove && !disabled && (
                <IconButton onClick={onRemoveInner}>
                  <ClearIcon
                    color={removeIconColor}
                    fontSize='small'
                  />
                </IconButton>
              )}
              {endIcon}
            </EndAdornmentWrapper>
          )}
        </Wrapper>
      )}
    </Adapter>
  );
};

export default FileInfo;
