import { combineReducers } from '@reduxjs/toolkit';
import create, { CorpOfferCreateState } from './create/store/slice';
import details, { CorpOfferDetailsState } from './details/store/slice';
import search, { CorpOfferSearchState } from './search/store/slice';
import list, { CorpOfferListState } from './table/store/slice';
import upload, { CorpOffersUploadState } from './upload/store/slice';
import actions, { CorpOffersActionsState } from '@features/corpOffer/actions/store/slice';

interface CorpOfferState {
  list: CorpOfferListState;
  create: CorpOfferCreateState;
  details: CorpOfferDetailsState;
  upload: CorpOffersUploadState;
  search: CorpOfferSearchState;
  actions: CorpOffersActionsState;
}

export default combineReducers<CorpOfferState>({
  list,
  create,
  details,
  upload,
  search,
  actions,
});
