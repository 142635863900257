import { UserAccessPartitionAdCampaigns, UUID } from '@/domain';
import { useAdCampaignActionsSelectors } from '@features/adCampaign/actions/useActions';
import AdCampaignDetailsFooterAdapter from '@features/adCampaign/details/adapters/footer';
import { AdCampaignDetailsHeaderAdapter } from '@features/adCampaign/details/adapters/header';
import AdCampaignDetailsStepGeneralAdapter from '@features/adCampaign/details/adapters/stepGeneral';
import AdCampaignDetailsStepKeywordsAdapter from '@features/adCampaign/details/adapters/stepKeywords';
import AdCampaignDetailsStepperAdapter from '@features/adCampaign/details/adapters/stepper';
import { AdCampaignDetailsUiStateAdapter } from '@features/adCampaign/details/adapters/uiState';
import { AdCampaignDetailsInitializer } from '@features/adCampaign/details/initializer';
import {
  AdCampaignDetailHandlersProvider,
  AdCampaignDetailLifecycleProvider,
  AdCampaignDetailParamsProvider,
} from '@features/adCampaign/details/providers';
import {
  adCampaignDetailsFetchIsFailedSelector,
  adCampaignDetailsIsPreloadingSelector,
} from '@features/adCampaign/details/store/selectors';
import { AdCampaignLifeCycle } from '@features/adCampaign/lifecycle/types';
import { EAdCampaignStep } from '@features/adCampaign/types';
import { CommonFetchErrorDecorator } from '@features/general/decorators/error';
import { CommonPreloaderDecorator } from '@features/general/decorators/preloader';
import { CommonUpdaterDecorator } from '@features/general/decorators/updater';
import MasterDetailsContainer from '@features/general/master/details/container';
import MasterContentGridLayout from '@layouts/masterContentGrid/container';
import { MasterDetailsLayout } from '@layouts/masterDetails/container';

export interface AdCampaignDetailsContainerProps {
  readonly id: UUID;
  readonly step: EAdCampaignStep;
  readonly lifecycle: AdCampaignLifeCycle;
  readonly access: UserAccessPartitionAdCampaigns;
}

const AdCampaignDetailsContainer = (props: AdCampaignDetailsContainerProps) => {
  const { id, step, access, lifecycle } = props;

  const { getIsAdCampaignProcessingSelector } = useAdCampaignActionsSelectors();

  return (
    <MasterDetailsContainer
      layout={MasterDetailsLayout}
      layoutProps={{
        stepper: AdCampaignDetailsStepperAdapter,
        header: AdCampaignDetailsHeaderAdapter,
        footer: AdCampaignDetailsFooterAdapter,
        decorators: [
          <CommonPreloaderDecorator
            key='preloader'
            isFetchingSelector={adCampaignDetailsIsPreloadingSelector}
          />,
          <CommonUpdaterDecorator
            key='actions'
            isFetchingSelector={getIsAdCampaignProcessingSelector(id)}
          />,
          <CommonFetchErrorDecorator
            key='fail'
            isFetchFailedSelector={adCampaignDetailsFetchIsFailedSelector}
          />,
        ],
      }}
      initializer={AdCampaignDetailsInitializer}
      providers={[
        {
          name: 'params',
          factory: AdCampaignDetailParamsProvider,
        },
        {
          name: 'handlers',
          factory: AdCampaignDetailHandlersProvider,
        },
        {
          name: 'lifecycle',
          factory: AdCampaignDetailLifecycleProvider,
        },
      ]}
      commonProps={{
        id,
        step,
        lifecycle,
        access,
      }}
    >
      <AdCampaignDetailsUiStateAdapter />

      <MasterContentGridLayout>
        <AdCampaignDetailsStepGeneralAdapter />
        <AdCampaignDetailsStepKeywordsAdapter />
      </MasterContentGridLayout>
    </MasterDetailsContainer>
  );
};

export default AdCampaignDetailsContainer;
