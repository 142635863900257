import styled from '@emotion/styled';
import { css } from '@mui/material';

interface WrapperProps {
  readonly width: string;
}

export const Wrapper = styled.div<WrapperProps>(
  ({ theme, width }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
    width: ${width};
  `
);
