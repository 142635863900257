import { CorpOfferActionsConfirmDialog } from '../components';
import { EOfferActionType } from '@features/general/offer/types';
import { pluralize } from 'presentation/utils/pluralize';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getIsCorpOfferAnyActionByTypeExecutingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'approveCollection';

const CorpOfferActionsDialogApproveCollectionAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getIsCorpOfferAnyActionByTypeExecutingSelector(EOfferActionType.Approve));

  const text =
    action?.data?.length &&
    `Будет опубликовано ${pluralize(action.data.length, [
      'корпоративное предложение',
      'корпоративных предложения',
      'корпоративных предложений',
    ])}: ${action.data.length}`;

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onApprove = useCallback(() => {
    action?.data &&
      handlers.onApprove(action.data, action?.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onApprove, action]);

  return (
    action?.data?.length && (
      <CorpOfferActionsConfirmDialog
        title='Публикация корпоративных предложений'
        actionText='Опубликовать'
        text={text}
        onAction={onApprove}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogApproveCollectionAdapter;
