import { ApprovalOfferRegistryRequest } from '@/domain';
import { EOfferActionType } from '@features/general/offer/types';
import { OnChangeObjectAttribute } from 'presentation/types';
import { pluralize } from 'presentation/utils/pluralize';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CorpOfferActionsApprovalRegistryDialog from '../components/approvalRegistryDialog';
import { corpOfferActionsGetActionSelector, getIsCorpOfferAnyActionByTypeExecutingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'changeApprovalRegistryCollection';

const CorpOfferActionsDialogChangeApprovalRegistryCollectionAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(
    getIsCorpOfferAnyActionByTypeExecutingSelector(EOfferActionType.ChangeApprovalRegistry)
  );

  const [approvalRegistry, setApprovalRegistry] = useState<ApprovalOfferRegistryRequest>({ number: null, date: null });

  const description = action?.data?.length
    ? `Введите реквизиты приказа к ${action?.data?.length}
  ${pluralize(action?.data?.length, [
    'корпоративному предложению',
    'корпоративным предложениям',
    'корпоративным предложениям',
  ])}`
    : null;

  const onChangeApprovalRegistryAttribute: OnChangeObjectAttribute<ApprovalOfferRegistryRequest> = (name, value) => {
    setApprovalRegistry((prevState: ApprovalOfferRegistryRequest) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onApprove = useCallback(() => {
    action?.data &&
      handlers.onChangeApprovalRegistry(action.data, approvalRegistry, action?.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onChangeApprovalRegistry, onCloseDialog, approvalRegistry, action]);

  useEffect(() => {
    setApprovalRegistry({ number: null, date: null });
  }, [action]);

  return (
    action?.data && (
      <CorpOfferActionsApprovalRegistryDialog
        open={!!action.data}
        approvalRegistry={approvalRegistry}
        description={description}
        isValidation
        isExecuting={isProcessing}
        onChangeAttribute={onChangeApprovalRegistryAttribute}
        onChange={onApprove}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogChangeApprovalRegistryCollectionAdapter;
