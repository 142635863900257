import SupportInfoLayout from '@features/support/layout';
import SupportInfoSportContactsAdapter from './adapters/contacts';
import SupportInfoSportDocumentsAdapter from './adapters/documents';
import SupportInfoSportHeaderAdapter from './adapters/header';

const SupportInfoSportContainer = () => {
  return (
    <SupportInfoLayout
      header={<SupportInfoSportHeaderAdapter />}
      contacts={<SupportInfoSportContactsAdapter />}
      documents={<SupportInfoSportDocumentsAdapter />}
    />
  );
};

export default SupportInfoSportContainer;
