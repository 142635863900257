import EntityMasterInfoPanelItem from '@components/common/entityMaster/infoPanel/item';
import { SidePanelContentWrapper } from '@components/common/wrappers/sidePanel';
import usePartnerInfoPanels from '@features/general/partner/hooks/useInfoPanels';
import { viewConfig } from '@theme/viewConfig';
import { useSelector } from 'react-redux';
import { partnerEditAdminPartnerSelector } from '../store/selectors';

export const PartnerEditAdminSidePanelsAdapter = () => {
  const partner = useSelector(partnerEditAdminPartnerSelector);

  const infoPanels = usePartnerInfoPanels({
    partner,
  });

  return (
    <SidePanelContentWrapper width={viewConfig.infoPanel.contentWidth}>
      {infoPanels?.map((infoPanel, index) => (
        <EntityMasterInfoPanelItem
          key={index}
          infoPanel={infoPanel}
        />
      ))}
    </SidePanelContentWrapper>
  );
};
