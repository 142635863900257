import { CorpOffer, EOfferType, MpUser } from '@/domain';
import { OfferChangeModeratorDialog } from '@features/general/offer/components/changeModeratorDialog';
import { EOfferActionType } from '@features/general/offer/types';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getIsCorpOfferAnyActionByTypeExecutingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'changeModeratorCollection';

const CorpOfferActionsDialogChangeModeratorCollectionAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getIsCorpOfferAnyActionByTypeExecutingSelector(EOfferActionType.ChangeModerator));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onChangeModerator = useCallback(
    (offers: CorpOffer[], user: MpUser) => {
      handlers.onChangeModerator(offers, user.id, action?.notification).then(() => {
        onCloseDialog();
      });
    },
    [handlers.onChangeModerator, action]
  );

  return (
    action?.data && (
      <OfferChangeModeratorDialog<CorpOffer>
        offers={action.data}
        offerType={EOfferType.Corp}
        isExecuting={isProcessing}
        onExecute={onChangeModerator}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogChangeModeratorCollectionAdapter;
