import { EMPStepperTransitionMethod } from '@components/common/stepper';
import StepperContainer from '../../../general/stepper/container';
import { BookingOfferCreateStepType } from '../../types';
import useBookingOfferDetailsStepper from '../hooks/useStepper';
import BookingOfferDetailsButtonsAdapter from './stepperButtons';

const BookingOfferViewStepperAdapter = () => {
  const { currentStep, steps, openStep } = useBookingOfferDetailsStepper();

  return (
    <StepperContainer<BookingOfferCreateStepType>
      step={currentStep}
      steps={steps}
      flow={false}
      forwardTransition={EMPStepperTransitionMethod.Free}
      footer={<BookingOfferDetailsButtonsAdapter />}
      onClick={openStep}
    />
  );
};

export default BookingOfferViewStepperAdapter;
