import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { Link } from '@mui/material';
import { LinkProps } from '@mui/material/Link/Link';
import { getUserRoleManualPath } from 'presentation/utils/roles';
import { FCC } from 'react';
import useBusinessConfig from '../../../hooks/useBusinessConfig';

type SupportManualLinkContainerProps = LinkProps;

const SupportManualLinkContainer: FCC<SupportManualLinkContainerProps> = ({ children, ...others }) => {
  const {
    accessMatrix: { roles },
  } = useCurrentUser();

  const {
    mp: { manualPaths: mpManualPaths },
    sport: { manualPaths: sportManualPaths },
  } = useBusinessConfig().supportInfo;

  const url = getUserRoleManualPath(roles, sportManualPaths, mpManualPaths);

  return (
    <Link
      target='_blank'
      href={url}
      color='primary'
      {...others}
    >
      {children ?? 'Руководство пользователя'}
    </Link>
  );
};

export default SupportManualLinkContainer;
