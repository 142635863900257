import { EOfferActionType } from '@features/general/offer/types';
import { FCC } from 'react';
import { useDispatch } from 'react-redux';
import useCurrentUser from '../../user/hooks/useCurrentUser';
import CorpOfferActionsDialogsAdapter from './adapters/dialogs';
import CorpOfferActionsOptimizerAdapter from './adapters/optimizer';
import CorpOfferActionsContext, { CorpOfferActionsContextType } from './context';
import {
  corpOfferCollectionActionProcessingSelector,
  getCorpOfferActionsOfferIsProcessingSelector,
  getIsCorpOfferAnyActionByTypeExecutedSelector,
  getIsCorpOfferAnyActionByTypeExecutingSelector,
  isCorpOfferAnyCollectionActionProcessingSelector,
  isCorpOffersAnyChangedSelector,
} from './store/selectors';
import {
  corpOfferActionsApprove,
  corpOfferActionsArchive,
  corpOfferActionsChangeApprovalRegistry,
  corpOfferActionsChangeDialogState,
  corpOfferActionsChangeModerator,
  corpOfferActionsDelete,
  corpOfferActionsDuplicate,
  corpOfferActionsInWork,
  corpOfferActionsNotUsedCodesDelete,
  corpOfferActionsPause,
  corpOfferActionsPublish,
  corpOfferActionsReject,
  corpOfferActionsResume,
  corpOfferActionsRetrieve,
  corpOfferActionsReturnToVerification,
  corpOfferActionsSave,
  corpOfferActionsUnPublish,
} from './store/slice';
import { showCorpOfferActionNotification } from './utils';

const CorpOfferActionsProvider: FCC = ({ children }) => {
  const dispatch = useDispatch();

  const { id: userId } = useCurrentUser().userSpecific;

  const onSave: CorpOfferActionsContextType['onSave'] = async (offer, notification = true) => {
    const response = await dispatch(corpOfferActionsSave(offer)).unwrap();
    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.Edit, !offer.id);
    }
    return response;
  };

  const onUnPublish: CorpOfferActionsContextType['onUnPublish'] = async (id, notification = true) => {
    const response = await dispatch(corpOfferActionsUnPublish({ id })).unwrap();

    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.UnPublish);
    }
    return response;
  };

  const onArchive: CorpOfferActionsContextType['onArchive'] = async (offers, notification = true) => {
    await dispatch(corpOfferActionsArchive({ ids: offers.map(offer => offer.id) })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.Archive);
    }
  };

  const onPublish: CorpOfferActionsContextType['onPublish'] = async (id, notification) => {
    const response = await dispatch(corpOfferActionsPublish({ id })).unwrap();

    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.Publish);
    }
    return response;
  };

  const onApprove: CorpOfferActionsContextType['onApprove'] = async (offers, notification = true) => {
    await dispatch(corpOfferActionsApprove({ ids: offers.map(offer => offer.id) })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.Approve);
    }
  };

  const onDuplicate: CorpOfferActionsContextType['onDuplicate'] = async (offer, notification = true) => {
    const response = await dispatch(corpOfferActionsDuplicate({ id: offer.id })).unwrap();
    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.Duplicate);
    }
    return response;
  };

  const onDelete: CorpOfferActionsContextType['onDelete'] = async (id, notification = true) => {
    await dispatch(corpOfferActionsDelete({ id })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.Delete);
    }
    return Promise.resolve(false);
  };

  const onDeleteCodes: CorpOfferActionsContextType['onDeleteCodes'] = async (id, notification = true) => {
    const response = await dispatch(corpOfferActionsNotUsedCodesDelete({ id })).unwrap();
    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.DeleteCodes);
    }
    return response;
  };

  const onReturnToVerification: CorpOfferActionsContextType['onReturnToVerification'] = async (
    offer,
    notification = true
  ) => {
    const response = await dispatch(corpOfferActionsReturnToVerification({ id: offer.id, userId })).unwrap();
    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.ReturnToVerification);
    }
    return response;
  };

  const onReject: CorpOfferActionsContextType['onReject'] = async (offers, reason, comment, notification = true) => {
    await dispatch(corpOfferActionsReject({ ids: offers.map(offer => offer.id), reason, comment })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.Reject);
    }
  };

  const onResume: CorpOfferActionsContextType['onResume'] = async (offers, notification = true) => {
    await dispatch(corpOfferActionsResume({ ids: offers.map(offer => offer.id) })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.Resume);
    }
  };

  const onRetrieve: CorpOfferActionsContextType['onRetrieve'] = async (offer, endDate, notification = true) => {
    const response = await dispatch(corpOfferActionsRetrieve({ id: offer.id, endDate })).unwrap();

    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.Retrieve);
    }

    return response;
  };

  const onPause: CorpOfferActionsContextType['onPause'] = async (offers, reason, comment, notification = true) => {
    await dispatch(corpOfferActionsPause({ ids: offers.map(offer => offer.id), reason, comment })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.Pause);
    }
  };

  const onInWork: CorpOfferActionsContextType['onInWork'] = async (offer, notification = true) => {
    const response = await dispatch(corpOfferActionsInWork({ id: offer.id, userId })).unwrap();
    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.InWork);
    }
    return response;
  };

  const onChangeModerator: CorpOfferActionsContextType['onChangeModerator'] = async (
    offers,
    userId,
    notification = true
  ) => {
    await dispatch(corpOfferActionsChangeModerator({ ids: offers.map(offer => offer.id), userId })).unwrap();
    if (notification) {
      showCorpOfferActionNotification(EOfferActionType.ChangeModerator);
    }
  };

  const onChangeApprovalRegistry: CorpOfferActionsContextType['onChangeApprovalRegistry'] = async (
    offers,
    approvalRegistry,
    notification = true
  ) => {
    const response = await dispatch(
      corpOfferActionsChangeApprovalRegistry({
        ids: offers.map(offer => offer.id),
        approvalRegistry,
      })
    ).unwrap();
    if (response && notification) {
      showCorpOfferActionNotification(EOfferActionType.ChangeApprovalRegistry);
    }
    return response;
  };

  const onTryReject: CorpOfferActionsContextType['onTryReject'] = props => {
    onChangeDialogState({ name: 'reject', data: props });
  };

  const onTryRejectCollection: CorpOfferActionsContextType['onTryRejectCollection'] = props => {
    onChangeDialogState({ name: 'rejectCollection', data: props });
  };

  const onTryDelete: CorpOfferActionsContextType['onTryDelete'] = props => {
    onChangeDialogState({ name: 'delete', data: props });
  };

  const onTryDeleteCodes: CorpOfferActionsContextType['onTryDeleteCodes'] = props => {
    onChangeDialogState({ name: 'deleteCodes', data: props });
  };

  const onTryCreateCodes: CorpOfferActionsContextType['onTryCreateCodes'] = props => {
    onChangeDialogState({ name: 'createCodes', data: props });
  };

  const onTryArchive: CorpOfferActionsContextType['onTryArchive'] = props => {
    onChangeDialogState({ name: 'archive', data: props });
  };

  const onTryArchiveCollection: CorpOfferActionsContextType['onTryArchiveCollection'] = props => {
    onChangeDialogState({ name: 'archiveCollection', data: props });
  };

  const onTryPause: CorpOfferActionsContextType['onTryPause'] = props => {
    onChangeDialogState({ name: 'pause', data: props });
  };

  const onTryPauseCollection: CorpOfferActionsContextType['onTryPauseCollection'] = props => {
    onChangeDialogState({ name: 'pauseCollection', data: props });
  };

  const onTryChangeModerator: CorpOfferActionsContextType['onTryChangeModerator'] = props => {
    onChangeDialogState({ name: 'changeModerator', data: props });
  };

  const onTryChangeModeratorCollection: CorpOfferActionsContextType['onTryChangeModeratorCollection'] = props => {
    onChangeDialogState({ name: 'changeModeratorCollection', data: props });
  };

  const onTryChangeApprovalRegistry: CorpOfferActionsContextType['onTryChangeApprovalRegistry'] = props => {
    onChangeDialogState({ name: 'changeApprovalRegistry', data: props });
  };

  const onTryChangeApprovalRegistryCollection: CorpOfferActionsContextType['onTryChangeApprovalRegistryCollection'] =
    props => {
      onChangeDialogState({ name: 'changeApprovalRegistryCollection', data: props });
    };

  const onTryReturnToVerification: CorpOfferActionsContextType['onTryReturnToVerification'] = props => {
    onChangeDialogState({ name: 'returnToVerification', data: props });
  };

  const onTryDuplicate: CorpOfferActionsContextType['onTryDuplicate'] = props => {
    dispatch(corpOfferActionsDuplicate({ id: props.data.id }))
      .unwrap()
      .then(() => showCorpOfferActionNotification(EOfferActionType.Duplicate));
  };

  const onTryRetrieve: CorpOfferActionsContextType['onTryRetrieve'] = props => {
    onChangeDialogState({ name: 'retrieve', data: props });
  };

  const onTryApprove: CorpOfferActionsContextType['onTryApprove'] = props => {
    onChangeDialogState({ name: 'approve', data: props });
  };

  const onTryApproveCollection: CorpOfferActionsContextType['onTryApproveCollection'] = props => {
    onChangeDialogState({ name: 'approveCollection', data: props });
  };

  const onTryUnPublish: CorpOfferActionsContextType['onTryUnPublish'] = props => {
    onChangeDialogState({ name: 'unPublish', data: props });
  };

  const onTryPublish: CorpOfferActionsContextType['onTryPublish'] = props => {
    onChangeDialogState({ name: 'publish', data: props });
  };

  const onTryResume: CorpOfferActionsContextType['onTryResume'] = props => {
    onChangeDialogState({ name: 'resume', data: props });
  };

  const onTryResumeCollection: CorpOfferActionsContextType['onTryResumeCollection'] = props => {
    onChangeDialogState({ name: 'resumeCollection', data: props });
  };

  const onCloseDialog: CorpOfferActionsContextType['onCloseDialog'] = name => {
    onChangeDialogState({ name, data: null });
  };

  const onChangeDialogState: CorpOfferActionsContextType['onChangeDialogState'] = state => {
    dispatch(corpOfferActionsChangeDialogState(state));
  };

  const value: CorpOfferActionsContextType = {
    onSave,
    onCloseDialog,
    onChangeDialogState,
    onArchive,
    onApprove,
    onDuplicate,
    onDelete,
    onDeleteCodes,
    onReturnToVerification,
    onReject,
    onResume,
    onRetrieve,
    onPause,
    onInWork,
    onChangeModerator,
    onChangeApprovalRegistry,
    onUnPublish,
    onPublish,

    onTryReject,
    onTryPause,
    onTryArchive,
    onTryDelete,
    onTryDeleteCodes,
    onTryCreateCodes,
    onTryChangeModerator,
    onTryChangeApprovalRegistry,
    onTryReturnToVerification,
    onTryDuplicate,
    onTryRetrieve,
    onTryApprove,
    onTryUnPublish,
    onTryPublish,
    onTryResume,

    onTryApproveCollection,
    onTryChangeModeratorCollection,
    onTryChangeApprovalRegistryCollection,
    onTryArchiveCollection,
    onTryRejectCollection,
    onTryPauseCollection,
    onTryResumeCollection,

    utils: {
      selectors: {
        getIsOfferProcessingSelector: getCorpOfferActionsOfferIsProcessingSelector,
        getIsOfferExecutedActionsSelector: getIsCorpOfferAnyActionByTypeExecutedSelector,
        getIsOfferExecutingActionsSelector: getIsCorpOfferAnyActionByTypeExecutingSelector,
        isOfferAnyCollectionProcessingSelector: isCorpOfferAnyCollectionActionProcessingSelector,
        offerCollectionActionProcessingSelector: corpOfferCollectionActionProcessingSelector,
        isOffersAnyChangedSelector: isCorpOffersAnyChangedSelector,
      },
    },
  };

  return (
    <CorpOfferActionsContext.Provider value={value}>
      {children}
      <CorpOfferActionsOptimizerAdapter />
      <CorpOfferActionsDialogsAdapter />
    </CorpOfferActionsContext.Provider>
  );
};

export default CorpOfferActionsProvider;
