import { RootState } from '@/data/store/store';
import { ApprovalOfferRegistry, ApprovalOfferRegistryRequest, CorpOffer, Nullable, SportOption } from '@/domain';
import { EOfferActionType } from '@features/general/offer/types';
import React from 'react';
import {
  CorpOfferActionType,
  CorpOffersActionsChangeDialogState,
  CorpOffersActionsMultipleDialogData,
  CorpOffersActionsSingleDialogData,
  CorpOffersActionsState,
} from './store/slice';

export interface CorpOfferActionsSelectors {
  readonly getIsOfferProcessingSelector: (id: Nullable<UUID>) => (store: RootState) => boolean;
  readonly getIsOfferExecutedActionsSelector: (
    id: Nullable<UUID>,
    ...actions: EOfferActionType[]
  ) => (store: RootState) => Nullable<EOfferActionType>;
  readonly getIsOfferExecutingActionsSelector: (...actions: EOfferActionType[]) => (store: RootState) => boolean;
  readonly isOffersAnyChangedSelector: (store: RootState) => number;
  readonly isOfferAnyCollectionProcessingSelector: (store: RootState) => boolean;
  readonly offerCollectionActionProcessingSelector: (store: RootState) => CorpOfferActionType[];
}

export type OpenDialogProps = CorpOffersActionsSingleDialogData;

export type OpenDialogMultipleProps = CorpOffersActionsMultipleDialogData;

export interface CorpOfferActionsContextType {
  readonly onReject: (
    offers: CorpOffer[],
    reason: SportOption,
    comment?: string,
    notification?: boolean
  ) => Promise<void>;
  readonly onRetrieve: (offer: CorpOffer, endDate: string, notification?: boolean) => Promise<Nullable<CorpOffer>>;
  readonly onResume: (offers: CorpOffer[], notification?: boolean) => Promise<void>;
  readonly onInWork: (offer: CorpOffer, notification?: boolean) => Promise<CorpOffer>;
  readonly onPause: (
    offers: CorpOffer[],
    reason?: SportOption,
    comment?: string,
    notification?: boolean
  ) => Promise<void>;
  readonly onCloseDialog: (name: keyof CorpOffersActionsState['dialogs']) => void;
  readonly onChangeDialogState: (state: CorpOffersActionsChangeDialogState) => void;
  readonly onReturnToVerification: (offer: CorpOffer, notification?: boolean) => Promise<Nullable<CorpOffer>>;
  readonly onDuplicate: (offer: CorpOffer, notification?: boolean) => Promise<Nullable<CorpOffer>>;
  readonly onDelete: (id: UUID, notification?: boolean) => Promise<Nullable<boolean>>;
  readonly onDeleteCodes: (id: UUID, notification?: boolean) => Promise<CorpOffer>;
  readonly onSave: (offer: CorpOffer, notification?: boolean) => Promise<Nullable<CorpOffer>>;
  readonly onArchive: (offers: CorpOffer[], notification?: boolean) => Promise<void>;
  readonly onApprove: (offers: CorpOffer[], notification?: boolean) => Promise<void>;
  readonly onChangeModerator: (offers: CorpOffer[], userId: UUID, notification?: boolean) => Promise<void>;
  readonly onChangeApprovalRegistry: (
    offers: CorpOffer[],
    approvalRegistry: ApprovalOfferRegistryRequest,
    notification?: boolean
  ) => Promise<Nullable<ApprovalOfferRegistry>>;
  readonly onUnPublish: (id: UUID, notification?: boolean) => Promise<Nullable<CorpOffer>>;
  readonly onPublish: (id: UUID, notification?: boolean) => Promise<Nullable<CorpOffer>>;

  readonly onTryReject: (props: OpenDialogProps) => void;
  readonly onTryApprove: (props: OpenDialogProps) => void;
  readonly onTryReturnToVerification: (props: OpenDialogProps) => void;
  readonly onTryDuplicate: (props: OpenDialogProps) => void;
  readonly onTryDelete: (props: OpenDialogProps) => void;
  readonly onTryDeleteCodes: (props: OpenDialogProps) => void;
  readonly onTryCreateCodes: (props: OpenDialogProps) => void;
  readonly onTryArchive: (props: OpenDialogProps) => void;
  readonly onTryUnPublish: (props: OpenDialogProps) => void;
  readonly onTryChangeModerator: (props: OpenDialogProps) => void;
  readonly onTryChangeApprovalRegistry: (props: OpenDialogProps) => void;
  readonly onTryPause: (props: OpenDialogProps) => void;
  readonly onTryPublish: (props: OpenDialogProps) => void;
  readonly onTryRetrieve: (props: OpenDialogProps) => void;
  readonly onTryResume: (props: OpenDialogProps) => void;

  readonly onTryApproveCollection: (props: OpenDialogMultipleProps) => void;
  readonly onTryChangeModeratorCollection: (props: OpenDialogMultipleProps) => void;
  readonly onTryChangeApprovalRegistryCollection: (props: OpenDialogMultipleProps) => void;
  readonly onTryArchiveCollection: (props: OpenDialogMultipleProps) => void;
  readonly onTryRejectCollection: (props: OpenDialogMultipleProps) => void;
  readonly onTryPauseCollection: (props: OpenDialogMultipleProps) => void;
  readonly onTryResumeCollection: (props: OpenDialogMultipleProps) => void;

  readonly utils: {
    readonly selectors: CorpOfferActionsSelectors;
  };
}

const CorpOfferActionsContext = React.createContext<CorpOfferActionsContextType>({} as CorpOfferActionsContextType);

export default CorpOfferActionsContext;
