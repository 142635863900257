import CorpOfferActionsDialogApproveAdapter from './dialogApprove';
import CorpOfferActionsDialogApproveCollectionAdapter from './dialogApproveCollection';
import CorpOfferActionsDialogArchiveAdapter from './dialogArhive';
import CorpOfferActionsDialogArchiveCollectionAdapter from './dialogArhiveCollection';
import CorpOfferActionsDialogChangeApprovalRegistryAdapter from './dialogChangeApprovalRegistry';
import CorpOfferActionsDialogChangeApprovalRegistryCollectionAdapter from './dialogChangeApprovalRegistryCollection';
import CorpOfferActionsDialogChangeModeratorAdapter from './dialogChangeModerator';
import CorpOfferActionsDialogChangeModeratorCollectionAdapter from './dialogChangeModeratorCollection';
import CorpOfferActionsDialogCreateCodesAdapter from './dialogCreateCodes';
import CorpOfferActionsDialogDeleteAdapter from './dialogDelete';
import CorpOfferActionsDialogDeleteCodesAdapter from './dialogDeleteCodes';
import CorpOfferActionsDialogPauseAdapter from './dialogPause';
import CorpOfferActionsDialogPauseCollectionAdapter from './dialogPauseCollection';
import CorpOfferActionsDialogRejectAdapter from './dialogReject';
import CorpOfferActionsDialogRejectCollectionAdapter from './dialogRejectCollection';
import CorpOfferActionsDialogResumeAdapter from './dialogResume';
import CorpOfferActionsDialogResumeCollectionAdapter from './dialogResumeCollection';
import CorpOfferActionsDialogRetrieveAdapter from './dialogRetrieve';
import CorpOfferActionsDialogUnPublishAdapter from './dialogUnPublish';

const CorpOfferActionsDialogsAdapter = () => {
  return (
    <>
      <CorpOfferActionsDialogPauseAdapter />
      <CorpOfferActionsDialogRejectAdapter />
      <CorpOfferActionsDialogArchiveAdapter />
      <CorpOfferActionsDialogChangeModeratorAdapter />
      <CorpOfferActionsDialogRetrieveAdapter />
      <CorpOfferActionsDialogDeleteAdapter />
      <CorpOfferActionsDialogDeleteCodesAdapter />
      <CorpOfferActionsDialogUnPublishAdapter />
      <CorpOfferActionsDialogResumeAdapter />
      <CorpOfferActionsDialogCreateCodesAdapter />
      <CorpOfferActionsDialogApproveAdapter />
      <CorpOfferActionsDialogChangeApprovalRegistryAdapter />

      <CorpOfferActionsDialogApproveCollectionAdapter />
      <CorpOfferActionsDialogChangeApprovalRegistryCollectionAdapter />
      <CorpOfferActionsDialogChangeModeratorCollectionAdapter />
      <CorpOfferActionsDialogArchiveCollectionAdapter />
      <CorpOfferActionsDialogRejectCollectionAdapter />
      <CorpOfferActionsDialogPauseCollectionAdapter />
      <CorpOfferActionsDialogResumeCollectionAdapter />
    </>
  );
};

export default CorpOfferActionsDialogsAdapter;
