import { SportOption } from '@/domain';
import { nsiDataSelector } from '@features/general/nsi/store/selectors';
import OfferReasonsDialog from '@features/general/offer/components/reasonsDialog';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getIsCorpOfferAnyActionByTypeExecutingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';
import { EOfferActionType } from '@features/general/offer/types';
import { pluralize } from 'presentation/utils/pluralize';

const nameDialog: CorpOfferStateKeyDialogs = 'rejectCollection';

const CorpOfferActionsDialogRejectCollectionAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const { offerRejectReasons: rejectReasons } = useSelector(nsiDataSelector);
  const isProcessing = useSelector(getIsCorpOfferAnyActionByTypeExecutingSelector(EOfferActionType.Reject));

  const text =
    action?.data?.length &&
    `Укажите причину отклонения ${pluralize(action.data.length, [
      'корпоративное предложение',
      'корпоративных предложения',
      'корпоративных предложений',
    ])}: ${action.data.length}`;

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onReject = useCallback(
    (reason: SportOption, comment?: string) => {
      action?.data &&
        handlers.onReject(action.data, reason, comment, action?.notification).then(() => {
          onCloseDialog();
        });
    },
    [handlers.onReject, action]
  );

  return (
    action?.data && (
      <OfferReasonsDialog
        title='Отклонение корпоративных предложений'
        actionText='Отклонить'
        text={text}
        isFetching={isProcessing}
        reasons={rejectReasons}
        onAction={onReject}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogRejectCollectionAdapter;
