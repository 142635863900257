import { AppFile, ENoticeStatus } from '@/domain';
import { Box, Typography } from '@mui/material';
import { MPButton } from '@ui-kit/button';
import { MPFormTextArea } from '@ui-kit/input';
import { useCallback, useEffect, useState } from 'react';
import Notifier from 'system/notifier';
import ConfirmDialog from '../../components/common/dialogs/confirm';
import FileInfo from '../../components/common/files/info';
import FileUploader from '../../components/common/files/uploader';
import Splitter from '../../components/common/splitter';
import { fileTypes } from '../../constants';
import useValidation from '../../hooks/validation/useValidation';
import { ContentTypeDocumentIcon } from '../../icons';
import { ValidationRules } from '../../utils/validation';

export type FeedbackData = {
  formText: string;
  formFile?: AppFile;
};

type FeedbackProps = {
  readonly open: boolean;
  readonly title?: string;
  readonly text?: string;
  readonly textFile?: string;
  readonly successTitle?: string;
  readonly successText?: string;
  readonly isFile?: boolean;
  onClose(): void;
  onSubmit(data: FeedbackData): Promise<boolean>;
};

const rulesValidation: ValidationRules<FeedbackData> = {
  formText: {
    required: true,
  },
};

export const documentMaxSize = 5 * 1024 * 1024;

export const Feedback = (props: FeedbackProps) => {
  const {
    open,
    title = 'Форма обратной связи',
    successTitle = 'Спасибо! Мы получили ваш обращение ',
    successText = 'Ответ придет на почту',
    text = 'Если у вас есть вопросы или пожелания, заполните форму ниже',
    textFile = `Также можете прикрепить файл, но это не обязательно. Файл должен быть не больше ${
      Math.round((documentMaxSize / 1024 / 1024) * 100) / 100
    } Мб.`,
    isFile = true,
    onClose,
    onSubmit,
  } = props;

  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [validateOnChange, setValidateOnChange] = useState<boolean>(false);
  const [formText, setFormText] = useState<string>('');
  const [formFile, setFormFile] = useState<AppFile>();

  const validation = useValidation<FeedbackData>({
    object: {
      formText,
      formFile,
    },
    validateOnChange: validateOnChange,
    rules: { ...rulesValidation },
  });

  const handleClose = useCallback(() => {
    onClose();
    setIsSuccess(false);
    setValidateOnChange(false);
    setFormText('');
    setFormFile(undefined);
  }, [onClose]);

  useEffect(() => {
    if (!validation.isValid) {
      const isValid = validation.validate();
      setValidateOnChange(isValid);
    }
  }, [validation.isValid, formFile, formText]);

  const handleSubmit = async () => {
    const isValid = validation.validate();
    setValidateOnChange(isValid);
    if (isValid) {
      try {
        const isSuccess = await onSubmit({
          formText,
          formFile,
        });

        if (isSuccess) {
          setIsSuccess(true);
        }
      } catch (e: any) {
        Notifier.getInstance().addNotice(ENoticeStatus.Error, e.message);
      }
    }
  };

  return (
    <ConfirmDialog
      open={open}
      onClose={handleClose}
      title={isSuccess ? successTitle : title}
      dialogButtons={
        <Box sx={{ display: 'flex', gap: 2.5, justifyContent: 'flex-start', width: '100%', mt: 2 }}>
          {isSuccess ? (
            <MPButton
              fullWidth={false}
              onClick={handleClose}
            >
              Отлично
            </MPButton>
          ) : (
            <>
              <MPButton
                fullWidth={false}
                onClick={handleSubmit}
              >
                Отправить
              </MPButton>
              <MPButton
                fullWidth={false}
                onClick={handleClose}
                variant={'outlined'}
              >
                Отмена
              </MPButton>
            </>
          )}
        </Box>
      }
    >
      <Typography variant='body2'>{isSuccess ? successText : text}</Typography>

      {!isSuccess && (
        <>
          <Splitter
            variant='horizontal'
            size={3}
          />

          <MPFormTextArea
            minRows={5}
            label='Ваш вопрос или пожелание'
            value={formText}
            onChange={event => setFormText(event.target.value)}
            inputProps={{ maxLength: 1000 }}
            error={!!validation.validationResult?.formText?.hasError}
            helperText={validation.validationResult?.formText?.message}
          />

          {isFile && (
            <>
              <Splitter
                variant='horizontal'
                size={4}
              />

              <Typography
                variant='body2'
                gutterBottom
              >
                {textFile}
              </Typography>

              {formFile ? (
                <FileInfo
                  key={formFile.path}
                  id={formFile.path}
                  icon={<ContentTypeDocumentIcon />}
                  onRemove={() => {
                    setFormFile(undefined);
                  }}
                />
              ) : (
                <FileUploader
                  fileMaxSize={documentMaxSize}
                  text={
                    <div>
                      <Typography
                        color='primary'
                        align='center'
                        gutterBottom
                      >
                        Загрузить файл
                      </Typography>
                      <Typography
                        color={'textSecondary'}
                        variant={'caption'}
                        align='center'
                      >
                        Допустимые форматы файлов doc, xslx
                      </Typography>
                    </div>
                  }
                  fileTypes={[
                    fileTypes.docx.mimeType,
                    fileTypes.doc.mimeType,
                    fileTypes.xls.mimeType,
                    fileTypes.xlsx.mimeType,
                  ]}
                  accept={[fileTypes.docx.mimeType, fileTypes.doc.ext, fileTypes.xls.ext, fileTypes.xlsx.ext]}
                  onUpload={file => {
                    setFormFile(file);
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </ConfirmDialog>
  );
};
