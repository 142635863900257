import { useContext } from 'react';
import CorpOfferActionsContext, { CorpOfferActionsContextType } from './context';

export const useCorpOfferActions = (): CorpOfferActionsContextType => {
  return useContext<CorpOfferActionsContextType>(CorpOfferActionsContext);
};

export const useCorpOfferActionsSelectors = (): CorpOfferActionsContextType['utils']['selectors'] => {
  return useCorpOfferActions().utils.selectors;
};
