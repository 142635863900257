import { ServerErrorResponse } from '@/data/network/types';
import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault, fetchableFailed, fetchableFetched, fetchableFetching } from '@/data/store/types';
import { CorpOffer, Nullable, Pageable, UUID } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { PaginationSize } from '../../../../types';
import { EOfferTableNotificationType } from '../../../general/offer/enums';
import offerServices from '../../../general/offer/services';
import { AllProps } from '../../../general/offer/services/corpOffers';
import { EOfferActionType, EOfferTableTab } from '../../../general/offer/types';
import { OfferTableTabsCounter } from '../../../general/offer/utils/table';
import { corpOfferCreatePublish, corpOfferCreateSave } from '../../create/store/slice';
import { corpOfferDetailsApprove, corpOfferDetailsReject, corpOfferDetailsResume } from '../../details/store/slice';
import { CorpOfferTableFilterValues } from '../../filterUtils';
import { ECorpOfferTableColumn } from '../../types';

const defaultSort = `${ECorpOfferTableColumn.Name},asc`;

const getActionProcess = (state: CorpOfferListState, id: UUID, actionType: EOfferActionType) => {
  let process = state.actions.find(change => change.id === id);
  if (process) return process;

  process = {
    ...fetchableDefault,
    id,
    type: actionType,
    error: null,
  };
  state.actions.push(process);

  return process;
};

export type CorpOffersFetchProps = Omit<AllProps, 'signal'>;

export type CorpOffersCountsFetchProps = CorpOffersFetchProps & {
  readonly tabs: EOfferTableTab[];
};

export const corpOffersFetch = createAsyncThunk<Pageable<CorpOffer>, CorpOffersFetchProps, AppThunkAPIConfig>(
  'corpOffer/list/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await offerServices.corp.all({ ...props, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOffersCountFetch = createAsyncThunk<number, CorpOffersFetchProps, AppThunkAPIConfig>(
  'corpOffer/list/count/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      return await offerServices.corp.count({ ...props, signal });
    } catch (e: any) {
      console.error(e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOffersUnwatchedFetch = createAsyncThunk<UUID[], CorpOffersFetchProps, AppThunkAPIConfig>(
  'corpOffer/list/unwatched/fetch',
  async (props, { rejectWithValue, signal }) => {
    try {
      const data = await offerServices.corp.unwatched({ ...props, signal });
      return data.map(({ id }) => id);
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOffersCountsFetch = createAsyncThunk<
  OfferTableTabsCounter,
  CorpOffersCountsFetchProps,
  AppThunkAPIConfig
>('corpOffer/list/counts/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await offerServices.corp.countsByTabs({ ...props, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOffersDuplicate = createAsyncThunk<CorpOffer, { id: UUID }, AppThunkAPIConfig>(
  'corpOffer/list/duplicate',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.duplicate({
        id,
      });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOffersCountsUnwatchedFetch = createAsyncThunk<
  OfferTableTabsCounter,
  CorpOffersCountsFetchProps,
  AppThunkAPIConfig
>('corpOffer/list/counts/unwatched/fetch', async (props, { rejectWithValue, signal }) => {
  try {
    const { counts, errors } = await offerServices.corp.countsByTabs({ ...props, viewed: false, signal });

    if (errors.length > 0) {
      console.error(errors.join('\n'));
    }

    return counts;
  } catch (e: any) {
    console.error(e.response);
    return rejectWithValue(e.response.data);
  }
});

export const corpOffersDeleteCorpOffer = createAsyncThunk<void, { id: UUID }, AppThunkAPIConfig<ServerErrorResponse>>(
  'corpOffer/list/delete',
  async ({ id }, { rejectWithValue }) => {
    try {
      return await offerServices.corp.delete({ id });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

export const corpOffersRetrieveCorpOffer = createAsyncThunk<
  CorpOffer,
  { id: UUID },
  AppThunkAPIConfig<ServerErrorResponse>
>('corpOffer/list/retrieve', async ({ id }, { rejectWithValue }) => {
  try {
    return await offerServices.corp.retrieve({ id });
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface CorpOfferListState extends Fetchable, Pageable<CorpOffer> {
  readonly guid: Nullable<UUID>;
  readonly needRefreshWatcher: number;
  readonly pageNumber: number;
  readonly search: {
    readonly sort: string;
    readonly pageSize: PaginationSize;
    readonly partnerId: Nullable<UUID>;
  };
  readonly allCorpOffers: Fetchable & {
    readonly count: Nullable<number>;
    readonly needRefreshWatcher: number;
  };
  readonly tab: Nullable<EOfferTableTab>;
  readonly filter: CorpOfferTableFilterValues;
  readonly notifierInfo: Nullable<{
    type: EOfferTableNotificationType;
    corpOffer: CorpOffer;
  }>;
  readonly actions: (Fetchable & {
    id: UUID;
    type: EOfferActionType;
    error: Nullable<ServerErrorResponse>;
  })[];
  readonly retrieve: Fetchable;
  readonly duplicate: Fetchable;
  readonly tabsCounter: OfferTableTabsCounter;
  readonly tabsCounterUnwatched: OfferTableTabsCounter;
  readonly selected: CorpOffer[];
  readonly unwatchedIds: UUID[];
}

type Reducer<T = undefined> = CaseReducer<CorpOfferListState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<CorpOfferListState> {
  corpOffersStartSession: Reducer<{ guid: UUID; filter?: CorpOfferTableFilterValues }>;
  corpOffersSetTab: Reducer<{ tab: EOfferTableTab }>;
  corpOffersSetSearch: Reducer<{ sort: string; pageSize: PaginationSize }>;
  corpOffersSortReset: Reducer;
  corpOffersSetSort: Reducer<{ sort: string }>;
  corpOffersSetPage: Reducer<{ pageNumber: number }>;
  corpOffersSetPageSize: Reducer<{ pageSize: PaginationSize }>;
  corpOffersSetFilter: Reducer<CorpOfferTableFilterValues>;
  corpOffersClearNotifierInfo: Reducer;
  corpOffersActionsReset: Reducer;
  corpOffersDataReset: Reducer;
  corpOffersNeedRefreshWatcherIncrement: Reducer;
  corpOffersNeedRefreshWatcherReset: Reducer;
  corpOffersSelect: Reducer<CorpOffer>;
  corpOffersUnselect: Reducer<CorpOffer>;
  corpOffersAllSelect: Reducer;
  corpOffersAllUnselect: Reducer;
}

const slice = createSlice<CorpOfferListState, Reducers, 'list'>({
  name: 'list',
  initialState: {
    ...fetchableDefault,
    guid: null,
    data: [],
    totalCount: 0,
    pageCount: 0,
    pageNumber: 1,
    needRefreshWatcher: 0,
    search: {
      sort: defaultSort,
      pageSize: 10,
      partnerId: null,
    },
    allCorpOffers: {
      ...fetchableDefault,
      count: null,
      needRefreshWatcher: 0,
    },
    tab: null,
    filter: {},
    notifierInfo: null,
    actions: [],
    retrieve: fetchableDefault,
    duplicate: fetchableDefault,
    tabsCounter: {},
    tabsCounterUnwatched: {},
    selected: [],
    unwatchedIds: [],
  },
  reducers: {
    corpOffersStartSession: (state, { payload }) => {
      const { guid, filter } = payload;
      if (guid !== state.guid) {
        state.guid = guid;

        state.isFetching = false;
        state.isFetched = false;
        state.isFailed = false;

        state.data = [];
        state.totalCount = 0;
        state.pageCount = 0;
        state.pageNumber = 1;
        state.needRefreshWatcher = 0;
        state.search = {
          sort: defaultSort,
          pageSize: 10,
          partnerId: null,
        };
        state.allCorpOffers = {
          ...fetchableDefault,
          count: null,
          needRefreshWatcher: 0,
        };
        state.tab = null;
        state.filter = filter ?? {};
        state.actions = [];
        state.retrieve = fetchableDefault;
        state.duplicate = fetchableDefault;
        state.tabsCounter = {};
        state.tabsCounterUnwatched = {};
        state.selected = [];
        state.unwatchedIds = [];
      }
    },
    corpOffersSetTab: (state, { payload }) => {
      const { tab } = payload;
      // сбрасываем пейджинг и сортировку, если поменялись статусы (закладки)
      if (state.tab !== tab) {
        state.pageNumber = 1;
        state.search.sort = defaultSort;
        state.tab = tab;
      }
      state.needRefreshWatcher++;
      state.selected = [];
    },
    corpOffersSetSearch: (state, { payload }) => {
      const { sort, pageSize } = payload;
      state.search = {
        ...state.search,
        sort,
        pageSize,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    corpOffersSetSort: (state, { payload }) => {
      const { sort } = payload;

      state.search.sort = sort;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    corpOffersSortReset: (state, { payload }) => {
      state.search = {
        ...state.search,
        sort: defaultSort,
      };
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    corpOffersSetPage: (state, { payload }) => {
      const { pageNumber } = payload;
      state.pageNumber = pageNumber;
      state.needRefreshWatcher++;
    },
    corpOffersSetPageSize: (state, { payload }) => {
      const { pageSize } = payload;
      state.pageNumber = 1;
      state.search.pageSize = pageSize;
      state.needRefreshWatcher++;
    },
    corpOffersSetFilter: (state, { payload }) => {
      state.filter = payload;
      state.pageNumber = 1;
      state.needRefreshWatcher++;
      state.selected = [];
    },
    corpOffersClearNotifierInfo: state => {
      state.notifierInfo = null;
    },
    corpOffersActionsReset: state => {
      state.actions = [];
    },
    corpOffersDataReset: state => {
      state.data = [];
      state.totalCount = 0;
      state.pageCount = 0;
      state.pageNumber = 1;
      state.selected = [];
    },
    corpOffersNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    corpOffersNeedRefreshWatcherReset: state => {
      state.needRefreshWatcher = 0;
      state.allCorpOffers.needRefreshWatcher = 0;
    },
    corpOffersSelect: (state, { payload }) => {
      const selected = [...state.selected];
      const existedIndex = selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        selected.splice(existedIndex, 1);
      }
      state.selected = [...selected, payload];
    },
    corpOffersUnselect: (state, { payload }) => {
      const existedIndex = state.selected.findIndex(s => s.id === payload.id);
      if (existedIndex !== -1) {
        state.selected.splice(existedIndex, 1);
      }
    },
    corpOffersAllSelect: state => {
      state.selected = [...state.data];
    },
    corpOffersAllUnselect: state => {
      state.selected = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(corpOffersFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        state.isFetching = true;
        state.isFetched = false;
        state.isFailed = false;

        if (partnerId !== state.search.partnerId) {
          state.data = [];
        }
        state.search.partnerId = partnerId;
      })
      .addCase(corpOffersFetch.fulfilled, (state, { payload }) => {
        const { data, totalCount, pageCount } = payload;

        state.isFetching = false;
        state.isFetched = true;
        state.isFailed = false;

        state.data = data;
        state.totalCount = totalCount;
        state.pageCount = pageCount;
      })
      .addCase(corpOffersFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.isFetching = false;
          state.isFetched = false;
          state.isFailed = true;
          state.data = [];
        }
      })
      .addCase(corpOffersUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.unwatchedIds = [];
        }
      })
      .addCase(corpOffersUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.unwatchedIds = payload;
      })
      .addCase(corpOffersUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.unwatchedIds = [];
        }
      })
      .addCase(corpOffersCountsFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounter = {};
        }
      })
      .addCase(corpOffersCountsFetch.fulfilled, (state, { payload }) => {
        state.tabsCounter = payload;
      })
      .addCase(corpOffersCountsFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounter = {};
        }
      })
      .addCase(corpOffersCountsUnwatchedFetch.pending, (state, { meta }) => {
        const { partnerId } = meta.arg.search;

        if (partnerId !== state.search.partnerId) {
          state.tabsCounterUnwatched = {};
        }
      })
      .addCase(corpOffersCountsUnwatchedFetch.fulfilled, (state, { payload }) => {
        state.tabsCounterUnwatched = payload;
      })
      .addCase(corpOffersCountsUnwatchedFetch.rejected, (state, { meta }) => {
        const { aborted } = meta;
        if (!aborted) {
          state.tabsCounterUnwatched = {};
        }
      })
      .addCase(corpOffersDeleteCorpOffer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;
      })
      .addCase(corpOffersDeleteCorpOffer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
        state.allCorpOffers.needRefreshWatcher++;
      })
      .addCase(corpOffersDeleteCorpOffer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Delete;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;
      })
      .addCase(corpOffersDuplicate.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.duplicate = fetchableFetching;
      })
      .addCase(corpOffersDuplicate.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.needRefreshWatcher++;
      })
      .addCase(corpOffersRetrieveCorpOffer.pending, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Retrieve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = true;
        process.isFetched = false;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.retrieve = fetchableFetching;
      })
      .addCase(corpOffersRetrieveCorpOffer.fulfilled, (state, { meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Retrieve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = true;
        process.isFailed = false;

        process.id = id;
        process.type = actionType;
        process.error = null;

        state.retrieve = fetchableFetched;

        state.needRefreshWatcher++;
      })
      .addCase(corpOffersRetrieveCorpOffer.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Retrieve;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;

        state.retrieve = fetchableFailed;
      })
      .addCase(corpOffersDuplicate.rejected, (state, { payload, meta }) => {
        const { id } = meta.arg;

        const actionType = EOfferActionType.Duplicate;
        const process = getActionProcess(state, id, actionType);

        process.isFetching = false;
        process.isFetched = false;
        process.isFailed = true;

        process.id = id;
        process.type = actionType;
        process.error = payload ?? null;

        state.duplicate = fetchableFailed;
      })
      .addCase(corpOffersCountFetch.pending, state => {
        state.allCorpOffers.isFetching = true;
        state.allCorpOffers.isFetched = false;
        state.allCorpOffers.isFailed = false;

        if (state.allCorpOffers.count === 0) {
          //сбрасываем в null в том случае если до этого ничего не было, чтобы не моргала таблица лишний раз
          state.allCorpOffers.count = null;
        }
      })
      .addCase(corpOffersCountFetch.fulfilled, (state, { payload }) => {
        state.allCorpOffers.isFetching = false;
        state.allCorpOffers.isFetched = true;
        state.allCorpOffers.isFailed = false;

        state.allCorpOffers.count = payload;
      })
      .addCase(corpOffersCountFetch.rejected, state => {
        state.allCorpOffers.isFetching = false;
        state.allCorpOffers.isFetched = false;
        state.allCorpOffers.isFailed = true;

        state.allCorpOffers.count = null;
      })
      .addCase(corpOfferDetailsApprove.fulfilled, (state, { payload }) => {
        state.needRefreshWatcher++;
        state.notifierInfo = {
          type: EOfferTableNotificationType.Approved,
          corpOffer: payload,
        };
      })
      .addCase(corpOfferDetailsResume.fulfilled, (state, { payload }) => {
        state.needRefreshWatcher++;
        state.notifierInfo = {
          type: EOfferTableNotificationType.Resumed,
          corpOffer: payload,
        };
      })
      .addCase(corpOfferDetailsReject.fulfilled, (state, { payload }) => {
        state.needRefreshWatcher++;
        state.notifierInfo = {
          type: EOfferTableNotificationType.Rejected,
          corpOffer: payload,
        };
      })
      .addCase(corpOfferCreatePublish.fulfilled, (state, { payload }) => {
        state.notifierInfo = {
          type: EOfferTableNotificationType.Published,
          corpOffer: payload,
        };
        state.needRefreshWatcher++;
        state.allCorpOffers.needRefreshWatcher++;
      })
      .addCase(corpOfferCreateSave.fulfilled, (state, { payload }) => {
        state.allCorpOffers.needRefreshWatcher++;
      });
  },
});

export const {
  corpOffersStartSession,
  corpOffersSetTab,
  corpOffersSetSearch,
  corpOffersSortReset,
  corpOffersSetSort,
  corpOffersSetPage,
  corpOffersSetFilter,
  corpOffersSetPageSize,
  corpOffersClearNotifierInfo,
  corpOffersActionsReset,
  corpOffersDataReset,
  corpOffersNeedRefreshWatcherIncrement,
  corpOffersNeedRefreshWatcherReset,
  corpOffersSelect,
  corpOffersUnselect,
  corpOffersAllSelect,
  corpOffersAllUnselect,
} = slice.actions;

export default slice.reducer;
