import { Feedback } from '@features/feedback';
import { useFeedback } from '@features/feedback/useFeedback';
import { useMemo } from 'react';

type SupportInfoMpFeedbackDialogAdapterProps = {
  readonly opened: boolean;
  readonly onClose: () => void;
};
const SupportInfoMpFeedbackDialogAdapter = ({ opened, onClose }: SupportInfoMpFeedbackDialogAdapterProps) => {
  const { onSubmitFeedback } = useFeedback();

  return useMemo(() => {
    return opened ? (
      <Feedback
        open
        onClose={onClose}
        onSubmit={onSubmitFeedback}
      />
    ) : null;
  }, [opened, onSubmitFeedback, onClose]);
};

export default SupportInfoMpFeedbackDialogAdapter;
