import { AdCampaign } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { FCC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import useHistoryExtensions from '../../../../hooks/useHistoryExtensions';
import { useAdCampaignActions } from '../../actions/useActions';
import { getAdCampaignTableTabByNameAction } from '../../actions/utils';
import { EAdCampaignUrlParam, getAdCampaignEditRoute, getAdCampaignsTableRoute } from '../../entry';
import { EAdCampaignActionType } from '../../types';
import { AdCampaignDetailsContainerProps } from '../container';
import { AdCampaignDetailsHandlersContext, AdCampaignDetailsHandlersContextValue } from '../context';
import { adCampaignDetailsAdCampaignSelector } from '../store/selectors';
import { adCampaignDetailsByIdFetch } from '../store/slice';

export const AdCampaignDetailHandlersProvider: FCC<AdCampaignDetailsContainerProps> = ({ step, children, id }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { gotoPrevIndependentLocation } = useHistoryExtensions();
  const { onTryArchive, onTryPause, onTryResume } = useAdCampaignActions();

  /**
   * Получение объекта букинга
   */
  const getAdCampaignFromState = useCallback((): Nullable<AdCampaign> => {
    const state = store.getState();
    return adCampaignDetailsAdCampaignSelector(state);
  }, [store]);

  /**
   * обновить данные объекта
   */
  const onRefresh = useCallback<AdCampaignDetailsHandlersContextValue['onRefresh']>(() => {
    const adCampaign = getAdCampaignFromState();
    if (adCampaign) {
      dispatch(adCampaignDetailsByIdFetch(adCampaign.id));
    }
  }, [dispatch, getAdCampaignFromState]);

  /**
   * Изменить шаг
   */
  const onChangeStep = useCallback<AdCampaignDetailsHandlersContextValue['onChangeStep']>(
    newStep => {
      if (newStep.key !== step) {
        history.push(
          addSearchParamToLocation({
            location,
            param: EAdCampaignUrlParam.Step,
            value: newStep.key,
          })
        );
      }
    },
    [dispatch, step, history, location]
  );

  /**
   * Открыть объект для редактирования
   */
  const onAdCampaignOpenEdit = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignOpenEdit']>(
    adCampaign => {
      history.push(getAdCampaignEditRoute({ id: adCampaign.id }));
    },
    [history]
  );

  const onAdCampaignSwitchToEdit = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignSwitchToEdit']>(
    adCampaign => {
      history.replace(getAdCampaignEditRoute({ id: adCampaign.id }));
    },
    [history]
  );

  /**
   * Закрыть мастер просмотра
   */
  const onAdCampaignClose = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignClose']>(
    (action: Nullable<EAdCampaignActionType>) => {
      const tabByAction = getAdCampaignTableTabByNameAction(action);
      gotoPrevIndependentLocation(getAdCampaignsTableRoute({ tab: tabByAction ?? undefined }));
    },
    [gotoPrevIndependentLocation]
  );

  /**
   * Обновить данные объекта
   */
  const onAdCampaignRefetch = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignRefetch']>(
    async adCampaign => {
      const newAdCampaign = await dispatch(adCampaignDetailsByIdFetch(adCampaign.id)).unwrap();
      return newAdCampaign;
    },
    [dispatch]
  );

  /**
   * Добавить объект в архив
   */
  const onAdCampaignTryArchive = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignTryArchive']>(() => {
    const adCampaign = getAdCampaignFromState();

    if (adCampaign) {
      onTryArchive(adCampaign);
    }
  }, [getAdCampaignFromState]);

  /**
   * Приостановить объект
   */
  const onAdCampaignTryPause = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignTryPause']>(() => {
    const adCampaign = getAdCampaignFromState();

    if (adCampaign) {
      onTryPause(adCampaign);
    }
  }, [getAdCampaignFromState]);

  /**
   * Вернуть на проверку объект
   */
  const onAdCampaignTryResume = useCallback<AdCampaignDetailsHandlersContextValue['onAdCampaignTryResume']>(() => {
    const adCampaign = getAdCampaignFromState();

    if (adCampaign) {
      onTryResume(adCampaign);
    }
  }, [getAdCampaignFromState]);

  const value: AdCampaignDetailsHandlersContextValue = {
    onRefresh,

    onChangeStep,
    onAdCampaignOpenEdit,
    onAdCampaignSwitchToEdit,
    onAdCampaignClose,
    onAdCampaignRefetch,
    onAdCampaignTryArchive,
    onAdCampaignTryPause,
    onAdCampaignTryResume,
  };

  return (
    <AdCampaignDetailsHandlersContext.Provider value={value}>{children}</AdCampaignDetailsHandlersContext.Provider>
  );
};
