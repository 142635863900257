import { FileInfoBlankAdapter } from '@components/common/files/info/blankAdapter';
import SupportManualLinkContainer from '@features/support/manualLink';
import useCurrentUser from '@features/user/hooks/useCurrentUser';
import { Grid, Typography } from '@mui/material';
import { ArrowRightIcon } from '@mui/x-date-pickers';
import { useMemo } from 'react';
import FileInfo from '../../../../components/common/files/info';
import useTechConfig from '../../../../hooks/useTechConfig';
import { ContentTypeDocumentIcon } from '../../../../icons';
import { EAppFeature } from '../../../../types';
import SupportPdpLinkContainer from '../../pdpLink';
import SupportTermsOfUseLinkContainer from '../../termsLink';

const SupportInfoMpDocumentsAdapter = () => {
  const {
    accessMatrix: { isPartnerUser },
  } = useCurrentUser();

  const { hasFeature } = useTechConfig();

  const hasTermsOfUseFeature = useMemo<boolean>(() => hasFeature(EAppFeature.TermsOfUse), [hasFeature]);
  const hasPersonalDataPolicyFeature = useMemo<boolean>(() => hasFeature(EAppFeature.PersonalDataPolicy), [hasFeature]);

  return useMemo(() => {
    return isPartnerUser || hasTermsOfUseFeature || hasPersonalDataPolicyFeature ? (
      <Grid
        container
        direction='column'
        spacing={2}
      >
        <Grid item>
          <Typography
            variant='h3'
            gutterBottom
          >
            Документы
          </Typography>
        </Grid>
        {isPartnerUser && (
          <Grid item>
            <SupportManualLinkContainer underline='none'>
              <FileInfo
                color='secondary'
                icon={<ContentTypeDocumentIcon color='action' />}
                endIcon={<ArrowRightIcon color='action' />}
                adapter={FileInfoBlankAdapter}
                originalName='Руководство пользователя'
              />
            </SupportManualLinkContainer>
          </Grid>
        )}
        {hasTermsOfUseFeature && (
          <Grid item>
            <SupportTermsOfUseLinkContainer underline='none'>
              <FileInfo
                color={'secondary'}
                icon={<ContentTypeDocumentIcon color='action' />}
                endIcon={<ArrowRightIcon color='action' />}
                adapter={FileInfoBlankAdapter}
                originalName='Пользовательское соглашение'
              />
            </SupportTermsOfUseLinkContainer>
          </Grid>
        )}
        {hasPersonalDataPolicyFeature && (
          <Grid item>
            <SupportPdpLinkContainer underline='none'>
              <FileInfo
                color={'secondary'}
                icon={<ContentTypeDocumentIcon color='action' />}
                endIcon={<ArrowRightIcon color='action' />}
                adapter={FileInfoBlankAdapter}
                originalName='Политика обработки персональных данных ОАО «РЖД»'
              />
            </SupportPdpLinkContainer>
          </Grid>
        )}
      </Grid>
    ) : null;
  }, [isPartnerUser, hasTermsOfUseFeature, hasPersonalDataPolicyFeature]);
};

export default SupportInfoMpDocumentsAdapter;
