import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector } from '../store/selectors';
import { useCorpOfferActions } from '../useActions';
import PersonalPromotionCreateContainer from '@features/personalPromotion/create/container';
import ConfirmDialog from '@components/common/dialogs/confirm';
import { CorpOfferStateKeyDialogs } from '../store/slice';

const nameDialog: CorpOfferStateKeyDialogs = 'createCodes';

const CorpOfferActionsDialogCreateCodesAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onPromotionsCreate = useCallback(() => {
    onCloseDialog();
  }, []);

  return (
    action?.data && (
      <ConfirmDialog
        open
        onClose={onCloseDialog}
        title='Пополнение базы кодов'
      >
        <PersonalPromotionCreateContainer
          offerId={action.data.id}
          canGenerate={true}
          onCreated={onPromotionsCreate}
        />
      </ConfirmDialog>
    )
  );
};

export default CorpOfferActionsDialogCreateCodesAdapter;
