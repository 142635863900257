import styled from '@emotion/styled';
import { Grid } from '@mui/material';

interface WrapperProps {
  readonly color: 'primary' | 'secondary' | 'success' | 'warning';
}

export const Wrapper = styled.div<WrapperProps & { disabled?: boolean }>`
  width: 100%;
  min-height: 2.875rem;
  color: ${p => (!p.disabled ? p.theme.palette.primary.main : p.theme.palette.disabled.text)};
  background: ${p => (!p.disabled ? 'transparent' : p.theme.palette.disabled.background)};

  display: flex;
  align-items: center;

  border: 1px solid
    ${p =>
      p.color === 'primary' && !p.disabled
        ? p.theme.palette.primary.main
        : p.color === 'success' && !p.disabled
        ? p.theme.palette.success.main
        : p.color === 'warning' && !p.disabled
        ? p.theme.palette.warning.main
        : p.theme.palette.secondary.main};
  border-radius: ${p => p.theme.shape.borderRadius}px;

  padding: ${p => p.theme.spacing(0.5)} ${p => p.theme.spacing(1)};
  cursor: pointer;

  > svg {
    margin-right: ${p => p.theme.spacing(1)};
  }
`;

export const Content = styled(Grid)`
  flex: 1;
  overflow: hidden;

  .MuiGrid-item {
    max-width: 100%;
  }
`;

export const EndAdornmentWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-left: ${p => p.theme.spacing()};
  gap: ${p => p.theme.spacing()};
`;
