import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';
import { CorpOfferActionsConfirmDialog } from '@features/corpOffer/actions/components';
import { pluralize } from 'presentation/utils/pluralize';

const nameDialog: CorpOfferStateKeyDialogs = 'archive';

const CorpOfferActionsDialogArchiveAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const text = `Будет отправлено в архив ${pluralize(1, [
    'корпоративное предложение',
    'корпоративных предложения',
    'корпоративных предложений',
  ])}: 1`;

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onArchive = useCallback(() => {
    action?.data &&
      handlers.onArchive([action.data], action?.notification).then(() => {
        onCloseDialog();
      });
  }, [handlers.onArchive, action]);

  return (
    action?.data && (
      <CorpOfferActionsConfirmDialog
        title='Архивирование корпоративных предложений'
        actionText='Отправить в архив'
        text={text}
        onAction={onArchive}
        isFetching={isProcessing}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogArchiveAdapter;
