import { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { SportOption } from '../../../../domain/model';
import { ProductOffer } from '../../../../domain/model/productOffer';
import { Nullable } from '../../../../domain/model/types';
import { MpUser } from '../../../../domain/model/user';
import { nsiDataSelector } from '../../general/nsi/store/selectors';
import OfferReasonsDialog from '../../general/offer/components/reasonsDialog';
import { ProductArchiveDialog } from '../components/dialogToArchive';
import ProductChangeModeratorDialog from './dialogs/changeModeratorDialog';
import ProductChangePriceDialog from './dialogs/changePrice';
import ProductChangeStockDialog from './dialogs/changeStock';
import ProductPauseDialog from './dialogs/pause';
import { useContextConfig } from './hooks/useContextConfig';
import { useContextHandlers } from './hooks/useContextHandlers';
import { productsDialogsStateSelector } from './store/selectors';

const ProductTableDialogs = () => {
  const handlers = useContextHandlers();
  const { isPartnerUser } = useContextConfig();

  const dialogs = useSelector(productsDialogsStateSelector);
  const { offerRejectReasons: rejectReasons } = useSelector(nsiDataSelector);

  const [isLoading, setIsLoading] = useState(false);

  const onReject = useCallback(
    async (reason: SportOption, comment?: string) => {
      if (dialogs.reject) {
        setIsLoading(true);
        await handlers.onProductReject(dialogs.reject, reason, comment).then(() => {
          setIsLoading(false);
          handlers.onChangeDialogState('reject', null);
        });
      }
    },
    [handlers.onChangeDialogState, dialogs.reject]
  );

  const onCloseReject = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('reject', null);
  }, [handlers.onChangeDialogState]);

  const onPause = useCallback(
    async (comment: Nullable<string>) => {
      if (dialogs.pause) {
        setIsLoading(true);
        await handlers.onProductPause(dialogs.pause, comment).then(() => {
          setIsLoading(false);
          handlers.onChangeDialogState('pause', null);
        });
      }
    },
    [handlers.onChangeDialogState, dialogs.pause]
  );

  const onPauseDesk = useCallback(
    async (comment: Nullable<string>) => {
      if (dialogs.pauseDesk?.productDesk) {
        setIsLoading(true);
        await handlers.onProductDeskPause(dialogs.pauseDesk.productDesk, comment).then(() => {
          setIsLoading(false);
          handlers.onChangeDialogState('pauseDesk', null);
        });
      }
    },
    [handlers.onChangeDialogState, dialogs.pauseDesk]
  );

  const onClosePause = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('pause', null);
  }, [handlers.onChangeDialogState]);

  const onClosePauseDesk = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('pauseDesk', null);
  }, [handlers.onChangeDialogState]);

  const onCloseChangePrice = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('changePrice', null);
  }, [handlers.onChangeDialogState]);

  const onCloseChangeStock = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('changeStock', null);
  }, [handlers.onChangeDialogState]);

  const onChangeModerator = useCallback(
    async (user: MpUser) => {
      if (dialogs.changeModerator) {
        setIsLoading(true);
        await handlers.onProductModeratorChange(dialogs.changeModerator, user).then(() => {
          setIsLoading(false);
          handlers.onChangeDialogState('changeModerator', null);
        });
      }
    },
    [handlers.onChangeDialogState, dialogs.changeModerator]
  );

  const onCloseChangeModerator = useCallback(() => {
    setIsLoading(false);
    handlers.onChangeDialogState('changeModerator', null);
  }, [handlers.onChangeDialogState]);

  const onChangeProductStock = useCallback(
    async (stock: number) => {
      if (dialogs.changeStock) {
        setIsLoading(true);
        await handlers.onProductStockChange(dialogs.changeStock, stock).then(() => {
          setIsLoading(false);
          handlers.onChangeDialogState('changeStock', null);
        });
      }
    },
    [handlers.onChangeDialogState, dialogs.changeStock]
  );

  const onChangeProductPrice = useCallback(
    async (price: number, originalPrice: ProductOffer['originalPrice']) => {
      if (dialogs.changePrice) {
        setIsLoading(true);
        await handlers.onProductPriceChange(dialogs.changePrice, price, originalPrice).then(() => {
          setIsLoading(false);
          handlers.onChangeDialogState('changePrice', null);
        });
      }
    },
    [handlers.onChangeDialogState, dialogs.changePrice]
  );

  const handleCloseToArchive = useCallback(() => {
    handlers.onChangeDialogState('archive', null);
  }, [handlers.onChangeDialogState]);

  const handleSubmitToArchive = useCallback(async () => {
    if (dialogs.archive) {
      await handlers.onProductArchive(dialogs.archive);
      handleCloseToArchive();
    }
  }, [handleCloseToArchive, dialogs.archive]);

  const handleCloseToArchiveDesk = useCallback(() => {
    handlers.onChangeDialogState('archiveDesk', null);
  }, [handlers.onChangeDialogState]);

  const handleSubmitToArchiveDesk = useCallback(async () => {
    if (dialogs.archiveDesk?.productDesk) {
      await handlers.onProductDeskArchive(dialogs.archiveDesk.productDesk);
      handleCloseToArchiveDesk();
    }
  }, [handleCloseToArchiveDesk, dialogs.archiveDesk?.productDesk]);

  return (
    <>
      {dialogs.reject && (
        <OfferReasonsDialog
          title='Отклонение товарного предложения'
          actionText='Отклонить'
          isFetching={isLoading}
          reasons={rejectReasons}
          onAction={onReject}
          onClose={onCloseReject}
        />
      )}
      {dialogs.pause && (
        <ProductPauseDialog
          productOffer={dialogs.pause}
          pauseWithComment={!isPartnerUser}
          pauseDesk={false}
          isExecuting={isLoading}
          onExecute={onPause}
          onClose={onClosePause}
        />
      )}
      {dialogs.pauseDesk && (
        <ProductPauseDialog
          productOffer={dialogs.pauseDesk}
          pauseWithComment={!isPartnerUser}
          pauseDesk={true}
          isExecuting={isLoading}
          onExecute={onPauseDesk}
          onClose={onClosePauseDesk}
        />
      )}
      {dialogs.changeModerator && (
        <ProductChangeModeratorDialog
          productName={dialogs.changeModerator.name}
          approvingAdmin={dialogs.changeModerator.moderator}
          isExecuting={isLoading}
          onExecute={onChangeModerator}
          onClose={onCloseChangeModerator}
        />
      )}
      {dialogs.changeStock && (
        <ProductChangeStockDialog
          productOffer={dialogs.changeStock}
          isExecuting={isLoading}
          onExecute={onChangeProductStock}
          onClose={onCloseChangeStock}
        />
      )}
      {dialogs.changePrice && (
        <ProductChangePriceDialog
          productOffer={dialogs.changePrice}
          isExecuting={isLoading}
          onExecute={onChangeProductPrice}
          onClose={onCloseChangePrice}
        />
      )}

      {dialogs.archive && (
        <ProductArchiveDialog
          open={true}
          onClose={handleCloseToArchive}
          onSubmit={handleSubmitToArchive}
          product={dialogs.archive}
          isProductVariant={true}
        />
      )}

      {dialogs.archiveDesk && (
        <ProductArchiveDialog
          open={true}
          onClose={handleCloseToArchiveDesk}
          onSubmit={handleSubmitToArchiveDesk}
          product={dialogs.archiveDesk}
          isProductVariant={false}
        />
      )}
    </>
  );
};

export default ProductTableDialogs;
