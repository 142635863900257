import { UserAccessMatrix } from 'domain/model/accessMatrix';
import { EOrderPartition, EPartnerStatus } from 'domain/model/enums';
import { Partner } from 'domain/model/partner';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { commonMenu } from '../../../../menu';
import { MenuPanelItem, MenuPanelLinkLocationState } from '../../../../types';
import { getMenuItemLocation, menuPanelItemIsSelected } from '../../../general/menu/utils';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import {
  getPartnerManagementAdCampaignsRoute,
  getPartnerManagementBookingOffersRoute,
  getPartnerManagementCorpActivationsRoute,
  getPartnerManagementCorpOffersRoute,
  getPartnerManagementDetailsRoute,
  getPartnerManagementEmployeesRoute,
  getPartnerManagementOrdersRoute,
  getPartnerManagementProductsRoute,
  getPartnerManagementQueryAnalyticsRoute,
  getPartnerManagementReportsRoute,
  getPartnerManagementTradeActivationsRoute,
  getPartnerManagementTradeOffersRoute,
  getPartnerManagementWindowRoute,
} from '../entry';
import { partnerManagementMenuOpenedItemsSelector } from './store/selectors';
import { partnerManagementMenuAddToOpened } from './store/slice';

const getMenu = (partner: Partner, accessMatrix: UserAccessMatrix): MenuPanelItem[] => {
  const items: MenuPanelItem[] = [];
  const {
    productOffers,
    tradeOffers,
    corpOffers,
    bookingOffers,
    orders,
    corpActivations,
    tradeActivations,
    partnerEmployees,
    reports,
    partnerWindow,
    analyticsQueries,
    adCampaigns,
    partners,
  } = accessMatrix;

  const offersSubMenu: MenuPanelItem[] = [];
  if (corpOffers?.view) {
    offersSubMenu.push(commonMenu.corpOffers(getPartnerManagementCorpOffersRoute({ id: partner.id })));
  }
  if (tradeOffers?.view) {
    offersSubMenu.push(commonMenu.tradeOffers(getPartnerManagementTradeOffersRoute({ id: partner.id })));
  }
  if (productOffers?.view) {
    offersSubMenu.push(commonMenu.productOffers(getPartnerManagementProductsRoute({ id: partner.id })));
  }
  if (bookingOffers?.view) {
    offersSubMenu.push(commonMenu.bookingOffers(getPartnerManagementBookingOffersRoute({ id: partner.id })));
  }
  if (offersSubMenu.length > 0) {
    items.push({
      ...commonMenu.offers,
      subMenu: offersSubMenu,
    });
  }

  const activationsSubMenu: MenuPanelItem[] = [];
  if (corpActivations.view) {
    activationsSubMenu.push(
      commonMenu.corpActivations(
        getPartnerManagementCorpActivationsRoute({
          id: partner.id,
          guid: uuidv4(),
        })
      )
    );
  }
  if (tradeActivations.view) {
    activationsSubMenu.push(
      commonMenu.tradeActivations(
        getPartnerManagementTradeActivationsRoute({
          id: partner.id,
          guid: uuidv4(),
        })
      )
    );
  }
  if (activationsSubMenu.length > 0) {
    items.push({
      ...commonMenu.activations,
      subMenu: activationsSubMenu,
    });
  }

  const ordersSubMenu: MenuPanelItem[] = [];
  if (orders?.[EOrderPartition.ProductOffers].view) {
    ordersSubMenu.push(
      commonMenu.productOfferOrders(
        getPartnerManagementOrdersRoute({
          id: partner.id,
          guid: uuidv4(),
          partition: EOrderPartition.ProductOffers,
        })
      )
    );
  }
  if (orders?.[EOrderPartition.BookingOffers].view) {
    ordersSubMenu.push(
      commonMenu.bookingOfferOrders(
        getPartnerManagementOrdersRoute({
          id: partner.id,
          guid: uuidv4(),
          partition: EOrderPartition.BookingOffers,
        })
      )
    );
  }
  if (ordersSubMenu.length > 0) {
    items.push({
      ...commonMenu.orders,
      subMenu: ordersSubMenu,
    });
  }

  const adsSubMenu: MenuPanelItem[] = [];
  if (partnerWindow?.view) {
    adsSubMenu.push(commonMenu.partnerWindow(getPartnerManagementWindowRoute({ id: partner.id })));
  }
  if (adCampaigns.view) {
    adsSubMenu.push(
      commonMenu.adCampaigns(
        getPartnerManagementAdCampaignsRoute({
          id: partner.id,
          guid: uuidv4(),
        })
      )
    );
  }
  if (adsSubMenu.length > 0) {
    items.push({
      ...commonMenu.ads,
      subMenu: adsSubMenu,
    });
  }

  const analyticsSubMenu: MenuPanelItem[] = [];
  if (reports?.view) {
    analyticsSubMenu.push(commonMenu.reports(getPartnerManagementReportsRoute({ id: partner.id })));
  }
  if (analyticsQueries?.view) {
    analyticsSubMenu.push(commonMenu.queryAnalytics(getPartnerManagementQueryAnalyticsRoute({ id: partner.id })));
  }
  if (analyticsSubMenu.length > 0) {
    items.push({
      ...commonMenu.analytics,
      subMenu: analyticsSubMenu,
    });
  }

  const companySubMenu: MenuPanelItem[] = [];
  if (partners?.view) {
    companySubMenu.push({
      ...commonMenu.partnerCompany(partner.id),
      link: getPartnerManagementDetailsRoute({ id: partner.id }),
    });
  }
  if (partnerEmployees?.view) {
    companySubMenu.push(commonMenu.partnerEmployees(getPartnerManagementEmployeesRoute({ id: partner.id })));
  }
  if (companySubMenu.length > 0) {
    items.push({
      ...commonMenu.company(partner.status === EPartnerStatus.Rejected),
      subMenu: companySubMenu,
    });
  }

  return items;
};

interface UsePartnerManagementMenuItemsProps {
  readonly partner: Partner;
}

interface UsePartnerManagementMenuItems {
  readonly items: MenuPanelItem[];
  readonly isSelectedItem: (item: MenuPanelItem) => boolean;
  readonly isOpenedSubMenu: (item: MenuPanelItem) => boolean;
  readonly onItemClick: (item: MenuPanelItem) => void;
}

const usePartnerManagementMenuItems = ({
  partner,
}: UsePartnerManagementMenuItemsProps): UsePartnerManagementMenuItems => {
  const location = useLocation();
  const history = useHistory<MenuPanelLinkLocationState>();
  const dispatch = useDispatch();

  const { accessMatrix } = useCurrentUser();

  const opened = useSelector(partnerManagementMenuOpenedItemsSelector);

  const isSelectedItem = useCallback((item: MenuPanelItem) => menuPanelItemIsSelected(item, location), [location]);

  const isOpenedSubMenu = useCallback(
    (item: MenuPanelItem): boolean => {
      return opened[item.id] || (item.subMenu?.some(subItem => isSelectedItem(subItem)) ?? false);
    },
    [isSelectedItem, opened]
  );

  const onItemClick = useCallback(
    (item: MenuPanelItem) => {
      if (item.subMenu) {
        dispatch(partnerManagementMenuAddToOpened({ itemId: item.id }));
      } else {
        history.replace(getMenuItemLocation(item));
      }
    },
    [dispatch, history]
  );

  const items = useMemo<MenuPanelItem[]>(() => getMenu(partner, accessMatrix), [partner, accessMatrix]);

  return { items, isSelectedItem, isOpenedSubMenu, onItemClick };
};

export default usePartnerManagementMenuItems;
