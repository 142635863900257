import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import { AppThunkAPIConfig } from '../../../../../data/store/store';
import { Fetchable, fetchableDefault } from '../../../../../data/store/types';
import { BookingOrder } from '../../../../../domain/model/order';
import { Nullable, UUID } from '../../../../../domain/model/types';
import bookingOrderServices from '../../services';
import { BookingOrderItemView } from '../../types';

export interface BookingOrderDetailsState {
  readonly byId: Fetchable & {
    readonly bookingOrder: Nullable<BookingOrder>;
    readonly orderItems: Nullable<BookingOrderItemView[]>;
  };
  readonly needRefreshWatcher: number;
  readonly dialogs: {
    readonly history: Nullable<BookingOrder>;
  };
}

export const bookingOrderDetailsByIdFetch = createAsyncThunk<BookingOrder, { id: UUID }, AppThunkAPIConfig>(
  'bookingOrder/details/fetch',
  async ({ id }, { rejectWithValue, signal }) => {
    try {
      return await bookingOrderServices.order.one({ id, signal });
    } catch (e: any) {
      ErrorHandler.handleHttpError(e, e.response);
      return rejectWithValue(e.response.data);
    }
  }
);

type Reducer<T = undefined> = CaseReducer<BookingOrderDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOrderDetailsState> {
  bookingOrderDetailsStateReset: Reducer;
  bookingOrderDetailsNeedRefreshWatcherIncrement: Reducer;
  bookingOrderDetailsSetDialogState: Reducer<{
    name: keyof BookingOrderDetailsState['dialogs'];
    data: Nullable<BookingOrder>;
  }>;
}

const slice = createSlice<BookingOrderDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      bookingOrder: null,
      orderItems: null,
    },
    needRefreshWatcher: 0,
    dialogs: {
      history: null,
    },
  },
  reducers: {
    bookingOrderDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        bookingOrder: null,
        orderItems: null,
      };
    },
    bookingOrderDetailsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    bookingOrderDetailsSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOrderDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.bookingOrder = null;
      })
      .addCase(bookingOrderDetailsByIdFetch.fulfilled, (state, { payload }) => {
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.bookingOrder = payload;
        state.byId.orderItems =
          payload.orderItems?.map(orderItem => ({
            ...orderItem,
            internalId: orderItem.id,
          })) ?? null;
      })
      .addCase(bookingOrderDetailsByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.bookingOrder = null;
      });
  },
});

export const {
  bookingOrderDetailsStateReset,
  bookingOrderDetailsSetDialogState,
  bookingOrderDetailsSetUiState,
  bookingOrderDetailsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
