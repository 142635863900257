import { ApiCancellable, ApiQueryDsl, ApiRequestListDiscriminator, ApiRequestPageable } from '@/data/api/types';
import { getPageableFromResponseHeaders, getQueryDslByDataFilterValues } from '@/data/api/utils';
import {
  ApprovalOfferRegistry,
  ApprovalOfferRegistryRequest,
  CorpOffer,
  ECorpOffersCollectionAction,
  EOfferStatus,
  Nullable,
  Pageable,
  PartnerResponsiblePerson,
  PartnerResponsiblePersonRequest,
  SportOption,
  UUID,
  XLSXImportResult,
} from '@/domain';
import Axios from 'axios';
import Api from '../../../../../data/api';
import { PaginationSize } from '../../../../types';
import { convertTargetToTargetRequest } from '../../../../utils/target';
import approvalRegistryServices from '../../../approvalRegistry/services';
import { CorpOfferTableFilterValues, ECorpOfferTableFilterItem } from '../../../corpOffer/filterUtils';
import { isCorpOfferDraftSavable } from '../../../corpOffer/utils';
import { EOfferTableTab, OfferCounterByStatus } from '../types';
import { getStatesFilterForOfferTableTab, OfferTableTabsCounter } from '../utils/table';

type OneProps = ApiCancellable & {
  readonly id: UUID;
};

export type AllProps = ApiCancellable & {
  readonly search: {
    readonly pageSize: PaginationSize;
    readonly sort: Nullable<string>;
    readonly statuses: EOfferStatus[];
    readonly partnerId: Nullable<UUID>;
    readonly categories: Nullable<UUID[]>;
  };
  readonly filter: CorpOfferTableFilterValues;
  readonly pageNumber: number;
};

type BuildListQueryParamsReturn<D extends ApiRequestListDiscriminator> = ApiCancellable &
  ApiRequestPageable & {
    readonly partnerId: Nullable<UUID>;
    readonly statuses?: Nullable<EOfferStatus[]>;
    readonly categories?: Nullable<UUID[]>;
    readonly query?: Nullable<string>;
    readonly querydsl?: Nullable<ApiQueryDsl>;
  };

type SaveProps = CorpOffer;

type ApplyDataProps = {
  readonly data: CorpOffer;
  readonly approvalRegistry?: Nullable<ApprovalOfferRegistryRequest>;
  readonly responsiblePerson?: Nullable<PartnerResponsiblePersonRequest>;
};

type ChangeApprovalRegistryProps = {
  readonly offerId: UUID;
  readonly approvalRegistry: ApprovalOfferRegistryRequest;
};

type UnwatchedProps = Omit<AllProps, 'pageNumber'>;

type CommandProps = {
  readonly id: UUID;
};

type InWorkProps = CommandProps & {
  readonly userId: UUID;
};

type InWorkCollectionProps = CommandCollectionProps & {
  readonly userId: UUID;
};

type RejectProps = CommandProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type RejectCollectionProps = CommandCollectionProps & {
  readonly reason: SportOption;
  readonly comment?: string;
};

type ImportProps = CommandProps & {
  readonly file: File;
};

type CommandCollectionProps = {
  readonly ids: UUID[];
};

type ChangeApprovalRegistryCollectionProps = CommandCollectionProps & {
  readonly approvalRegistry: ApprovalOfferRegistryRequest;
};

type PauseProps = CommandProps & {
  readonly reason?: SportOption;
  readonly comment?: string;
};

type PauseCollectionProps = CommandCollectionProps & {
  readonly reason?: SportOption;
  readonly comment?: string;
};

type CountsByStatusesProps = Omit<AllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

type DuplicateProps = {
  readonly id: UUID;
};

export type CountProps = Omit<AllProps, 'pageNumber'> & {
  readonly viewed?: boolean;
};

type CountsByTabsProps = CountsByStatusesProps & {
  readonly tabs: EOfferTableTab[];
};

export type CorpOfferService = {
  readonly buildListQueryParams: <D extends ApiRequestListDiscriminator = ApiRequestListDiscriminator.List>(
    props: AllProps
  ) => BuildListQueryParamsReturn<D>;
  readonly one: (props: OneProps) => Promise<CorpOffer>;
  readonly all: (props: AllProps) => Promise<Pageable<CorpOffer>>;
  readonly unwatched: (props: UnwatchedProps) => Promise<CorpOffer[]>;
  readonly count: (props: CountProps) => Promise<number>;
  readonly countsByStatuses: (
    props: CountsByStatusesProps
  ) => Promise<{ counts: OfferCounterByStatus; errors: Array<string> }>;
  readonly countsByTabs: (
    props: CountsByTabsProps
  ) => Promise<{ counts: OfferTableTabsCounter; errors: Array<string> }>;
  readonly delete: (props: CommandProps) => Promise<void>;
  readonly approve: (props: CommandProps) => Promise<CorpOffer>;
  readonly resume: (props: CommandProps) => Promise<CorpOffer>;
  readonly pause: (props: PauseProps) => Promise<CorpOffer>;
  readonly archive: (props: CommandProps) => Promise<CorpOffer>;
  readonly inWork: (props: InWorkProps) => Promise<CorpOffer>;
  readonly reject: (props: RejectProps) => Promise<CorpOffer>;
  readonly publish: (props: CommandProps) => Promise<CorpOffer>;
  readonly unPublish: (props: CommandProps) => Promise<CorpOffer>;
  readonly retrieve: (props: CommandProps) => Promise<CorpOffer>;
  readonly save: (props: SaveProps) => Promise<CorpOffer>;
  readonly changeApprovalRegistry: (props: ChangeApprovalRegistryProps) => Promise<Nullable<ApprovalOfferRegistry>>;
  readonly applyData: (props: ApplyDataProps) => Promise<CorpOffer>;
  readonly import: (props: ImportProps) => Promise<XLSXImportResult>;
  readonly duplicate: (props: DuplicateProps) => Promise<CorpOffer>;
  /** @deprecated переместить логику в approve, а approveCollection удалить */
  readonly approveCollection: (props: CommandCollectionProps) => Promise<boolean>;
  /** @deprecated переместить логику в inWork, а inWorkCollection удалить */
  readonly inWorkCollection: (props: InWorkCollectionProps) => Promise<boolean>;
  /** @deprecated переместить логику в archive, а archiveCollection удалить */
  readonly archiveCollection: (props: CommandCollectionProps) => Promise<boolean>;
  /** @deprecated переместить логику в pause, а pauseCollection удалить */
  readonly pauseCollection: (props: PauseCollectionProps) => Promise<boolean>;
  /** @deprecated переместить логику в reject, а rejectCollection удалить */
  readonly rejectCollection: (props: RejectCollectionProps) => Promise<boolean>;
  /** @deprecated переместить логику в reject, а resumeCollection удалить */
  readonly resumeCollection: (props: CommandCollectionProps) => Promise<boolean>;
  /** @deprecated переместить логику в changeApprovalRegistry, а changeApprovalRegistryCollection удалить */
  readonly changeApprovalRegistryCollection: (
    props: ChangeApprovalRegistryCollectionProps
  ) => Promise<ApprovalOfferRegistry>;
};

const service: CorpOfferService = {
  one: async ({ id, signal }) => {
    return (await Api.corpOffer.one({ id, signal })).data;
  },
  buildListQueryParams: props => {
    const { search, filter, pageNumber, signal } = props;
    const { pageSize, sort, statuses, categories, partnerId } = search;

    const query = filter[ECorpOfferTableFilterItem.Query]?.value;
    const querydsl = getQueryDslByDataFilterValues(filter);
    const target = filter[ECorpOfferTableFilterItem.Target]?.value;

    return {
      query,
      sort,
      signal,
      pageSize,
      partnerId,
      statuses,
      categories,
      page: pageNumber,
      querydsl,
      target: target && convertTargetToTargetRequest(target),
    };
  },
  all: async props => {
    const response = await Api.corpOffer.all(service.buildListQueryParams(props));
    const { pageCount, totalCount, page } = getPageableFromResponseHeaders(response);

    return { data: response.data, totalCount, pageCount, pageNumber: page };
  },
  unwatched: async props => {
    const { data: corpOffers } = await Api.corpOffer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      pageSize: 99999,
      viewed: false,
    });
    return corpOffers;
  },
  count: async ({ viewed, ...props }) => {
    const { data: response } = await Api.corpOffer.all({
      ...service.buildListQueryParams({ ...props, pageNumber: 1 }),
      discriminator: ApiRequestListDiscriminator.Count,
      viewed,
    });

    return response[0].count;
  },
  countsByStatuses: async ({ viewed, signal, ...props }) => {
    const {
      search: { statuses },
    } = props;

    const errors: string[] = [];
    const counts: OfferCounterByStatus = {};

    const requests = statuses.map(status => {
      const params = {
        ...props,
        search: { ...props.search, statuses: [status] },
      };
      return service.count({ ...params, viewed, signal });
    });

    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], status: EOfferStatus) => {
      if (response.status === 'fulfilled') {
        counts[status] = response.value;
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество корпоративных предложений '${status}': ${response.reason}`);
        }
      }
    };
    statuses.forEach((status, index) => parseResponse(responses[index], status));

    return { counts, errors };
  },
  countsByTabs: async ({ tabs, signal, ...props }) => {
    const errors: string[] = [];
    const counts: OfferTableTabsCounter = {};

    const requests = tabs.map(tab => {
      const statuses = getStatesFilterForOfferTableTab(tab, []);
      const params = {
        ...props,
        search: { ...props.search, statuses },
      };
      return service.countsByStatuses({ ...params, signal });
    });
    const responses = await Promise.allSettled(requests);

    const parseResponse = (response: (typeof responses)[0], tab: EOfferTableTab) => {
      if (response.status === 'fulfilled') {
        const statuses = getStatesFilterForOfferTableTab(tab, []);
        counts[tab] = statuses.reduce<number>((prev, current) => prev + (response.value.counts?.[current] ?? 0), 0);
      } else {
        if (!(response.reason instanceof Axios.Cancel)) {
          errors.push(`Не удалось получить количество корпоративных предложений '${tab}': ${response.reason}`);
        }
      }
    };
    tabs.forEach((tab, index) => parseResponse(responses[index], tab));

    return { counts, errors };
  },
  delete: async ({ id }) => {
    await Api.corpOffer.delete({ id });
  },
  resume: async ({ id }) => {
    return (await Api.corpOffer.resume({ id })).data;
  },
  approve: async ({ id }) => {
    return (await Api.corpOffer.approve({ id })).data;
  },
  pause: async ({ id, comment, reason }) => {
    return (await Api.corpOffer.pause({ id, type: reason, comment })).data;
  },
  archive: async ({ id }) => {
    return (await Api.corpOffer.archive({ id })).data;
  },
  inWork: async ({ id, userId }) => {
    return (await Api.corpOffer.inWork({ id, userId })).data;
  },
  reject: async ({ id, reason, comment }) => {
    return (await Api.corpOffer.reject({ id, rejectId: reason.id, comment })).data;
  },
  publish: async ({ id }) => {
    return (await Api.corpOffer.publish({ id })).data;
  },
  unPublish: async ({ id }) => {
    return (await Api.corpOffer.unPublish({ id })).data;
  },
  retrieve: async ({ id }) => {
    return (await Api.corpOffer.retrieve({ id })).data;
  },
  save: async data => {
    const { id, status } = data;

    if (id) {
      if (isCorpOfferDraftSavable(status)) {
        return (await Api.corpOffer.saveUnpublished({ id, data })).data;
      } else {
        return (await Api.corpOffer.save({ id, data })).data;
      }
    } else {
      return (await Api.corpOffer.create({ data })).data;
    }
  },
  changeApprovalRegistry: async ({ offerId, approvalRegistry }) => {
    let corpOfferToSave = await service.one({ id: offerId });

    let savedApprovalRegistry: Nullable<ApprovalOfferRegistry>;
    if (approvalRegistry.number) {
      savedApprovalRegistry = await approvalRegistryServices.offer.getOrCreate({ data: approvalRegistry });
    } else {
      savedApprovalRegistry = null;
    }

    corpOfferToSave = {
      ...corpOfferToSave,
      approvalRegistry: savedApprovalRegistry,
    };

    await service.save(corpOfferToSave);

    if (savedApprovalRegistry) {
      savedApprovalRegistry = await approvalRegistryServices.offer.one({ id: savedApprovalRegistry.id });
    }

    return savedApprovalRegistry;
  },
  import: async ({ id, file }) => {
    return (await Api.corpOffer.import({ id, file })).data;
  },
  applyData: async ({ data, approvalRegistry, responsiblePerson }) => {
    let result = data;

    if (approvalRegistry) {
      let savedApprovalRegistry: Nullable<ApprovalOfferRegistry> = null;
      if (approvalRegistry.number) {
        savedApprovalRegistry = await approvalRegistryServices.offer.getOrCreate({ data: approvalRegistry });
      }
      result = {
        ...result,
        approvalRegistry: savedApprovalRegistry,
      };
    }

    const partnerId = data.partnerId;
    if (responsiblePerson && partnerId) {
      let savedResponsiblePerson: Nullable<PartnerResponsiblePerson> = null;
      if (responsiblePerson?.fullName && partnerId) {
        savedResponsiblePerson = (
          await Api.partner.responsiblePerson.getOrCreate({
            ...responsiblePerson,
            partnerId,
          })
        ).data;
      }
      result = {
        ...result,
        responsiblePerson: savedResponsiblePerson,
      };
      if (savedResponsiblePerson && result.storeResponsiblePerson?.responsiblePersonLink) {
        result = {
          ...result,
          storeResponsiblePerson: {
            ...result.storeResponsiblePerson,
            responsiblePersonLink: { id: savedResponsiblePerson.id },
          },
        };
      }
    }

    return await service.save(result);
  },
  duplicate: async ({ id }) => {
    return (await Api.corpOffer.duplicate({ id })).data;
  },
  approveCollection: async ({ ids }) => {
    await Api.corpOffer.collectionAction({
      discriminator: ECorpOffersCollectionAction.CorpOffersApproveCommand,
      corpOfferIds: ids,
    });
    return true;
  },
  changeApprovalRegistryCollection: async ({ ids, approvalRegistry }) => {
    const savedApprovalRegistry = await approvalRegistryServices.offer.getOrCreate({ data: approvalRegistry });
    const offerLinks = ids.map(id => ({ id }));
    return await approvalRegistryServices.offer.setOffers({
      id: savedApprovalRegistry.id,
      offers: offerLinks,
    });
  },
  inWorkCollection: async ({ ids, userId }) => {
    await Api.corpOffer.collectionAction({
      discriminator: ECorpOffersCollectionAction.CorpOffersToWorkCommand,
      corpOfferIds: ids,
      userId,
    });
    return true;
  },
  archiveCollection: async ({ ids }) => {
    await Api.corpOffer.collectionAction({
      discriminator: ECorpOffersCollectionAction.CorpOffersArchiveCommand,
      corpOfferIds: ids,
    });
    return true;
  },
  pauseCollection: async ({ ids, reason, comment }) => {
    await Api.corpOffer.collectionAction({
      discriminator: ECorpOffersCollectionAction.CorpOffersPauseCommand,
      corpOfferIds: ids,
      reasonId: reason?.id,
      comment,
    });
    return true;
  },
  rejectCollection: async ({ ids, reason, comment }) => {
    await Api.corpOffer.collectionAction({
      discriminator: ECorpOffersCollectionAction.CorpOffersRejectCommand,
      corpOfferIds: ids,
      rejectId: reason.id,
      comment,
    });
    return true;
  },
  resumeCollection: async ({ ids }) => {
    await Api.corpOffer.collectionAction({
      discriminator: ECorpOffersCollectionAction.CorpOffersRenewCommand,
      corpOfferIds: ids,
    });
    return true;
  },
};

export default service;
