import { CorpOffer, EOfferStatus, ESortDirection, Nullable } from '@/domain';
import { addSearchParamToLocation } from '@/routing/globalRouting';
import { useCorpOfferActions } from '@features/corpOffer/actions/useActions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import store from '../../../../../data/store/store';
import { PaginationSize } from '../../../../types';
import { getDataFilterValuesByStrategies } from '../../../../utils/filtering';
import { EOfferTableTab } from '../../../general/offer/types';
import useCurrentUser from '../../../user/hooks/useCurrentUser';
import { ECorpOfferUrlParam } from '../../entry';
import { CorpOfferTableFilterEditStrategy } from '../../filterUtils';
import { corpOffersGuidSelector } from '../store/selectors';
import {
  corpOffersAllSelect,
  corpOffersAllUnselect,
  corpOffersClearNotifierInfo,
  corpOffersDataReset,
  corpOffersDeleteCorpOffer,
  corpOffersDuplicate,
  corpOffersNeedRefreshWatcherIncrement,
  corpOffersRetrieveCorpOffer,
  corpOffersSelect,
  corpOffersSetFilter,
  corpOffersSetPage,
  corpOffersSetPageSize,
  corpOffersSetSort,
  corpOffersSortReset,
  corpOffersUnselect,
} from '../store/slice';

export type UseCorpOffersTable = {
  readonly onRefresh: () => void;
  readonly onCorpOfferSelect: (corpOffer: CorpOffer, selected: boolean) => void;
  readonly onAllCorpOffersSelect: (selected: boolean) => void;
  readonly onChangeTab: (newTab: EOfferTableTab) => void;
  readonly onChangeFilter: (strategies: CorpOfferTableFilterEditStrategy[]) => void;
  readonly onNotificationRead: () => void;
  readonly onChangeSort: (name: string, direction: ESortDirection) => void;
  readonly onResetSort: () => void;
  readonly isCorpOfferNotModeratedByMe: (corpOffer: CorpOffer) => boolean;
  readonly onCorpOfferReject: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferPause: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferArchive: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferRetrieve: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOfferDelete: (corpOffer: CorpOffer) => Promise<boolean>;
  readonly onCorpOfferResume: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferApprove: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferChangeModerator: (corpOffer: CorpOffer) => void;
  readonly onCorpOfferChangeApprovalRegistry: (corpOffer: CorpOffer) => void;
  readonly onCorpOffersChangePage: (page: number) => void;
  readonly onCorpOffersChangePageSize: (pageSize: PaginationSize) => void;
  readonly onCorpOfferDuplicate: (corpOffer: CorpOffer) => Promise<Nullable<CorpOffer>>;

  readonly onCorpOffersApprove: (corpOffers: CorpOffer[]) => void;
  readonly onCorpOffersChangeModerator: (corpOffers: CorpOffer[]) => void;
  readonly onCorpOffersChangeApprovalRegistry: (corpOffers: CorpOffer[]) => void;
  readonly onCorpOffersArchive: (corpOffers: CorpOffer[]) => void;
  readonly onCorpOffersReject: (corpOffers: CorpOffer[]) => void;
  readonly onCorpOffersPause: (corpOffers: CorpOffer[]) => void;
  readonly onCorpOffersResume: (corpOffers: CorpOffer[]) => void;
};

type UseCorpOffersTableHandlersProps = {
  readonly tab: EOfferTableTab;
};

export function useCorpOffersTableHandlers({ tab }: UseCorpOffersTableHandlersProps): UseCorpOffersTable {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const {
    onTryApprove,
    onTryApproveCollection,
    onTryChangeApprovalRegistry,
    onTryChangeApprovalRegistryCollection,
    onTryChangeModerator,
    onTryChangeModeratorCollection,
    onTryArchive,
    onTryArchiveCollection,
    onTryReject,
    onTryRejectCollection,
    onTryPause,
    onTryPauseCollection,
    onTryResume,
    onTryResumeCollection,
  } = useCorpOfferActions();

  const {
    userSpecific: { id: userId },
    accessMatrix,
  } = useCurrentUser();

  const getCorpOffersGuidFromState = useCallback((): Nullable<UUID> => {
    const state = store.getState();
    return corpOffersGuidSelector(state);
  }, [store]);

  const onRefresh = useCallback<UseCorpOffersTable['onRefresh']>(
    () => dispatch(corpOffersNeedRefreshWatcherIncrement()),
    [dispatch]
  );

  const onCorpOfferSelect = useCallback<UseCorpOffersTable['onCorpOfferSelect']>(
    (corpOffer, selected) => {
      if (selected) {
        dispatch(corpOffersSelect(corpOffer));
      } else {
        dispatch(corpOffersUnselect(corpOffer));
      }
    },
    [dispatch]
  );

  const onAllCorpOffersSelect = useCallback<UseCorpOffersTable['onAllCorpOffersSelect']>(
    selected => {
      if (selected) {
        dispatch(corpOffersAllSelect());
      } else {
        dispatch(corpOffersAllUnselect());
      }
    },
    [dispatch]
  );

  const onChangeFilter = useCallback<UseCorpOffersTable['onChangeFilter']>(
    strategies => {
      dispatch(corpOffersSetFilter(getDataFilterValuesByStrategies(strategies)));
    },
    [dispatch]
  );

  const onNotificationRead = useCallback<UseCorpOffersTable['onNotificationRead']>(() => {
    dispatch(corpOffersClearNotifierInfo());
  }, [dispatch]);

  const onCorpOfferChangeApprovalRegistry = useCallback<UseCorpOffersTable['onCorpOfferChangeApprovalRegistry']>(
    corpOffer => {
      onTryChangeApprovalRegistry({ data: corpOffer });
    },
    [dispatch, onTryChangeApprovalRegistry]
  );

  const onCorpOffersChangeApprovalRegistry = useCallback<UseCorpOffersTable['onCorpOffersChangeApprovalRegistry']>(
    corpOffers => {
      onTryChangeApprovalRegistryCollection({ data: corpOffers });
    },
    [dispatch, onTryChangeApprovalRegistryCollection]
  );

  const onChangeSort = useCallback<UseCorpOffersTable['onChangeSort']>(
    (name: string, direction: ESortDirection) => {
      dispatch(
        corpOffersSetSort({
          sort: `${name},${direction}`,
        })
      );
    },
    [dispatch]
  );

  const onResetSort = useCallback<UseCorpOffersTable['onResetSort']>(() => dispatch(corpOffersSortReset()), [dispatch]);

  const isCorpOfferNotModeratedByMe = useCallback<UseCorpOffersTable['isCorpOfferNotModeratedByMe']>(
    corpOffer => {
      return (
        !!accessMatrix.corpOffers.moderate &&
        corpOffer.status === EOfferStatus.OnModeration &&
        corpOffer.approvingAdmin?.id !== userId
      );
    },
    [userId, accessMatrix.corpOffers.moderate]
  );

  const onCorpOfferReject = useCallback<UseCorpOffersTable['onCorpOfferReject']>(
    async corpOffer => {
      onTryReject({ data: corpOffer });
    },
    [dispatch]
  );

  const onCorpOffersReject = useCallback<UseCorpOffersTable['onCorpOffersReject']>(
    async corpOffers => {
      onTryRejectCollection({ data: corpOffers });
    },
    [dispatch]
  );

  const onCorpOfferPause = useCallback<UseCorpOffersTable['onCorpOfferPause']>(
    async corpOffer => {
      onTryPause({ data: corpOffer });
    },
    [dispatch]
  );

  const onCorpOffersPause = useCallback<UseCorpOffersTable['onCorpOffersPause']>(
    async corpOffers => {
      onTryPauseCollection({ data: corpOffers });
    },
    [dispatch]
  );

  const onCorpOfferArchive = useCallback<UseCorpOffersTable['onCorpOfferArchive']>(
    corpOffer => {
      onTryArchive({ data: corpOffer });
    },
    [dispatch]
  );

  const onCorpOffersArchive = useCallback<UseCorpOffersTable['onCorpOffersArchive']>(
    corpOffers => {
      onTryArchiveCollection({ data: corpOffers });
    },
    [dispatch]
  );

  const onCorpOfferRetrieve = useCallback<UseCorpOffersTable['onCorpOfferRetrieve']>(
    async ({ id }) => {
      const response = await dispatch(corpOffersRetrieveCorpOffer({ id })).unwrap();
      return !!response?.id;
    },
    [dispatch]
  );

  const onCorpOfferDelete = useCallback<UseCorpOffersTable['onCorpOfferDelete']>(
    async ({ id }) => {
      await dispatch(corpOffersDeleteCorpOffer({ id })).unwrap();
      return true;
    },
    [dispatch]
  );

  const onCorpOfferResume = useCallback<UseCorpOffersTable['onCorpOfferResume']>(
    corpOffer => {
      onTryResume({ data: corpOffer });
    },
    [dispatch]
  );

  const onCorpOffersResume = useCallback<UseCorpOffersTable['onCorpOffersResume']>(
    async corpOffers => {
      onTryResumeCollection({ data: corpOffers });
    },
    [dispatch]
  );

  const onCorpOfferApprove = useCallback<UseCorpOffersTable['onCorpOfferApprove']>(
    corpOffer => {
      onTryApprove({ data: corpOffer });
    },
    [dispatch, onTryApprove]
  );

  const onCorpOffersApprove = useCallback<UseCorpOffersTable['onCorpOffersApprove']>(
    corpOffers => {
      onTryApproveCollection({ data: corpOffers });
    },
    [dispatch, onTryApproveCollection]
  );

  const onCorpOfferChangeModerator = useCallback<UseCorpOffersTable['onCorpOfferChangeModerator']>(
    corpOffer => {
      onTryChangeModerator({ data: corpOffer });
    },
    [dispatch]
  );

  const onCorpOffersChangeModerator = useCallback<UseCorpOffersTable['onCorpOffersChangeModerator']>(
    corpOffers => {
      onTryChangeModeratorCollection({ data: corpOffers });
    },
    [dispatch]
  );

  const onCorpOffersChangePage = useCallback<UseCorpOffersTable['onCorpOffersChangePage']>(
    page => {
      dispatch(corpOffersSetPage({ pageNumber: page }));
    },
    [dispatch]
  );

  const onCorpOffersChangePageSize = useCallback<UseCorpOffersTable['onCorpOffersChangePageSize']>(
    pageSize => {
      dispatch(corpOffersSetPageSize({ pageSize }));
    },
    [dispatch]
  );

  const onChangeTab = useCallback<UseCorpOffersTable['onChangeTab']>(
    newTab => {
      if (newTab !== tab) {
        const guid = getCorpOffersGuidFromState();
        dispatch(corpOffersDataReset());
        history.replace(
          addSearchParamToLocation({
            location,
            param: ECorpOfferUrlParam.Tab,
            value: newTab,
            state: { guid },
          })
        );
      }
    },
    [dispatch, history, location, tab, getCorpOffersGuidFromState]
  );

  const onCorpOfferDuplicate = useCallback<UseCorpOffersTable['onCorpOfferDuplicate']>(
    async corpOffer => {
      const response = await dispatch(corpOffersDuplicate({ id: corpOffer.id })).unwrap();

      return response ?? null;
    },
    [dispatch]
  );

  return {
    onRefresh,

    onCorpOfferSelect,
    onAllCorpOffersSelect,
    onChangeFilter,
    onNotificationRead,
    onCorpOfferChangeApprovalRegistry,
    onChangeTab,
    onChangeSort,
    onResetSort,
    isCorpOfferNotModeratedByMe,
    onCorpOfferReject,
    onCorpOfferPause,
    onCorpOfferArchive,
    onCorpOfferRetrieve,
    onCorpOfferChangeModerator,
    onCorpOfferDelete,
    onCorpOfferResume,
    onCorpOfferApprove,
    onCorpOffersChangePage,
    onCorpOffersChangePageSize,
    onCorpOfferDuplicate,

    onCorpOffersApprove,
    onCorpOffersChangeModerator,
    onCorpOffersChangeApprovalRegistry,
    onCorpOffersArchive,
    onCorpOffersReject,
    onCorpOffersPause,
    onCorpOffersResume,
  };
}
