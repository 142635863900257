import { SportOption } from '@/domain';
import { nsiCustomOfferPausedReasonsSelector } from '@features/general/nsi/store/selectors';
import OfferReasonsDialog from '@features/general/offer/components/reasonsDialog';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getTradeOfferActionsOfferIsProcessingSelector, tradeOfferActionsGetActionSelector } from '../store/selectors';
import { TradeOfferStateKeyDialogs } from '../store/slice';
import { useTradeOfferActions } from '../useActions';

const nameDialog: TradeOfferStateKeyDialogs = 'pause';

const TradeOfferActionsDialogPauseAdapter = () => {
  const handlers = useTradeOfferActions();
  const { data: object, notification } = useSelector(tradeOfferActionsGetActionSelector(nameDialog));
  const pauseReasons = useSelector(nsiCustomOfferPausedReasonsSelector);
  const isProcessing = useSelector(getTradeOfferActionsOfferIsProcessingSelector(object?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onPause = useCallback(
    (reason: SportOption, comment?: string) => {
      if (object) {
        handlers.onPause(object, reason, comment, notification).then(() => {
          onCloseDialog();
        });
      }
    },
    [handlers.onPause, notification, object]
  );

  return (
    object && (
      <OfferReasonsDialog
        title='Приостановка торгового предложения'
        actionText='Приостановить'
        isFetching={isProcessing}
        reasons={pauseReasons}
        onAction={onPause}
        onClose={onCloseDialog}
      />
    )
  );
};

export default TradeOfferActionsDialogPauseAdapter;
