import { AppThunkAPIConfig } from '@/data/store/store';
import { Fetchable, fetchableDefault } from '@/data/store/types';
import { BookingOffer, Nullable, PartnerView, UUID } from '@/domain';
import { CaseReducer, createAsyncThunk, createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import ErrorHandler from '../../../../../data/network/errorHandler';
import offerServices from '../../../general/offer/services';
import bookingServices from '../../services';
import { BookingOfferDetailsUiState } from '../types';

export const bookingOfferDetailsViewed = createAsyncThunk<void, UUID, AppThunkAPIConfig>(
  'bookingOffer/details/viewed',
  async id => {
    try {
      await offerServices.common.makeViewed({ id });
    } catch (e: any) {
      console.error(`Error at call user event`, e);
    }
  }
);

export const bookingOfferDetailsByIdFetch = createAsyncThunk<
  { bookingOffer: BookingOffer; partner: PartnerView },
  { id: UUID },
  AppThunkAPIConfig
>('bookingOffer/details/byId/fetch', async ({ id }, { rejectWithValue, dispatch }) => {
  try {
    const bookingOffer = await bookingServices.offer.one({ id });
    dispatch(bookingOfferDetailsViewed(id));
    return { bookingOffer, partner: bookingOffer.partner };
  } catch (e: any) {
    ErrorHandler.handleHttpError(e, e.response);
    return rejectWithValue(e.response.data);
  }
});

export interface BookingOfferDetailsState {
  readonly byId: Fetchable & {
    readonly bookingOffer: Nullable<BookingOffer>;
    readonly partner: Nullable<PartnerView>;
  };
  readonly needRefreshWatcher: number;
  readonly ui: Nullable<BookingOfferDetailsUiState>;
  readonly dialogs: {
    readonly history: Nullable<BookingOffer>;
  };
}

type Reducer<T = undefined> = CaseReducer<BookingOfferDetailsState, PayloadAction<T>>;

interface Reducers extends SliceCaseReducers<BookingOfferDetailsState> {
  bookingOfferDetailsStateReset: Reducer;
  bookingOfferDetailsNeedRefreshWatcherIncrement: Reducer;
  bookingOfferDetailsSetUiState: Reducer<{
    name: keyof BookingOfferDetailsUiState;
    value: any;
  }>;
  bookingOfferDetailsSetDialogState: Reducer<{
    name: keyof BookingOfferDetailsState['dialogs'];
    data: Nullable<BookingOffer>;
  }>;
}

const slice = createSlice<BookingOfferDetailsState, Reducers, 'details'>({
  name: 'details',
  initialState: {
    byId: {
      ...fetchableDefault,
      bookingOffer: null,
      partner: null,
    },
    needRefreshWatcher: 0,
    ui: null,
    dialogs: {
      history: null,
    },
  },
  reducers: {
    bookingOfferDetailsStateReset: state => {
      state.byId = {
        ...fetchableDefault,
        bookingOffer: null,
        partner: null,
      };

      state.ui = null;
    },
    bookingOfferDetailsNeedRefreshWatcherIncrement: state => {
      state.needRefreshWatcher++;
    },
    bookingOfferDetailsSetDialogState: (state, { payload }) => {
      const { name, data } = payload;
      state.dialogs[name] = data;
    },
    bookingOfferDetailsSetUiState: (state, { payload }) => {
      const { name, value } = payload;
      if (!state.ui) {
        state.ui = {
          steps: [],
        };
      }
      state.ui[name] = value;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(bookingOfferDetailsByIdFetch.pending, state => {
        state.byId.isFetching = true;
        state.byId.isFetched = false;
        state.byId.isFailed = false;
        state.byId.bookingOffer = null;
        state.byId.partner = null;
      })
      .addCase(bookingOfferDetailsByIdFetch.fulfilled, (state, { payload }) => {
        const { bookingOffer, partner } = payload;
        state.byId.isFetching = false;
        state.byId.isFetched = true;
        state.byId.isFailed = false;

        state.byId.bookingOffer = bookingOffer;
        state.byId.partner = partner;
      })
      .addCase(bookingOfferDetailsByIdFetch.rejected, state => {
        state.byId.isFetching = false;
        state.byId.isFetched = false;
        state.byId.isFailed = true;
        state.byId.bookingOffer = null;
        state.byId.partner = null;
      });
  },
});

export const {
  bookingOfferDetailsStateReset,
  bookingOfferDetailsSetDialogState,
  bookingOfferDetailsSetUiState,
  bookingOfferDetailsNeedRefreshWatcherIncrement,
} = slice.actions;

export default slice.reducer;
