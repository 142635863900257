import { SportOption } from '@/domain';
import { nsiCustomOfferPausedReasonsSelector } from '@features/general/nsi/store/selectors';
import OfferReasonsDialog from '@features/general/offer/components/reasonsDialog';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { corpOfferActionsGetActionSelector, getCorpOfferActionsOfferIsProcessingSelector } from '../store/selectors';
import { CorpOfferStateKeyDialogs } from '../store/slice';
import { useCorpOfferActions } from '../useActions';

const nameDialog: CorpOfferStateKeyDialogs = 'pause';

const CorpOfferActionsDialogPauseAdapter = () => {
  const handlers = useCorpOfferActions();
  const action = useSelector(corpOfferActionsGetActionSelector(nameDialog));
  const pauseReasons = useSelector(nsiCustomOfferPausedReasonsSelector);
  const isProcessing = useSelector(getCorpOfferActionsOfferIsProcessingSelector(action?.data?.id ?? null));

  const onCloseDialog = useCallback(() => {
    handlers.onCloseDialog(nameDialog);
  }, [handlers.onCloseDialog]);

  const onPause = useCallback(
    (reason: SportOption, comment?: string) => {
      action?.data &&
        handlers.onPause([action.data], reason, comment, action?.notification).then(() => {
          onCloseDialog();
        });
    },
    [handlers.onPause, action]
  );

  return (
    action?.data && (
      <OfferReasonsDialog
        title='Приостановка корпоративного предложения'
        actionText='Приостановить'
        isFetching={isProcessing}
        reasons={pauseReasons}
        onAction={onPause}
        onClose={onCloseDialog}
      />
    )
  );
};

export default CorpOfferActionsDialogPauseAdapter;
